import React, { useState } from "react";

const BreathingAssessmentForm = () => {
  const [formData, setFormData] = useState({
    clientName: "",
    clientEmail: "",
    date: "",
    referral: "",
    practitionerName: "",
    practitionerEmail: "",
    behavioralObservations: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (category, item) => {
    setFormData({
      ...formData,
      [category]: {
        ...formData[category],
        [item]: !formData[category]?.[item],
      },
    });
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "1200px",
        margin: "20px auto",
        padding: "15px",
        border: "1px solid #ccc",
        marginTop: "20px",
      }}
    >
      <h1
        style={{
          color: "#cc0000",
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "bold",
          marginBottom: "15px",
        }}
      >
        SCREENING REPORT
      </h1>

      {/* Client Information */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        <div style={{ flex: "1" }}>
          <span>Client Name: </span>
          <input
            type="text"
            name="clientName"
            value={formData.clientName}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "60%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>
        <div style={{ flex: "1" }}>
          <span>Email: </span>
          <input
            type="text"
            name="clientEmail"
            value={formData.clientEmail}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "70%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>
        <div style={{ flex: "0.5" }}>
          <span>Date: </span>
          <input
            type="text"
            name="date"
            value={formData.date}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "60%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>
      </div>

      {/* Referral */}
      <div style={{ marginBottom: "15px" }}>
        <span style={{ color: "#cc0000", fontWeight: "bold" }}>REFERRAL? </span>
        <input
          type="radio"
          id="referralYes"
          name="referral"
          value="yes"
          checked={formData.referral === "yes"}
          onChange={handleInputChange}
        />
        <label htmlFor="referralYes" style={{ marginRight: "15px" }}>
          {" "}
          Yes{" "}
        </label>
        <input
          type="radio"
          id="referralNo"
          name="referral"
          value="no"
          checked={formData.referral === "no"}
          onChange={handleInputChange}
        />
        <label htmlFor="referralNo" style={{ marginRight: "15px" }}>
          {" "}
          No{" "}
        </label>
        <span>Practitioner Name </span>
        <input
          type="text"
          name="practitionerName"
          value={formData.practitionerName}
          onChange={handleInputChange}
          style={{
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "1px solid black",
            width: "35%",
            backgroundColor: "#f1f4ff",
            height: "30px",
            outline: "none",
          }}
        />
        <span>Email: </span>
        <input
          type="text"
          name="email"
          value={formData.practitionerName}
          onChange={handleInputChange}
          style={{
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "1px solid black",
            width: "25%",
            backgroundColor: "#f1f4ff",
            height: "30px",
            outline: "none",
          }}
        />
      </div>

      {/* Behavioral Observations */}
      <div style={{ marginBottom: "15px" }}>
        <div style={{ fontWeight: "bold", color: "#cc0000" }}>
          BEHAVIORAL OBSERVATIONS:{" "}
          <span style={{ color: "black" }}>
            Dysfunctional breathing behaviors
          </span>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "50%" }}>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="abortedExhale" />
              <label htmlFor="abortedExhale"> Aborted exhale</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "50%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="assistingInhale" />
              <label htmlFor="assistingInhale"> Assisting the inhale</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "50%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="avoidanceTransitionTime" />
              <label htmlFor="avoidanceTransitionTime">
                {" "}
                Avoidance of transition time
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "40%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="breathHolding" />
              <label htmlFor="breathHolding"> Breath holding</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "50%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="chestBreathing" />
              <label htmlFor="chestBreathing"> Chest breathing</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "50%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="doingBreathing" />
              <label htmlFor="doingBreathing">
                {" "}
                Doing breathing (not allowing)
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "45%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="dysponesis" />
              <label htmlFor="dysponesis"> Dysponesis (muscles)</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "45%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="effortfulBreathing" />
              <label htmlFor="effortfulBreathing"> Effortful breathing</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "45%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="forcedExhalation" />
              <label htmlFor="forcedExhalation"> Forced exhalation</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "45%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="gaspingSighing" />
              <label htmlFor="gaspingSighing"> Gasping, sighing</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "50%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="intentionalManipulations" />
              <label htmlFor="intentionalManipulations">
                {" "}
                Intentional manipulations
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "40%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="mouthBreathing" />
              <label htmlFor="mouthBreathing"> Mouth breathing</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "45%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="overbreathingPetCO2" />
              <label htmlFor="overbreathingPetCO2" style={{ color: "#cc0000" }}>
                {" "}
                Overbreathing (PetCO₂ levels)
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "35%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="rapidBreathing" />
              <label htmlFor="rapidBreathing" style={{ color: "#cc0000" }}>
                {" "}
                Rapid breathing (Rate range)
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "39%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="reverseBreathing" />
              <label htmlFor="reverseBreathing"> Reverse breathing</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "45%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="takingDeepBreaths" />
              <label htmlFor="takingDeepBreaths"> Taking deep breaths</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "45%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="underbreathing" />
              <label htmlFor="underbreathing"> Underbreathing</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "45%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="otherBehavior" />
              <label htmlFor="otherBehavior"> Other</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "65%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Triggers */}
      <div style={{ marginBottom: "15px" }}>
        <div style={{ fontWeight: "bold" }}>
          Triggers of dysfunctional breathing DURING the interview:
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "50%" }}>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="breathingMechanics" />
              <label htmlFor="breathingMechanics"> Breathing mechanics</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "45%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="breathingSelfInterventions" />
              <label htmlFor="breathingSelfInterventions">
                {" "}
                Breathing self interventions
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "40%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="conversationalContent" />
              <label htmlFor="conversationalContent">
                {" "}
                Conversational content
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "40%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="emotions" />
              <label htmlFor="emotions"> Emotions</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "55%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="memories" />
              <label htmlFor="memories"> Memories</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "55%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="physicalDiscomfort" />
              <label htmlFor="physicalDiscomfort"> Physical discomfort</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "45%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="posturalChanges" />
              <label htmlFor="posturalChanges">
                {" "}
                Postural (kinesthetic) changes
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "35%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="tasks" />
              <label htmlFor="tasks"> Tasks</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "60%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="thoughts" />
              <label htmlFor="thoughts"> Thoughts</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "55%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="otherTrigger" />
              <label htmlFor="otherTrigger"> Other</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "60%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Fear or anxiety */}
      <div style={{ marginBottom: "15px" }}>
        <div style={{ fontWeight: "bold" }}>
          Fear or anxiety associated with breathing:
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "50%" }}>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="allowingBreathing" />
              <label htmlFor="allowingBreathing"> Allowing the breathing</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "45%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="approachingEndExhale" />
              <label htmlFor="approachingEndExhale">
                {" "}
                Approaching the end of the exhale
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "30%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="breathingInChest" />
              <label htmlFor="breathingInChest"> Breathing in the chest</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "40%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="breathingInDiaphragm" />
              <label htmlFor="breathingInDiaphragm">
                {" "}
                Breathing in the diaphragm
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "35%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="cantTakeDeepBreath" />
              <label htmlFor="cantTakeDeepBreath">
                {" "}
                Can't take a deep breath
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "40%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="changesInBodySensations" />
              <label htmlFor="changesInBodySensations">
                {" "}
                Changes in body sensations
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "35%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="fastBreathing" />
              <label htmlFor="fastBreathing"> Fast breathing</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "50%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="hypocapnicSymptoms" />
              <label htmlFor="hypocapnicSymptoms"> Hypocapnic symptoms</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "45%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="noseBreathing" />
              <label htmlFor="noseBreathing"> Nose breathing</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "50%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="slowBreathing" />
              <label htmlFor="slowBreathing"> Slow breathing</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "50%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="slownessOfExhale" />
              <label htmlFor="slownessOfExhale"> Slowness of the exhale</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "40%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="smallBreaths" />
              <label htmlFor="smallBreaths"> Small breaths</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "50%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="transitionTime" />
              <label htmlFor="transitionTime">
                {" "}
                Transition time (exhale to inhale)
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "30%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="othersFear" />
              <label htmlFor="othersFear"> Others</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "60%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Thoughts and beliefs */}
      <div style={{ marginBottom: "15px" }}>
        <div style={{ fontWeight: "bold" }}>
          Thoughts and beliefs associated with breathing:
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "50%" }}>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="breathingDone" />
              <label htmlFor="breathingDone">
                {" "}
                Breathing is "done" (not allowed)
              </label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "35%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="disruptiveThoughts" />
              <label htmlFor="disruptiveThoughts"> Disruptive thoughts</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "50%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="fearOfBreathing" />
              <label htmlFor="fearOfBreathing"> Faulty beliefs</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "40%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="breathingDone" />
              <label htmlFor="breathingDone"> Memories</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "35%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="disruptiveThoughts" />
              <label htmlFor="disruptiveThoughts">Self-talk</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "50%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <input type="checkbox" id="fearOfBreathing" />
              <label htmlFor="fearOfBreathing">Symptom misinterpretation</label>
              <input
                type="text"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  width: "40%",
                  marginLeft: "5px",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                  outline: "none",
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{ marginBottom: "15px", display: "flex", marginTop: "15px" }}
        >
          <label style={{ fontWeight: "bold" }}>
            Breathing challenges used and outcomes:{" "}
          </label>
          <input
            type="text"
            name="breathingChallenges"
            value={formData.breathingChallenges}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "60%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
          <span
            style={{
              color: "#cc0000",
            }}
          >
            CAPNOGRAPHY (PetCO₂):
          </span>{" "}
          Was overbreathing (below 35 mmHg) present at the start of the session?
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="overbreathingYes"
              name="overbreathingPresent"
              value="yes"
              checked={formData.overbreathingPresent === "yes"}
              onChange={() => handleRadioChange("overbreathingPresent", "yes")}
            />
            <label htmlFor="overbreathingYes"> Yes </label>
          </span>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="overbreathingNo"
              name="overbreathingPresent"
              value="no"
              checked={formData.overbreathingPresent === "no"}
              onChange={() => handleRadioChange("overbreathingPresent", "no")}
            />
            <label htmlFor="overbreathingNo"> No </label>
          </span>
        </div>

        <div style={{ margin: "5px 0", display: "flex" }}>
          <label>
            What were the initial baseline PetCO₂ values (first two minutes)?{" "}
          </label>
          <input
            type="text"
            name="baselinePetCO2"
            value={formData.baselinePetCO2}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "50%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>

        <div style={{ margin: "5px 0", display: "flex" }}>
          <label>What symptoms/deficits were reported, if any? </label>
          <input
            type="text"
            name="reportedSymptoms"
            value={formData.reportedSymptoms}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "60%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>

        <div style={{ margin: "5px 0", display: "flex", alignItems: "center" }}>
          <label>
            If so, did recovery take place on its own within a few minutes?{" "}
          </label>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="recoveryYes"
              name="recoveryTakePlace"
              value="yes"
              checked={formData.recoveryTakePlace === "yes"}
              onChange={() => handleRadioChange("recoveryTakePlace", "yes")}
            />
            <label htmlFor="recoveryYes"> Yes </label>
          </span>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="recoveryNo"
              name="recoveryTakePlace"
              value="no"
              checked={formData.recoveryTakePlace === "no"}
              onChange={() => handleRadioChange("recoveryTakePlace", "no")}
            />
            <label htmlFor="recoveryNo"> No </label>
          </span>
          <label style={{ marginLeft: "10px" }}>Why? </label>
          <input
            type="text"
            name="recoveryWhyReason"
            value={formData.recoveryWhyReason}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "40%",
              marginLeft: "5px",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>

        <div style={{ margin: "5px 0", display: "flex" }}>
          <label>
            If not, when did PetCO₂ levels return to normal, and why?{" "}
          </label>
          <input
            type="text"
            name="returnToNormal"
            value={formData.returnToNormal}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "60%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>

        <div style={{ margin: "5px 0", display: "flex", alignItems: "center" }}>
          <label>Did you assist in this process? </label>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="assistYes"
              name="assistProcess"
              value="yes"
              checked={formData.assistProcess === "yes"}
              onChange={() => handleRadioChange("assistProcess", "yes")}
            />
            <label htmlFor="assistYes"> Yes </label>
          </span>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="assistNo"
              name="assistProcess"
              value="no"
              checked={formData.assistProcess === "no"}
              onChange={() => handleRadioChange("assistProcess", "no")}
            />
            <label htmlFor="assistNo"> No </label>
          </span>
          <label style={{ marginLeft: "10px" }}>How did you assist? </label>
          <input
            type="text"
            name="assistMethod"
            value={formData.assistMethod}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "40%",
              marginLeft: "5px",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>

        <div style={{ margin: "5px 0", display: "flex", alignItems: "center" }}>
          <label>Was your client trapped in overbreathing? </label>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="trappedYes"
              name="trappedInOverbreathing"
              value="yes"
              checked={formData.trappedInOverbreathing === "yes"}
              onChange={() =>
                handleRadioChange("trappedInOverbreathing", "yes")
              }
            />
            <label htmlFor="trappedYes"> Yes </label>
          </span>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="trappedNo"
              name="trappedInOverbreathing"
              value="no"
              checked={formData.trappedInOverbreathing === "no"}
              onChange={() => handleRadioChange("trappedInOverbreathing", "no")}
            />
            <label htmlFor="trappedNo"> No </label>
          </span>
        </div>

        <div style={{ margin: "5px 0" }}>
          <label>
            What was your client's explanation for the associated symptoms?{" "}
          </label>
          <input
            type="text"
            name="clientExplanation"
            value={formData.clientExplanation}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "50%",
            }}
          />
        </div>

        <div style={{ margin: "5px 0" }}>
          <label>
            How did the symptoms/deficits change as breathing changed?{" "}
          </label>
          <input
            type="text"
            name="symptomsChangeWithBreathing"
            value={formData.symptomsChangeWithBreathing}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "50%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>
      </div>

      {/* EMG Section */}
      <div style={{ marginBottom: "20px" }}>
        <div
          style={{
            fontWeight: "bold",
            color: "#cc0000",
            marginBottom: "5px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>
            EMG: Was your client "chest breathing" at the start of the session?"{" "}
          </span>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="chestBreathingYes"
              name="chestBreathing"
              value="yes"
              checked={formData.chestBreathing === "yes"}
              onChange={() => handleRadioChange("chestBreathing", "yes")}
            />
            <label htmlFor="chestBreathingYes"> Yes </label>
          </span>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="chestBreathingNo"
              name="chestBreathing"
              value="no"
              checked={formData.chestBreathing === "no"}
              onChange={() => handleRadioChange("chestBreathing", "no")}
            />
            <label htmlFor="chestBreathingNo"> No </label>
          </span>
          <span style={{ marginLeft: "15px" }}>
            Do you suspect dysponesis?{" "}
          </span>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="dysponesisYes"
              name="suspectDysponesis"
              value="yes"
              checked={formData.suspectDysponesis === "yes"}
              onChange={() => handleRadioChange("suspectDysponesis", "yes")}
            />
            <label htmlFor="dysponesisYes"> Yes </label>
          </span>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="dysponesisNo"
              name="suspectDysponesis"
              value="no"
              checked={formData.suspectDysponesis === "no"}
              onChange={() => handleRadioChange("suspectDysponesis", "no")}
            />
            <label htmlFor="dysponesisNo"> No </label>
          </span>
        </div>

        <div style={{ margin: "5px 0", display: "flex" }}>
          <label>
            If so, what breathing accessory muscles were being used?{" "}
          </label>
          <input
            type="text"
            name="accessoryMuscles"
            value={formData.accessoryMuscles}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "50%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>

        <div style={{ margin: "5px 0", display: "flex" }}>
          <label>
            What EMG placement did you make and what were the readings?{" "}
          </label>
          <input
            type="text"
            name="emgPlacement1"
            value={formData.emgPlacement1}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "50%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>

        <div
          style={{
            margin: "5px 0",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <label>
              Did you ask your client to breathe with the diaphragm? If so,
              could (s)he do so?{" "}
            </label>
            <span style={{ display: "inline-block", marginLeft: "10px" }}>
              <input
                type="radio"
                id="diaphragmYes"
                name="breatheWithDiaphragm"
                value="yes"
                checked={formData.breatheWithDiaphragm === "yes"}
                onChange={() =>
                  handleRadioChange("breatheWithDiaphragm", "yes")
                }
              />
              <label htmlFor="diaphragmYes"> Yes </label>
            </span>
            <span style={{ display: "inline-block", marginLeft: "10px" }}>
              <input
                type="radio"
                id="diaphragmNo"
                name="breatheWithDiaphragm"
                value="no"
                checked={formData.breatheWithDiaphragm === "no"}
                onChange={() => handleRadioChange("breatheWithDiaphragm", "no")}
              />
              <label htmlFor="diaphragmNo"> No </label>
            </span>
            <span style={{ display: "inline-block", marginLeft: "10px" }}>
              <input
                type="radio"
                id="diaphragmNA"
                name="breatheWithDiaphragm"
                value="na"
                checked={formData.breatheWithDiaphragm === "na"}
                onChange={() => handleRadioChange("breatheWithDiaphragm", "na")}
              />
              <label htmlFor="diaphragmNA"> N/A </label>
            </span>
          </div>
        </div>

        <div style={{ margin: "5px 0", display: "flex", alignItems: "center" }}>
          <label>If so, which did they prefer </label>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="preferChest"
              name="preference"
              value="chest"
              checked={formData.preference === "chest"}
              onChange={() => handleRadioChange("preference", "chest")}
            />
            <label htmlFor="preferChest"> chest </label>
          </span>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="preferDiaphragm"
              name="preference"
              value="diaphragm"
              checked={formData.preference === "diaphragm"}
              onChange={() => handleRadioChange("preference", "diaphragm")}
            />
            <label htmlFor="preferDiaphragm"> diaphragm</label>
          </span>
          <span style={{ marginLeft: "10px" }}>
            ? What was the preference based on?{" "}
          </span>
          <input
            type="text"
            name="preferenceReason"
            value={formData.preferenceReason}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "30%",
              marginLeft: "5px",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>

        <div style={{ margin: "5px 0", display: "flex" }}>
          <label>
            What muscles unrelated to breathing were triggered by breathing
            (e.g., jaw)?{" "}
          </label>
          <input
            type="text"
            name="triggeredMuscles"
            value={formData.triggeredMuscles}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "48%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>

        <div style={{ margin: "5px 0", display: "flex" }}>
          <label>
            What EMG placement did you make and what were the readings?{" "}
          </label>
          <input
            type="text"
            name="emgPlacement2"
            value={formData.emgPlacement2}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "50%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>

        <div style={{ margin: "5px 0", display: "flex" }}>
          <label>
            How was dysponesis affected when (and if) your client shifted into
            the diaphragm?{" "}
          </label>
          <input
            type="text"
            name="dysponesisDiaphragmShift"
            value={formData.dysponesisDiaphragmShift}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "44%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>

        <div style={{ margin: "5px 0", display: "flex", alignItems: "center" }}>
          <label>Did posture influence breathing? </label>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="postureYes"
              name="postureInfluence"
              value="yes"
              checked={formData.postureInfluence === "yes"}
              onChange={() => handleRadioChange("postureInfluence", "yes")}
            />
            <label htmlFor="postureYes"> Yes </label>
          </span>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            <input
              type="radio"
              id="postureNo"
              name="postureInfluence"
              value="no"
              checked={formData.postureInfluence === "no"}
              onChange={() => handleRadioChange("postureInfluence", "no")}
            />
            <label htmlFor="postureNo"> No </label>
          </span>
          <label style={{ marginLeft: "10px" }}>If so, how? </label>
          <input
            type="text"
            name="postureInfluenceHow"
            value={formData.postureInfluenceHow}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "40%",
              marginLeft: "5px",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>

        <div style={{ margin: "5px 0", display: "flex" }}>
          <label>
            How did emotions and thoughts shift muscle utilization?{" "}
          </label>
          <input
            type="text"
            name="emotionsThoughtsInfluence"
            value={formData.emotionsThoughtsInfluence}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "55%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>

        <div style={{ margin: "5px 0", display: "flex" }}>
          <label>
            What correlation did you observe among EMG, PetCO₂, and breathing
            rate?{" "}
          </label>
          <input
            type="text"
            name="correlation"
            value={formData.correlation}
            onChange={handleInputChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "48%",
              backgroundColor: "#f1f4ff",
              height: "30px",
              outline: "none",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BreathingAssessmentForm;
