import React from "react";

const LearningPlanForm = () => {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "20px",
        marginTop: "20px",
        border: "1px solid #ccc",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          color: "#0066cc",
          fontSize: "24px",
          marginBottom: "20px",
        }}
      >
        LEARNING PLAN
      </h1>

      <div
        style={{
          marginBottom: "15px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <span>Client Name: </span>
        <input
          style={{
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
            width: "340px",
            height: "30px",
          }}
        ></input>
        <span style={{ marginLeft: "10px" }}>
          <input type="checkbox" id="original" />
          <label htmlFor="original"> Original</label>
          <input type="checkbox" id="revised" style={{ marginLeft: "5px" }} />
          <label htmlFor="revised"> Revised</label>
        </span>
        <span style={{ marginLeft: "10px" }}>Session #</span>
        <input
          style={{
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
            width: "50px",
            height: "30px",
          }}
        ></input>
        <span style={{ marginLeft: "10px" }}>Date: </span>
        <input
          style={{
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
            height: "30px",
            width: "150px",
          }}
        ></input>
      </div>

      <div
        style={{
          marginBottom: "15px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <span>Practitioner Name: </span>
        <input
          style={{
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
            height: "30px",
            width: "370px",
          }}
        ></input>
        <span style={{ marginLeft: "10px" }}>Email:</span>
        <input
          style={{
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
            height: "30px",
            width: "350px",
            marginLeft: "5px",
          }}
        ></input>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <div>Overall learning objectives...</div>
        <textarea
          style={{
            border: "1px solid black",
            height: "80px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            outline: "none",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <div>
          Breathing behaviors to be learned/unlearned (e.g., forced exhaling)...
        </div>
        <textarea
          style={{
            border: "1px solid black",
            height: "80px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            outline: "none",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <div>Principal symptoms and/or deficits to be ameliorated...</div>
        <textarea
          style={{
            border: "1px solid black",
            height: "80px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            outline: "none",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <div>
          Faulty beliefs about BREATHING and/or misguided INTERPRETATIONS of
          symptoms to be addressed...
        </div>
        <textarea
          style={{
            border: "1px solid black",
            height: "80px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            outline: "none",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <div>
          Habit triggers (e.g., memories) to be addressed and in what contexts
          (e.g., while at work)...
        </div>
        <textarea
          style={{
            border: "1px solid black",
            height: "80px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            outline: "none",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <div>
          Motivations, reinforcements, and emotions associated with breathing to
          be addressed...
        </div>
        <textarea
          style={{
            border: "1px solid black",
            height: "80px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            outline: "none",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <div>En vivo work to be done in the field...</div>
        <textarea
          style={{
            border: "1px solid black",
            height: "80px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            outline: "none",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <div>
          Specific learning protocols to be used during learning sessions (e.g.,
          negative practice: chest vs. diaphragmatic breathing)...
        </div>
        <textarea
          style={{
            border: "1px solid black",
            height: "80px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            outline: "none",
          }}
        ></textarea>
      </div>
    </div>
  );
};

export default LearningPlanForm;
