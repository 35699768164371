import React from "react";

const MedicalIntakeForm = () => {
  const formStyle = {
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "16px",
    fontFamily: "Arial, sans-serif",
  };

  const headingStyle = {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#800080",
    textAlign: "center",
    marginBottom: "16px",
  };

  const fieldRowStyle = {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "16px",
    gap: "8px",
  };

  const fieldGroupStyle = {
    display: "flex",
    alignItems: "flex-end",
    gap: "8px",
  };

  const inputStyle = {
    backgroundColor: "#f1f4ff",
    padding: "4px",
    width: "300px",
    borderBottom: "2px solid #444",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    outline: "none",
  };

  const shortInputStyle = { ...inputStyle, width: "55px" };
  const mediumInputStyle = { ...inputStyle, width: "140px" };
  const zipInputStyle = { ...inputStyle, width: "120px" };
  const addressInputStyle = { ...inputStyle, width: "320px" };
  const cityInputStyle = { ...inputStyle, width: "190px" };

  const questionBoxStyle = {
    border: "1px solid #888",
    padding: "8px",
    marginBottom: "8px",
    backgroundColor: "#f1f4ff",
  };

  const commentBoxStyle = {
    padding: "8px",
    marginBottom: "8px",
    width: "100%",
    height: "96px",
  };

  const questions = [
    "Tell us about your breathing. Is breathing ever a challenge, or an issue, for you?",
    "Medical conditions, e.g., cardiovascular, diabetes?",
    "Respiratory disorders, e.g., asthma, COPD?",
    "Injuries, past and present, e.g., chest, back, neck?",
    "Physical complaints, e.g., headache, hypertension, stress symptoms?",
    "Emotional challenges, e.g., panic, anxiety, anger?",
    "Pregnancy experiences, current or previous?",
    "Current prescriptions, e.g., depression, anxiety, hypertension?",
    "Life traumas, e.g., emotional abuse, PTSD, chronic stress?",
    "Pain issues, past or present, acute or chronic?",
    "Physical limitations, e.g., fatigue, speech, movement?",
    "Allergies and sensitivities, e.g., food, environment?",
    "Deficiencies, e.g., electrolytes (kidney dysfunction)?",
    'Relationship difficulties, or social challenges, e.g., significant other, "children," employment?',
    "Work related challenges, e.g., environment, unreasonable demands, co-workers, superiors?",
    "Learning issues, e.g., attention deficit, memory, focus?",
    "Performance issues, e.g., public speaking, testing, performing arts, operating technology?",
    "Are you seeing a healthcare practitioner? If so, what kind of practitioner(s), e.g. physician?",
  ];

  return (
    <div style={formStyle}>
      <h1 style={headingStyle}>TELL US MORE ABOUT YOURSELF.</h1>

      <div style={fieldRowStyle}>
        <div style={fieldGroupStyle}>
          <span>Name:</span>
          <input type="text" style={inputStyle} />
        </div>

        <div style={fieldGroupStyle}>
          <span>Sex</span>
          <input type="text" style={shortInputStyle} />
        </div>

        <div style={fieldGroupStyle}>
          <span>Age</span>
          <input type="text" style={shortInputStyle} />
        </div>

        <div style={fieldGroupStyle}>
          <span>Email:</span>
          <input type="email" style={mediumInputStyle} />
        </div>

        <div style={fieldGroupStyle}>
          <span>Tel:</span>
          <input type="tel" style={mediumInputStyle} />
        </div>
      </div>

      <div style={fieldRowStyle}>
        <div style={fieldGroupStyle}>
          <span>Address:</span>
          <input type="text" style={addressInputStyle} />
        </div>

        <div style={fieldGroupStyle}>
          <span>City</span>
          <input type="text" style={cityInputStyle} />
        </div>

        <div style={fieldGroupStyle}>
          <span>State</span>
          <input type="text" style={shortInputStyle} />
        </div>

        <div style={fieldGroupStyle}>
          <span>Zip</span>
          <input type="text" style={zipInputStyle} />
        </div>
      </div>

      {questions.map((question, index) => (
        <div key={index} style={questionBoxStyle}>
          <label>{question}</label>
          <textarea
            style={{
              width: "100%",
              border: "none",
              backgroundColor: "transparent",
            }}
          ></textarea>
        </div>
      ))}

      <div style={questionBoxStyle}>
        <label>Other Comments:</label>
        <textarea
          style={{
            width: "100%",
            border: "none",
            backgroundColor: "transparent",
          }}
        ></textarea>
      </div>
    </div>
  );
};

export default MedicalIntakeForm;
