import React, { useState } from "react";

const BreathingHabitsChecklist = () => {
  const [personalInfo, setPersonalInfo] = useState({
    name: "",
    date: "",
    email: "",
    tel: "",
    sex: "",
    age: "",
    sigOther: "",
    children: "",
    issue: "",
    dysfunctionalBreathingDescription: "",
  });

  // Symptoms data with their corresponding information
  const symptoms = [
    { id: 1, text: "Chest tightness, pressure, or pain", hasRedDot: true },
    { id: 2, text: "Intentional breathing, purposeful regulation" },
    { id: 3, text: "Blurred or hazy vision" },
    { id: 4, text: "Dizziness, light-headedness, fainting", hasRedDot: true },
    { id: 5, text: "Disconnected, things seem distant" },
    {
      id: 6,
      text: "Shortness of breath, difficulty breathing",
      hasRedDot: true,
    },
    {
      id: 7,
      text: "Tingling or numbness, e.g., fingers, lips",
      hasRedDot: true,
    },
    { id: 8, text: "Disoriented, confused" },
    { id: 9, text: "Unable to breathe deeply", hasRedDot: true },
    { id: 10, text: "Muscle pain, stiffness, e.g., hands, jaw, back" },
    {
      id: 11,
      text: "Not exhaling completely, aborting the exhale",
      hasRedDot: true,
    },
    { id: 12, text: "Deep breathing, like during talking", hasRedDot: true },
    { id: 13, text: "Fast or irregular heartbeat" },
    { id: 14, text: "Chest breathing, effortful breathing", hasRedDot: true },
    { id: 15, text: "Breath holding, irregular breathing" },
    { id: 16, text: "Poor concentration, focus, memory" },
    { id: 17, text: "Rapid breathing, panicky breathing", hasRedDot: true },
    { id: 18, text: "Fatigue easily" },
    { id: 19, text: "Worried about my breathing", hasRedDot: true },
    { id: 20, text: "Mouth breathing", hasRedDot: true },
    { id: 21, text: "Hard to swallow, nauseous" },
    { id: 22, text: "Can't seem to get enough oxygen", hasRedDot: true },
    { id: 23, text: "Hyper-aroused, can't calm down, anxious" },
    { id: 24, text: "Unexpected mood changes (e.g., anger)" },
  ];

  // Situations data
  const situations = [
    { id: 1, text: "working (employment)" },
    { id: 2, text: "resting (between tasks)" },
    { id: 3, text: "performing (e.g., test taking)" },
    { id: 4, text: "talking, eating, singing" },
    { id: 5, text: "feeling anxious or worried" },
    { id: 6, text: "feeling tired or stressed" },
    { id: 7, text: "interacting in groups" },
    { id: 8, text: "physical challenges, exercising" },
    { id: 9, text: "being confronted by others" },
    { id: 10, text: "traveling, unfamiliar places" },
    { id: 11, text: "socializing, meeting people" },
    { id: 12, text: "speaking in public, in groups" },
    { id: 13, text: "feeling angry or upset" },
    { id: 14, text: "intimacy, expressing feelings" },
    { id: 15, text: "physical discomfort, pain" },
    { id: 16, text: "meeting authority figures" },
    { id: 17, text: "going to sleep, while asleep" },
    { id: 18, text: "being accountable, in-charge" },
    { id: 19, text: "learning new tasks, new info" },
    { id: 20, text: "feeling unsure of self" },
    { id: 21, text: "allergens, weather, foods" },
  ];

  // State for symptom responses
  const [symptomResponses, setSymptomResponses] = useState(
    symptoms.reduce(
      (acc, symptom) => ({
        ...acc,
        [symptom.id]: {
          yesNo: null,
          frequency: null,
          situations: "",
          comment: "",
        },
      }),
      {}
    )
  );

  const handlePersonalInfoChange = (e) => {
    setPersonalInfo({
      ...personalInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleYesNoChange = (symptomId, value) => {
    setSymptomResponses({
      ...symptomResponses,
      [symptomId]: {
        ...symptomResponses[symptomId],
        yesNo: value,
      },
    });
  };

  const handleFrequencyChange = (symptomId, value) => {
    setSymptomResponses({
      ...symptomResponses,
      [symptomId]: {
        ...symptomResponses[symptomId],
        frequency: value,
      },
    });
  };

  const handleSituationsChange = (symptomId, e) => {
    setSymptomResponses({
      ...symptomResponses,
      [symptomId]: {
        ...symptomResponses[symptomId],
        situations: e.target.value,
      },
    });
  };

  const handleCommentChange = (symptomId, e) => {
    setSymptomResponses({
      ...symptomResponses,
      [symptomId]: {
        ...symptomResponses[symptomId],
        comment: e.target.value,
      },
    });
  };

  return (
    <div
      style={{
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "16px",
        backgroundColor: "white",
        border: "1px solid #d1d1d1",
        fontFamily: "Arial, sans-serif",
        marginTop: "16px",
      }}
    >
      <div style={{ textAlign: "center", marginBottom: "16px" }}>
        <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
          INTERVIEW CHECKLIST
        </h1>
        <p
          style={{
            color: "#7030a0",
            fontStyle: "italic",
            fontSize: "17px",
            marginTop: "-12px",
            fontWeight: "bold",
          }}
        >
          For learning about your breathing habits
        </p>
      </div>

      <p style={{ marginBottom: "16px", fontSize: "14px" }}>
        This checklist has been designed to serve as a "guideline" for assisting
        you in exploring whether or not your breathing habits are consistent
        with optimal respiration, and if not, how they may be affecting you at
        specific times and places.
      </p>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginBottom: "16px",
          gap: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
          <label htmlFor="client-name">Name:</label>
          <input
            type="text"
            id="client-name"
            style={{
              height: "30px",
              width: 280,
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
          <label htmlFor="client-name">Date:</label>
          <input
            type="text"
            id="client-name"
            style={{
              height: "30px",
              width: 170,
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
          <label htmlFor="client-name">Email:</label>
          <input
            type="text"
            id="client-name"
            style={{
              height: "30px",
              width: 290,
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginBottom: "16px",
          gap: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
          <label htmlFor="client-name">Tel:</label>
          <input
            type="text"
            id="client-name"
            style={{
              height: "30px",
              width: 160,
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
          <label htmlFor="client-name">Sex:</label>
          <input
            type="text"
            id="client-name"
            style={{
              height: "30px",
              width: 40,
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
          <label htmlFor="client-name">Age:</label>
          <input
            type="text"
            id="client-name"
            style={{
              height: "30px",
              width: 40,
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
          <label htmlFor="client-name">Sig Other:</label>
          <input
            type="text"
            id="client-name"
            style={{
              height: "30px",
              width: 40,
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
          <label htmlFor="client-name">Children:</label>
          <input
            type="text"
            id="client-name"
            style={{
              height: "30px",
              width: 40,
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
          <label htmlFor="client-name">Issue:</label>
          <input
            type="text"
            id="client-name"
            style={{
              height: "30px",
              width: 230,
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
      </div>

      <div style={{ marginBottom: "24px" }}>
        <p style={{ fontWeight: "bold", marginBottom: "4px" }}>
          Do you think you might have a dysfunctional breathing habit? If so,
          what difficulties are you having that might be related to breathing?
        </p>
        <textarea
          name="dysfunctionalBreathingDescription"
          value={personalInfo.dysfunctionalBreathingDescription}
          onChange={handlePersonalInfoChange}
          style={{
            width: "100%",
            height: "60px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div style={{ marginBottom: "16px" }}>
        <p style={{ fontWeight: "bold", marginBottom: "4px" }}>
          Do you ever experience any of the 24 symptoms listed below?
        </p>
        <p style={{ fontSize: "14px", marginTop: "4px" }}>
          Check the <span style={{ fontWeight: "bold" }}>Y column</span> for
          "YES," OR the <span style={{ fontWeight: "bold" }}>N column</span> for
          "NO," after each symptom listed. If you checked YES, indicate{" "}
          <span style={{ fontStyle: "italic", color: "#7030a0" }}>
            how frequently you experience the symptom
          </span>{" "}
          by checking a number 1 through 7, where 1 is rarely and 7 is daily.
          Then enter in the{" "}
          <span style={{ fontStyle: "italic", color: "#7030a0" }}>
            situations in which you experience a symptom
          </span>
          , in the "situation column," by entering a number that corresponds to
          one of the 21 situations listed at the bottom of the page. For
          example, you might check column #6 for "dizziness" and then enter in
          situations #14 (expressing feelings) and #19 (learning new tasks). If
          the situation is not shown on the list, write it into the "comment"
          column. Focus on when, where, and with whom these symptoms may occur.
        </p>
      </div>

      <div
        style={{ marginBottom: "8px", fontSize: "14px", textAlign: "center" }}
      >
        <p style={{ margin: "0" }}>
          How often? 1 = rarely ................... 7 = every day
        </p>
      </div>

      <div style={{ overflowX: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2" }}>
              <th
                style={{
                  border: "1px solid #999",
                  padding: "4px",
                  textAlign: "left",
                }}
              >
                Do you experience the following? If so, how often?
              </th>
              <th
                style={{
                  border: "1px solid #999",
                  padding: "4px",
                  width: "30px",
                  textAlign: "center",
                }}
              >
                N
              </th>
              <th
                style={{
                  border: "1px solid #999",
                  padding: "4px",
                  width: "30px",
                  textAlign: "center",
                }}
              >
                Y
              </th>
              <th
                style={{
                  border: "1px solid #999",
                  padding: "4px",
                  width: "25px",
                  textAlign: "center",
                }}
              >
                1
              </th>
              <th
                style={{
                  border: "1px solid #999",
                  padding: "4px",
                  width: "25px",
                  textAlign: "center",
                }}
              >
                2
              </th>
              <th
                style={{
                  border: "1px solid #999",
                  padding: "4px",
                  width: "25px",
                  textAlign: "center",
                }}
              >
                3
              </th>
              <th
                style={{
                  border: "1px solid #999",
                  padding: "4px",
                  width: "25px",
                  textAlign: "center",
                }}
              >
                4
              </th>
              <th
                style={{
                  border: "1px solid #999",
                  padding: "4px",
                  width: "25px",
                  textAlign: "center",
                }}
              >
                5
              </th>
              <th
                style={{
                  border: "1px solid #999",
                  padding: "4px",
                  width: "25px",
                  textAlign: "center",
                }}
              >
                6
              </th>
              <th
                style={{
                  border: "1px solid #999",
                  padding: "4px",
                  width: "25px",
                  textAlign: "center",
                }}
              >
                7
              </th>
              <th
                style={{
                  border: "1px solid #999",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Situations
              </th>
              <th
                style={{
                  border: "1px solid #999",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Comment
              </th>
            </tr>
          </thead>
          <tbody>
            {symptoms.map((symptom, index) => (
              <tr key={symptom.id}>
                <td
                  style={{
                    border: "1px solid #999",
                    padding: "4px",
                    textAlign: "left",
                    backgroundColor: "#f4ebff",
                  }}
                >
                  {symptom.text}{" "}
                  {symptom.hasRedDot && <span style={{ color: "red" }}>●</span>}
                </td>
                <td
                  style={{
                    border: "1px solid #999",
                    padding: "4px",
                    textAlign: "center",
                    backgroundColor: index % 2 === 0 ? "#eae8ff" : "#f1f4ff",
                  }}
                >
                  <input
                    type="checkbox"
                    name={`yesNo-${symptom.id}`}
                    style={{
                      width: "16px",
                      height: "16px",
                      appearance: "none",
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                      position: "relative",
                    }}
                    onChange={(e) => {
                      e.target.style.backgroundColor = e.target.checked
                        ? "#007bff"
                        : "transparent";
                      e.target.style.borderRadius = "50%"; // To make it look like a dot
                    }}
                  />
                </td>

                <td
                  style={{
                    border: "1px solid #999",
                    padding: "4px",
                    textAlign: "center",
                    backgroundColor: index % 2 === 0 ? "#eae8ff" : "#f1f4ff",
                  }}
                >
                  <input
                    type="checkbox"
                    name={`yesNo-${symptom.id}`}
                    style={{
                      width: "16px",
                      height: "16px",
                      appearance: "none",
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                      position: "relative",
                    }}
                    onChange={(e) => {
                      e.target.style.backgroundColor = e.target.checked
                        ? "#007bff"
                        : "transparent";
                      e.target.style.borderRadius = "50%"; // To make it look like a dot
                    }}
                  />
                </td>
                {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                  <td
                    style={{
                      border: "1px solid #999",
                      padding: "4px",
                      textAlign: "center",
                      backgroundColor: index % 2 === 0 ? "#eae8ff" : "#f1f4ff",
                    }}
                  >
                    <input
                      type="checkbox"
                      name={`yesNo-${symptom.id}`}
                      style={{
                        width: "16px",
                        height: "16px",
                        appearance: "none",
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onChange={(e) => {
                        e.target.style.backgroundColor = e.target.checked
                          ? "#007bff"
                          : "transparent";
                        e.target.style.borderRadius = "50%"; // To make it look like a dot
                      }}
                    />
                  </td>
                ))}
                <td
                  style={{
                    border: "1px solid #999",
                    padding: "4px",
                    textAlign: "center",
                    backgroundColor: index % 2 === 0 ? "#eae8ff" : "#f1f4ff",
                  }}
                >
                  <input
                    type="checkbox"
                    name={`yesNo-${symptom.id}`}
                    style={{
                      width: "16px",
                      height: "16px",
                      appearance: "none",
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                      position: "relative",
                    }}
                    onChange={(e) => {
                      e.target.style.backgroundColor = e.target.checked
                        ? "#007bff"
                        : "transparent";
                      e.target.style.borderRadius = "50%"; // To make it look like a dot
                    }}
                  />
                </td>
                <td
                  style={{
                    border: "1px solid #999",
                    padding: "4px",
                    backgroundColor: index % 2 === 0 ? "#eae8ff" : "#f1f4ff",
                  }}
                >
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      background: "transparent",
                      border: "none",
                      outline: "none",
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div style={{ marginTop: "24px", marginBottom: "16px" }}>
        <strong>*SITUATIONS:</strong> circumstances under which you experience
        the above symptoms
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(260px, 1fr))",
          gap: "8px",
          fontSize: "14px",
          marginBottom: "24px",
        }}
      >
        {situations.map((situation) => (
          <div key={situation.id} style={{ display: "flex" }}>
            <span style={{ marginRight: "4px" }}>({situation.id})</span>
            <span>{situation.text}</span>
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          gap: 20,
        }}
      >
        <label
          style={{
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          General Comments:
        </label>
        <input
          type="text"
          style={{
            height: "30px",
            width: "80%",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>
    </div>
  );
};

export default BreathingHabitsChecklist;
