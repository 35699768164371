import React from "react";

const PhysiologyReport = () => {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "20px",
        border: "1px solid #ccc",
        marginTop: "20px",
      }}
    >
      <h1
        style={{
          color: "#0066cc",
          textAlign: "center",
          fontSize: "24px",
          marginBottom: "20px",
        }}
      >
        PHYSIOLOGY REPORT
      </h1>

      <div
        style={{
          display: "flex",
          gap: "20px",
          marginBottom: "10px",
        }}
      >
        <div>
          <label style={{ marginRight: "10px" }}>Client Name:</label>
          <input
            type="text"
            style={{
              width: "300px",
              padding: "2px 5px",
              border: "1px solid #ccc",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div>
          <label style={{ marginRight: "10px" }}>Type:</label>
          <input type="checkbox" id="typeA" name="typeA" />
          <label htmlFor="typeA" style={{ marginRight: "5px" }}>
            {" "}
            A
          </label>
          <input type="checkbox" id="typeT" name="typeT" />
          <label htmlFor="typeT" style={{ marginRight: "15px" }}>
            {" "}
            T
          </label>

          <label style={{ marginRight: "5px" }}>Session #</label>
          <input
            type="text"
            style={{
              width: "40px",
              padding: "2px 5px",
              border: "1px solid #ccc",
              marginRight: "10px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />

          <label style={{ marginRight: "5px" }}>Date:</label>
          <input
            type="text"
            style={{
              width: "150px",
              padding: "2px 5px",
              border: "1px solid #ccc",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <label style={{ marginRight: "10px" }}>Practitioner Name:</label>
        <input
          type="text"
          style={{
            width: "300px",
            padding: "2px 5px",
            marginRight: "15px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />

        <label style={{ marginRight: "5px" }}>Email:</label>
        <input
          type="text"
          style={{
            width: "300px",
            padding: "2px 5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <h3
        style={{
          color: "#0066cc",
          marginTop: "20px",
          marginBottom: "10px",
          fontSize: "16px",
        }}
      >
        CAPNOGRAPHY (PetCO₂)
      </h3>

      <div style={{ marginBottom: "10px" }}>
        <strong style={{ marginRight: "10px" }}>
          Was overbreathing (below 35 mmHg) present at the start of the session?
        </strong>
        <input type="checkbox" id="overbreathingYes" name="overbreathing" />
        <strong htmlFor="overbreathingYes" style={{ marginRight: "10px" }}>
          {" "}
          Yes
        </strong>
        <input type="checkbox" id="overbreathingNo" name="overbreathing" />
        <strong htmlFor="overbreathingNo"> No</strong>
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label>
          What were the initial baseline PetCO₂ values (first two minutes)?
        </label>
        <input
          type="text"
          style={{
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            width: "500px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label>What symptoms/deficits were reported, if any?</label>
        <input
          type="text"
          style={{
            width: "600px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label style={{ marginRight: "10px" }}>
          If so, did recovery take place on its own within a few minutes?
        </label>
        <input type="checkbox" id="recoveryYes" name="recovery" />
        <label htmlFor="recoveryYes" style={{ marginRight: "10px" }}>
          {" "}
          Yes
        </label>
        <input type="checkbox" id="recoveryNo" name="recovery" />
        <label htmlFor="recoveryNo" style={{ marginRight: "10px" }}>
          {" "}
          No
        </label>
        <label style={{ marginRight: "5px" }}>Why?</label>
        <input
          type="text"
          style={{
            width: "340px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label>If not, when did PetCO₂ levels return to normal, and why?</label>
        <input
          type="text"
          style={{
            width: "500px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label style={{ marginRight: "10px" }}>
          Did you assist in this process?
        </label>
        <input type="checkbox" id="assistYes" name="assist" />
        <label htmlFor="assistYes" style={{ marginRight: "10px" }}>
          {" "}
          Yes
        </label>
        <input type="checkbox" id="assistNo" name="assist" />
        <label htmlFor="assistNo" style={{ marginRight: "10px" }}>
          {" "}
          No
        </label>
        <label>How did you assist?</label>
        <input
          type="text"
          style={{
            width: "480px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label style={{ marginRight: "10px" }}>
          Was your client trapped in overbreathing?
        </label>
        <input type="checkbox" id="trappedYes" name="trapped" />
        <label htmlFor="trappedYes" style={{ marginRight: "10px" }}>
          {" "}
          Yes
        </label>
        <input type="checkbox" id="trappedNo" name="trapped" />
        <label htmlFor="trappedNo"> No</label>
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label>
          What was your client's explanation for the associated symptoms?
        </label>
        <input
          type="text"
          style={{
            width: "480px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label>
          How did the symptoms/deficits change as breathing improved?
        </label>
        <input
          type="text"
          style={{
            width: "480px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{ marginTop: "15px", marginBottom: "10px", fontWeight: "bold" }}
      >
        <label style={{ marginRight: "10px" }}>
          Did overbreathing occur at other times during the interview?
        </label>
        <input type="checkbox" id="otherTimesYes" name="otherTimes" />
        <label htmlFor="otherTimesYes" style={{ marginRight: "10px" }}>
          {" "}
          Yes
        </label>
        <input type="checkbox" id="otherTimesNo" name="otherTimes" />
        <label htmlFor="otherTimesNo"> No</label>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label>What was the range of the PetCO₂ values you observed?</label>
        <input
          type="text"
          style={{
            width: "520px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label>What symptoms/deficits were reported?</label>
        <input
          type="text"
          style={{
            width: "550px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label>What were the triggers?</label>
        <input
          type="text"
          style={{
            width: "700px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label>What was your client's explanation for these symptoms?</label>
        <input
          type="text"
          style={{
            width: "500px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label style={{ marginRight: "10px" }}>
          Did the PetCO₂ levels return to baseline levels?
        </label>
        <input type="checkbox" id="returnBaselineYes" name="returnBaseline" />
        <label htmlFor="returnBaselineYes" style={{ marginRight: "10px" }}>
          {" "}
          Yes
        </label>
        <input type="checkbox" id="returnBaselineNo" name="returnBaseline" />
        <label htmlFor="returnBaselineNo" style={{ marginRight: "10px" }}>
          {" "}
          No
        </label>
        <label>How long did it take?</label>
        <input
          type="text"
          style={{
            width: "300px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label style={{ marginRight: "10px" }}>
          Was your client trapped in overbreathing?
        </label>
        <input type="checkbox" id="trappedYes2" name="trapped2" />
        <label htmlFor="trappedYes2" style={{ marginRight: "10px" }}>
          {" "}
          Yes
        </label>
        <input type="checkbox" id="trappedNo2" name="trapped2" />
        <label htmlFor="trappedNo2"> No</label>
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label style={{ marginRight: "10px" }}>
          If so, did your client recover on his/her own?
        </label>
        <input type="checkbox" id="recoverOwnYes" name="recoverOwn" />
        <label htmlFor="recoverOwnYes" style={{ marginRight: "10px" }}>
          {" "}
          Yes
        </label>
        <input type="checkbox" id="recoverOwnNo" name="recoverOwn" />
        <label htmlFor="recoverOwnNo"> No</label>
      </div>

      <div style={{ alignItems: "flex-end" }}>
        <label>
          If so, what seemed to be the factor(s) in its return to normal?
        </label>
        <input
          type="text"
          style={{
            width: "500px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label>If not, how did you assist your client in recovery?</label>
        <input
          type="text"
          style={{
            width: "600px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label>What strategies were, and were not, effective?</label>
        <input
          type="text"
          style={{
            width: "500px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label>
          How did the symptoms/deficits change as breathing improved?
        </label>
        <input
          type="text"
          style={{
            width: "500px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <label>
          What was the range of PetCO₂ values at the end of interview session?
        </label>
        <input
          type="text"
          style={{
            width: "450px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <h3
        style={{
          color: "#0066cc",
          marginTop: "20px",
          marginBottom: "10px",
          fontSize: "16px",
        }}
      >
        EMG (electromyography)
      </h3>

      <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
        <label>Placements (1 or 2)</label>
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <label>Specific muscle group 1</label>
          <input
            type="text"
            style={{
              width: "420px",
              padding: "2px 5px",
              border: "1px solid #ccc",
              marginTop: "5px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <label>Range (mv)</label>
          <input
            type="text"
            style={{
              width: "250px",
              padding: "2px 5px",
              border: "1px solid #ccc",
              marginTop: "5px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <label>Specific muscle group 2</label>
          <input
            type="text"
            style={{
              width: "420px",
              padding: "2px 5px",
              border: "1px solid #ccc",
              marginTop: "5px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <label>Range (mv)</label>
          <input
            type="text"
            style={{
              width: "240px",
              padding: "2px 5px",
              border: "1px solid #ccc",
              marginTop: "5px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
      </div>

      <div style={{ marginBottom: "10px", marginTop: "20px" }}>
        <strong style={{ marginRight: "10px" }}>
          Was your client "chest breathing" at the start of the session?"
        </strong>
        <input type="checkbox" id="chestBreathingYes" name="chestBreathing" />
        <strong htmlFor="chestBreathingYes" style={{ marginRight: "10px" }}>
          {" "}
          Yes
        </strong>
        <input type="checkbox" id="chestBreathingNo" name="chestBreathing" />
        <strong htmlFor="chestBreathingNo"> No</strong>
      </div>

      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <label>If so, what breathing accessory muscles were being used?</label>
        <input
          type="text"
          style={{
            width: "330px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <label>What seemed to be the triggers for chest breathing?</label>
        <input
          type="text"
          style={{
            width: "330px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <label>What was your client's explanation for chest breathing?</label>
        <input
          type="text"
          style={{
            width: "330px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <label style={{ marginRight: "10px" }}>
          Did your client like chest breathing? If so, why?
        </label>
        <input
          type="text"
          style={{
            width: "330px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div
        style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
      >
        <label style={{ marginRight: "10px" }}>
          If you asked them to breathe with the diaphragm, could they do it?
        </label>
        <input type="checkbox" id="diaphragmYes" name="diaphragm" />
        <label htmlFor="diaphragmYes" style={{ marginRight: "10px" }}>
          {" "}
          Yes
        </label>
        <input type="checkbox" id="diaphragmNo" name="diaphragm" />
        <label htmlFor="diaphragmNo" style={{ marginRight: "10px" }}>
          {" "}
          No
        </label>
        <input type="checkbox" id="diaphragmNA" name="diaphragm" />
        <label htmlFor="diaphragmNA"> N/A</label>
      </div>

      <div
        style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
      >
        <label style={{ marginRight: "10px" }}>
          If so, which did they prefer
        </label>
        <input type="checkbox" id="preferChest" name="prefer" />
        <label htmlFor="preferChest" style={{ marginRight: "10px" }}>
          {" "}
          chest
        </label>
        <input type="checkbox" id="preferDiaphragm" name="prefer" />
        <label htmlFor="preferDiaphragm" style={{ marginRight: "10px" }}>
          {" "}
          diaphragm?
        </label>
        <label style={{ marginRight: "5px" }}>
          What was the preference based on?
        </label>
        <input
          type="text"
          style={{
            width: "310px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label style={{ marginRight: "10px" }}>
          Do you suspect dysponesis?
        </label>
        <input type="checkbox" id="dysponesisYes" name="dysponesis" />
        <label htmlFor="dysponesisYes" style={{ marginRight: "10px" }}>
          {" "}
          Yes
        </label>
        <input type="checkbox" id="dysponesisNo" name="dysponesis" />
        <label htmlFor="dysponesisNo"> No</label>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label>
          If so, what muscles unrelated to breathing were triggered (e.g., jaw)?
        </label>
        <input
          type="text"
          style={{
            width: "310px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label>
          What seem to be the triggers for contraction of these muscles?
        </label>
        <input
          type="text"
          style={{
            width: "310px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label>
          How was dysponesis affected when (and if) your client shifted into the
          diaphragm?
        </label>
        <input
          type="text"
          style={{
            width: "310px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label style={{ marginRight: "10px" }}>
          Did posture influence breathing?
        </label>
        <input type="checkbox" id="postureYes" name="posture" />
        <label htmlFor="postureYes" style={{ marginRight: "10px" }}>
          {" "}
          Yes
        </label>
        <input type="checkbox" id="postureNo" name="posture" />
        <label htmlFor="postureNo" style={{ marginRight: "10px" }}>
          {" "}
          No
        </label>
        <label style={{ marginRight: "5px" }}>If so, how?</label>
        <input
          type="text"
          style={{
            width: "310px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label>
          How did emotions and thoughts influence shift muscle utilization?
        </label>
        <input
          type="text"
          style={{
            width: "310px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label>
          What correlation did you observe among EMG, PetCO₂, and breathing
          rate?
        </label>
        <input
          type="text"
          style={{
            width: "310px",
            padding: "2px 5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        />
      </div>

      <h3
        style={{
          color: "#0066cc",
          marginTop: "20px",
          marginBottom: "10px",
          fontSize: "16px",
        }}
      >
        Other measurements (autonomic and oximetry)
      </h3>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ marginRight: "5px" }}>Initial HRV</label>
          <input
            type="text"
            style={{
              width: "120px",
              padding: "2px 5px",
              border: "1px solid #ccc",
              marginTop: "5px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ marginRight: "5px", marginLeft: "10px" }}>
            HRV range during the session
          </label>
          <input
            type="text"
            style={{
              width: "120px",
              padding: "2px 5px",
              border: "1px solid #ccc",
              marginTop: "5px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ marginRight: "5px", marginLeft: "10px" }}>
            Ending HRV
          </label>
          <input
            type="text"
            style={{
              width: "120px",
              padding: "2px 5px",
              border: "1px solid #ccc",
              marginTop: "5px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
      </div>

      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ marginRight: "5px" }}>Initial SpO₂</label>
          <input
            type="text"
            style={{
              width: "120px",
              padding: "2px 5px",
              border: "1px solid #ccc",
              marginTop: "5px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ marginRight: "5px", marginLeft: "10px" }}>
            SpO₂ range during the session
          </label>
          <input
            type="text"
            style={{
              width: "120px",
              padding: "2px 5px",
              border: "1px solid #ccc",
              marginTop: "5px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ marginRight: "5px", marginLeft: "10px" }}>
            Ending SpO₂
          </label>
          <input
            type="text"
            style={{
              width: "120px",
              padding: "2px 5px",
              border: "1px solid #ccc",
              marginTop: "5px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>
      </div>

      <div style={{ marginBottom: "10px", marginTop: "10px" }}>
        <label>
          Were there any correlations between PetCO₂, Breathing Rate, EMG, HRV,
          & SpO₂? If so, why do you think so?
        </label>
        <textarea
          style={{
            width: "100%",
            padding: "5px",
            border: "1px solid #ccc",
            marginTop: "5px",
            minHeight: "60px",
            backgroundColor: "#f1f4ff",
            outline: "none",
            height: "100px",
          }}
        ></textarea>
      </div>
    </div>
  );
};

export default PhysiologyReport;
