import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { API_URL } from "../../../config";
import { Box } from "@mui/material";

const VerifyEmail = () => {
    const { token } = useParams();  // Get the token from URL
    
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const toggleSuccessModal = () => setShowSuccessModal(!showSuccessModal);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const verifyEmail = async () => {
        setErrorMessage("");
        setLoading(true);
      
      
        if (!token) {
          setErrorMessage("Authentication token is missing.");
          setLoading(false);
          return;
        }
      
        if (!email) {
          setErrorMessage("Email is required.");
          setLoading(false);
          return;
        }
      
        const data = { email };
      
        try {
          const response = await fetch(`${API_URL}/verify-email`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
            body: JSON.stringify(data),
          });
      
          const resp = await response.json();
      
          if (response.ok) {
            toggleSuccessModal();
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          } else {
            setErrorMessage(resp.message || "Verification failed.");
          }
        } catch (error) {
          console.error("Error verifying email:", error);
          setErrorMessage("An error occurred. Please try again.");
        } finally {
          setLoading(false);
        }
      };
      
    

    return (
        <div>
            <Box className="reset-password-bg">
                <div className="container">
                    <div className="login-content">
                        <ul className="reset-input-list">
                            <li>
                                <input
                                    type="email"
                                    placeholder="Enter Your Email"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                                {errorMessage && <p className="error-text">{errorMessage}</p>}
                            </li>
                            <li>
                                <div className="submit-btn-reset">
                                    <button onClick={verifyEmail}>
                                        Verify Email
                                        {loading && <div id="loader"></div>}
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Box>

            <Modal isOpen={showSuccessModal} toggle={toggleSuccessModal} centered>
                <ModalHeader toggle={toggleSuccessModal}>
                    <span className="ml-1 roititle">Email Verified Successfully</span>
                </ModalHeader>
                <ModalBody>
                    <div className="modal-p">
                        <p style={{
                            padding:"1rem"
                        }}>
                            <a href="/">Click here to go to Login Page</a>
                        </p>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default VerifyEmail;
