
import { ChartModifierBase2D, CustomChartModifier2D, EChart2DModifierType, MouseWheelZoomModifier, RubberBandXyZoomModifier, ZoomPanModifier } from "scichart";

export class CustomRubberBandXyZoomModifier extends RubberBandXyZoomModifier {
  constructor(cb,options = {}) {
    super(options);
    this.callback  = cb
  }


  performZoom(start,to) {
    super.performZoom(start, to);

    setTimeout(() => {
      if (this.callback) {
        this.callback(start);
      }        
    }, 300);
  }

  // modifierMouseUp(args) {
  //   super.modifierMouseUp(args);
  //   this.callback(args);
  // }



}