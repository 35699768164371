import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import Header from "../../component/Header";
import Filter from "../../component/Filter";
import Sidebar from "../../component/Sidebar";
import { API_URL } from "../../../config";

const Viewmanageform = () => {
  const { t } = useTranslation();
  const accessToken = localStorage.getItem("accessToken");
  const userId = localStorage.getItem("user_id");
  const [selectedClient, setSelectedClient] = useState(
    localStorage.getItem("selectedClient") == "null"
      ? userId
      : localStorage.getItem("selectedClient")
  );

  const [selectedSession, setSelectedSession] = useState();
  // const selectedtrainerActive = localStorage.getItem('selectedtrainerActive');
  const [clientlength, setClientlength] = useState([]);
  const [trainerLength, setTrainerLength] = useState([]);
  const [selectedHomework, setselectedHomework] = useState("selectedHomework");
  const [homeworklist, setHomeworklist] = useState([]);
  const [userType, setUserType] = useState();
  const [sessionLength, setSessionLength] = useState([]);
  const [selectedtraineractive, setSelectedtraineractive] = useState();
  const [selectedtrainer, setSelectedtrainer] = useState();
  const [selectedclientactive, setSelectedclientactive] = useState();

  useEffect(() => {
    setInterval(() => {
      setSelectedClient(
        localStorage.getItem("selectedClient") == "null"
          ? userId
          : localStorage.getItem("selectedClient")
      );
      setSelectedSession(localStorage.getItem("selectedSession"));
      setselectedHomework(localStorage.getItem("selectedHomework"));
      setSelectedtraineractive(localStorage.getItem("selectedtrainerActive"));
      setUserType(localStorage.getItem("userType"));
      setSelectedtrainer(localStorage.getItem("selectedTrainer"));
      setSelectedclientactive(localStorage.getItem("selectedclientActive"));
    }, 1000);
  }, []);

  useEffect(() => {
    if (selectedClient !== "null") {
      getclientform();
      viewtrainerform();
      Homeworklist();
      getSession();
    }
  }, [selectedClient, selectedSession, selectedHomework]);

  const getclientform = () => {
    fetch(API_URL + "/forms/client/" + selectedClient, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": accessToken,
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((resp) => {
          // console.log("result", resp);
          setClientlength(resp.data);
        });
      } else if (response.status == 401) {
        logout();
      } else {
        console.log("network error");
      }
    });
  };

  const viewtrainerform = () => {
    fetch(API_URL + "/forms/trainer/" + selectedClient, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": accessToken,
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((resp) => {
          // // console.log("result", resp);

          let _temp = [];
          resp.data.map((v, i) => {
            if (v.sessid == selectedSession || v.sessid == "null") {
              _temp.push(v);
            }
            setTrainerLength(_temp);
            if (i == resp.data.length - 1) {
              setTrainerLength(_temp);
            }
          });
        });
      } else if (response.status == 401) {
        logout();
      } else {
        console.log("network error");
      }
    });
  };

  const getSession = () => {
    let _cid;

    if (localStorage.getItem("selectedClient") == "null") {
      _cid = userId;
    } else {
      _cid = localStorage.getItem("selectedClient");
    }

    let _hw = 0;

    let url = API_URL + "/sessions?cid=" + _cid + "&hw=" + _hw;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": accessToken,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          response.json().then((result) => {
            // // console.log(result.sesstion)

            setSessionLength(result.sessions);
          });
        } else if (response.status == 401) {
          logout();
        } else {
          console.log("network error");
        }
      })
      .catch((err) => {
        // // console.log(err)
      });
  };

  const Homeworklist = () => {
    fetch(API_URL + "/homework/client/" + selectedClient, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": accessToken,
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((resp) => {
          // console.log("result", resp);
          setHomeworklist(resp.data);
        });
      } else if (response.status == 401) {
        logout();
      } else {
        console.log("network error");
      }
    });
  };

  const setHomework = () => {
    if (selectedHomework === "false" && sessionLength.length > 0) {
      localStorage.setItem("selectedHomework", true);
      localStorage.setItem("selectedStandard", false);
      localStorage.setItem("selectedSession", null);
      window.location.reload();
    }
  };

  const logout = () => {
    localStorage.clear();
    // alert("You Logout successful")
  };

  return (
    <div>
      <Header />
      <div className="wrp-dashbord">
        <div className="sidebar-section">
          <Sidebar />
        </div>
        <div className="right-section">
          <Filter managesessionid="5" />
          {!userType ? (
            ""
          ) : (
            <>
              <div className="create-section">
                <ul className="create-list">
                  <li>
                    <div className="create-list-box">
                      <Link to="/blank-forms">
                        {t("Download-Fill-in-PDF-Blank-Forms")}
                      </Link>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="create-list-box">
                      <Link
                        to={
                          (
                            selectedClient === "" ||
                            selectedClient === "Choose a Client" ||
                            selectedtraineractive == "false" ||
                            userType == 3 ||
                            userType == 4
                              ? ""
                              : selectedtrainer == "null"
                          )
                            ? ""
                            : "/upload/client/form-online"
                        }
                        className={
                          (
                            selectedClient === "" ||
                            selectedClient === "Choose a Client" ||
                            selectedtraineractive == "false" ||
                            userType == 3 ||
                            userType == 4
                              ? ""
                              : selectedtrainer == "null"
                          )
                            ? "deactivate"
                            : ""
                        }
                      >
                        {t("Complete Client Forms Online")}
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="create-list-box">
                      <Link
                        to={
                          (
                            selectedClient === "" ||
                            selectedClient === "Choose a Client" ||
                            selectedtraineractive == "false" ||
                            userType == 3 ||
                            userType == 4
                              ? ""
                              : selectedtrainer == "null"
                          )
                            ? ""
                            : "/upload/client/form"
                        }
                        className={
                          (
                            selectedClient === "" ||
                            selectedClient === "Choose a Client" ||
                            selectedtraineractive == "false" ||
                            userType == 3 ||
                            userType == 4
                              ? ""
                              : selectedtrainer == "null"
                          )
                            ? "deactivate"
                            : ""
                        }
                      >
                        {t("Upload-Completed-Client-Forms")}
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="create-list-box">
                      <Link
                        to={
                          (
                            clientlength.length == 0 ||
                            selectedtraineractive == "false" ||
                            selectedclientactive == "false" ||
                            userType == 3 ||
                            userType == 4
                              ? ""
                              : selectedtrainer == "null"
                          )
                            ? ""
                            : "/view/uploaded/client/form"
                        }
                        className={
                          (
                            clientlength.length == 0 ||
                            selectedtraineractive == "false" ||
                            selectedclientactive == "false" ||
                            userType == 3 ||
                            userType == 4
                              ? ""
                              : selectedtrainer == "null"
                          )
                            ? "deactivate"
                            : ""
                        }
                      >
                        {t("View-Completed-Client-Forms")}
                      </Link>
                    </div>
                  </li>
                  <br />
                  {userType == 3 || userType == 4 ? (
                    ""
                  ) : (
                    <>
                      <li>
                        <div className="create-list-box">
                          <Link
                            to={
                              selectedClient === "" ||
                              selectedClient === "Choose a Client" ||
                              selectedtraineractive == "false" ||
                              selectedtrainer == "null"
                                ? ""
                                : "/upload/trainer/form-online"
                            }
                            className={
                              selectedtraineractive == "false" ||
                              selectedtrainer == "null"
                                ? "deactivate"
                                : ""
                            }
                          >
                            {t("Complete Trainer Forms Online")}
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="create-list-box">
                          <Link
                            to={
                              selectedClient === "" ||
                              selectedClient === "Choose a Client" ||
                              selectedtraineractive == "false" ||
                              selectedtrainer == "null"
                                ? ""
                                : "/upload/trainer/form"
                            }
                            className={
                              selectedtraineractive == "false" ||
                              selectedtrainer == "null"
                                ? "deactivate"
                                : ""
                            }
                          >
                            {t("Upload-Completed-Trainer-Forms")}
                          </Link>
                        </div>
                      </li>
                    </>
                  )}

                  {userType && userType != 3 ? (
                    <li>
                      <div className="create-list-box">
                        <Link
                          to={
                            trainerLength?.length == 0 ||
                            selectedtraineractive == "false" ||
                            (userType == 4 ? "" : selectedtrainer == "null") ||
                            selectedclientactive == "false"
                              ? ""
                              : "/view/uploaded/trainer/form"
                          }
                          className={
                            trainerLength?.length == 0 ||
                            selectedtraineractive == "false" ||
                            (userType == 4 ? "" : selectedtrainer == "null") ||
                            selectedclientactive == "false"
                              ? "deactivate"
                              : ""
                          }
                        >
                          {t("View-Completed-Trainer-Forms")}
                        </Link>
                      </div>
                    </li>
                  ) : (
                    <li>
                      <div className="create-list-box">
                        <Link
                          to={
                            selectedSession === "" ||
                            selectedSession === "null" ||
                            selectedtraineractive == "false" ||
                            selectedclientactive == "false"
                              ? ""
                              : "/view/uploaded/trainer/form"
                          }
                          className={
                            selectedSession === "" ||
                            selectedSession === "null" ||
                            selectedtraineractive == "false" ||
                            selectedclientactive == "false" ||
                            (userType == 3 ? "" : selectedtrainer == "null")
                              ? "deactivate"
                              : ""
                          }
                        >
                          {t("View-Completed-Trainer-Forms")}
                        </Link>
                      </div>
                    </li>
                  )}
                  <br />

                  {userType == 2 ? (
                    ""
                  ) : (
                    <>
                      <li>
                        <div
                          className="create-list-box"
                          onClick={() => setHomework()}
                        >
                          <Link
                            to={
                              selectedHomework === "false" ||
                              selectedSession === "" ||
                              selectedSession === "null" ||
                              selectedtraineractive == "false"
                                ? ""
                                : "/upload/homework/asignment-online"
                            }
                            className={
                              selectedHomework === "false" ||
                              selectedSession === "" ||
                              selectedSession === "null" ||
                              selectedtraineractive == "false"
                                ? "deactivate"
                                : ""
                            }
                          >
                            {t("Complete Homework Assignment Online")}
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div
                          className="create-list-box"
                          onClick={() => setHomework()}
                        >
                          <Link
                            to={
                              selectedHomework === "false" ||
                              selectedSession === "" ||
                              selectedSession === "null" ||
                              selectedtraineractive == "false"
                                ? ""
                                : "/upload/homework/asignment"
                            }
                            className={
                              selectedHomework === "false" ||
                              selectedSession === "" ||
                              selectedSession === "null" ||
                              selectedtraineractive == "false"
                                ? "deactivate"
                                : ""
                            }
                          >
                            {t("Upload-Homework-Assignment")}
                          </Link>
                        </div>
                      </li>
                    </>
                  )}
                  <li>
                    <div
                      className="create-list-box"
                      onClick={() => setHomework()}
                    >
                      <Link
                        to={
                          selectedHomework === "false" ||
                          selectedSession === "" ||
                          selectedSession === "null" ||
                          sessionLength.length == 0 ||
                          homeworklist.length == 0 ||
                          selectedtraineractive == "false" ||
                          selectedclientactive == "false"
                            ? ""
                            : "/view/completed/client/work"
                        }
                        className={
                          selectedHomework === "false" ||
                          selectedSession === "" ||
                          selectedSession === "null" ||
                          sessionLength.length == 0 ||
                          homeworklist.length == 0 ||
                          selectedtraineractive == "false" ||
                          selectedclientactive == "false"
                            ? "deactivate"
                            : ""
                        }
                      >
                        {t("View-Homework-Assignment")}
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Viewmanageform;
