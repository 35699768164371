import React, { useState } from "react";

const BreathingBehaviorsForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    date: "",
    email: "",
    tel: "",
    sex: "",
    age: "",
    primaryHealthIssue: "",
    breathingIssue: "",
    breathingQuestions: Array(13).fill(""),
  });

  const symptoms = [
    "Chest tightness, pressure, or pain",
    "Intentional breathing, purposeful regulation",
    "Dizziness, light-headedness, fainting",
    "Shortness of breath, difficulty breathing",
    "Tingling or numbness, e.g., fingers, lips",
    "Unable to breathe deeply",
    "Not exhaling completely, aborting the exhale",
    "Deep breathing, like during talking",
    "Chest breathing, effortful breathing",
    "Breath holding, irregular breathing",
    "Rapid breathing, panicky breathing",
    "Worried about my breathing",
    "Mouth breathing",
    "Can't seem to get enough oxygen",
  ];

  const situations = [
    "(01) working (employment)",
    "(02) resting (between tasks)",
    "(03) performing (e.g., test taking)",
    "(04) feeling anxious or worried",
    "(05) feeling tired or stressed",
    "(06) interacting in groups",
    "(07) traveling, unfamiliar places",
    "(08) socializing, meeting people",
    "(09) feeling angry or upset",
    "(10) intimacy, expressing feelings",
    "(11) physical discomfort, pain",
    "(12) going to sleep, while asleep",
    "(13) learning new tasks, new info",
    "(14) feeling unsure of self",
    "(15) allergens, weather, foods",
  ];

  const breathingQuestions = [
    "Issues related to breathing?",
    "Episodes of not being able to get enough air?",
    "Respiratory disorders?",
    "Physical injuries: e.g., back, chest, neck?",
    "Emotional issues: e.g., panic, anxiety, anger?",
    "Life traumas: e.g., PTSD, emotional abuse, chronic stress?",
    "Pain issues: past or present, acute or chronic?",
    "Physical limitations: e.g., fatigue, speech, movement?",
    "Deficiencies: e.g., electrolytes (kidney problems)?",
    "Social challenges: e.g., relationships, family?",
    "Work related challenges: e.g., co-workers, supervisor?",
    "Learning issues, e.g., attention, memory, focus?",
    "Performance issues: e.g., speaking, technology, testing",
    "Current prescriptions?",
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "10px",
        border: "1px solid #000",
        marginTop: "20px",
      }}
    >
      <h1
        style={{
          color: "#cc0000",
          textAlign: "center",
          margin: "10px 0",
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        YOUR BREATHING BEHAVIORS
      </h1>

      <p
        style={{
          margin: "10px 0",
          fontSize: "14px",
          lineHeight: "1.4",
        }}
      >
        <strong>Everyone has breathing habits.</strong> Most people are NOT
        aware of their breathing habits and how they may be interacting with
        other complaints, symptoms, and deficits. This checklist has been
        designed to serve as a "guideline" for assisting you in exploring
        whether or not your breathing habits are consistent with optimal
        respiration, and if not, how they may be affecting you at specific times
        and places.
      </p>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          margin: "15px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <label style={{ fontWeight: "bold", marginRight: "5px" }}>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            style={{
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              width: "300px",
              height: "30px",
              outline: "none",
              backgroundColor: "#f1f4ff",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <label style={{ fontWeight: "bold", marginRight: "5px" }}>Date</label>
          <input
            type="text"
            name="date"
            value={formData.date}
            onChange={handleInputChange}
            style={{
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              width: "190px",
              height: "30px",
              outline: "none",
              backgroundColor: "#f1f4ff",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <label style={{ fontWeight: "bold", marginRight: "5px" }}>
            Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            style={{
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              width: "270px",
              height: "30px",
              outline: "none",
              backgroundColor: "#f1f4ff",
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          margin: "15px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <label style={{ fontWeight: "bold", marginRight: "5px" }}>Tel</label>
          <input
            type="tel"
            name="tel"
            value={formData.tel}
            onChange={handleInputChange}
            style={{
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              width: "170px",
              height: "30px",
              outline: "none",
              backgroundColor: "#f1f4ff",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <label style={{ fontWeight: "bold", marginRight: "5px" }}>Sex</label>
          <input
            type="text"
            name="sex"
            value={formData.sex}
            onChange={handleInputChange}
            style={{
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              width: "50px",
              height: "30px",
              outline: "none",
              backgroundColor: "#f1f4ff",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <label style={{ fontWeight: "bold", marginRight: "5px" }}>Age</label>
          <input
            type="text"
            name="age"
            value={formData.age}
            onChange={handleInputChange}
            style={{
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              width: "50px",
              height: "30px",
              outline: "none",
              backgroundColor: "#f1f4ff",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <label style={{ fontWeight: "bold", marginRight: "5px" }}>
            Primary health issue?
          </label>
          <input
            type="text"
            name="primaryHealthIssue"
            value={formData.primaryHealthIssue}
            onChange={handleInputChange}
            style={{
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              width: "370px",
              height: "30px",
              outline: "none",
              backgroundColor: "#f1f4ff",
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "15px 0",
        }}
      >
        <label style={{ fontWeight: "bold", marginRight: "5px" }}>
          Is there a breathing issue?
        </label>
        <input
          type="text"
          name="breathingIssue"
          value={formData.breathingIssue}
          onChange={handleInputChange}
          style={{
            borderBottom: "1px solid black",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            flexGrow: 1,
          }}
        />
      </div>

      <p
        style={{
          fontWeight: "bold",
          margin: "15px 0 5px",
        }}
      >
        Answers to the following questions are important to learning about the
        possible origins of your breathing habits.
      </p>

      {breathingQuestions.map((question, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            margin: "5px 0",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100px",
            }}
          >
            <input
              type="checkbox"
              name={`breathingQuestionYes[${index}]`}
              checked={formData.breathingQuestions[index] === "YES"}
              onChange={(e) => {
                const newBreathingQuestions = [...formData.breathingQuestions];
                newBreathingQuestions[index] = e.target.checked ? "YES" : "NO";
                setFormData({
                  ...formData,
                  breathingQuestions: newBreathingQuestions,
                });
              }}
              style={{ marginRight: "5px" }}
            />
            <span style={{ marginRight: "5px" }}>YES</span>
            <input
              type="checkbox"
              name={`breathingQuestionNo[${index}]`}
              checked={formData.breathingQuestions[index] === "NO"}
              onChange={(e) => {
                const newBreathingQuestions = [...formData.breathingQuestions];
                newBreathingQuestions[index] = e.target.checked ? "NO" : "YES";
                setFormData({
                  ...formData,
                  breathingQuestions: newBreathingQuestions,
                });
              }}
              style={{ marginRight: "5px" }}
            />

            <span>NO</span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <span style={{ marginRight: "10px" }}>{question}</span>
            <input
              type="text"
              name={`breathingQuestions[${index}]`}
              value={formData.breathingQuestions[index]}
              onChange={(e) => {
                const newBreathingQuestions = [...formData.breathingQuestions];
                newBreathingQuestions[index] = e.target.value;
                setFormData({
                  ...formData,
                  breathingQuestions: newBreathingQuestions,
                });
              }}
              style={{
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                flexGrow: 1,
                height: "30px",
                outline: "none",
                backgroundColor: "#f1f4ff",
              }}
            />
          </div>
        </div>
      ))}

      <div style={{ margin: "20px 0 10px" }}>
        <p style={{ fontWeight: "bold" }}>
          Do you ever experience any of the 14 symptoms listed below? Check the
          Y column for "YES," OR the N column for "NO," after each symptom
          listed. If you checked YES, indicate{" "}
          <span style={{ fontStyle: "italic", textDecoration: "underline" }}>
            how frequently you experience the symptom
          </span>{" "}
          by checking a number 1 through 7, where 1 is rarely and 7 is every
          day. Then enter in the{" "}
          <span style={{ fontStyle: "italic", textDecoration: "underline" }}>
            situations in which you experience a symptom
          </span>
          , in the "situation column," by entering a number that corresponds to
          one of the 15 situations listed at the bottom of the page. If the
          situation is not shown on the list, write it on the "comment" column.
          Focus on when, where, and with whom these symptoms may occur.
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px 0",
            fontSize: "12px",
          }}
        >
          <span>
            How often? 1 = rarely ............................ 7 = every day
          </span>
        </div>

        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            border: "1px solid black",
            fontSize: "12px",
          }}
        >
          <thead
            style={{
              backgroundColor: "#f3f3f3",
            }}
          >
            <tr>
              <th
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "left",
                  width: "30%",
                }}
              >
                Do you experience the following? If so, how often?
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                  width: "5%",
                }}
              >
                N
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                  width: "5%",
                }}
              >
                Y
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                  width: "5%",
                }}
              >
                1
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                  width: "5%",
                }}
              >
                2
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                  width: "5%",
                }}
              >
                3
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                  width: "5%",
                }}
              >
                4
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                  width: "5%",
                }}
              >
                5
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                  width: "5%",
                }}
              >
                6
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                  width: "5%",
                }}
              >
                7
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                  width: "10%",
                }}
              >
                Situations
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  textAlign: "center",
                  width: "15%",
                }}
              >
                Comment
              </th>
            </tr>
          </thead>
          <tbody>
            {symptoms.map((symptom, index) => (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "5px",
                    backgroundColor: "#fff7f7",
                  }}
                >
                  {symptom}
                </td>
                {[...Array(9)].map((_, index) => (
                  <td
                    style={{
                      border: "1px solid #999",
                      padding: "4px",
                      textAlign: "center",
                      backgroundColor: "#f1effa",
                    }}
                  >
                    <input
                      type="checkbox"
                      name={`yesNo-${symptom.id}`}
                      style={{
                        width: "16px",
                        height: "16px",
                        appearance: "none",
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onChange={(e) => {
                        e.target.style.backgroundColor = e.target.checked
                          ? "#007bff"
                          : "transparent";
                        e.target.style.borderRadius = "50%"; // To make it look like a dot
                      }}
                    />
                  </td>
                ))}
                <td
                  style={{
                    border: "1px solid #999",
                    padding: "4px",
                    backgroundColor: "#f1effa",
                  }}
                >
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      background: "transparent",
                      border: "none",
                      outline: "none",
                    }}
                  />
                </td>
                <td
                  style={{
                    border: "1px solid #999",
                    padding: "4px",
                    backgroundColor: "#f1effa",
                  }}
                >
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      background: "transparent",
                      border: "none",
                      outline: "none",
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div style={{ margin: "20px 0" }}>
        <p
          style={{
            fontSize: "14px",
            marginBottom: "5px",
          }}
        >
          <strong
            style={{
              fontWeight: "bold",
              color: "#cc0000",
            }}
          >
            *SITUATIONS:
          </strong>{" "}
          circumstances under which you experience the above symptoms
        </p>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            fontSize: "12px",
          }}
        >
          {situations.map((situation, index) => (
            <div
              key={index}
              style={{
                width: "33%",
                padding: "3px 0",
              }}
            >
              {situation}
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          margin: "20px 0",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label
            style={{
              fontWeight: "bold",
              marginRight: "5px",
            }}
          >
            General comments:
          </label>
          <input
            type="text"
            style={{
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              flexGrow: 1,
              height: "30px",
              outline: "none",
              backgroundColor: "#f1f4ff",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BreathingBehaviorsForm;
