import React from "react";

function Behaviour() {
  return (
    <div>
      <h1
        style={{
          fontSize: "24px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        BEHAVIOR REPORT
      </h1>

      <div className="client-info">
        <div className="client-info-item">
          <label>Client Name:</label>
          <input
            style={{
              width: "310px",
              border: "none",
              borderBottom: "1px solid black",
              backgroundColor: "#f1f4ff",
            }}
          />
        </div>

        <div className="client-info-item">
          <label
            style={{
              marginRight: "10px",
            }}
          >
            Type:
          </label>
          <div className="type-checkboxes">
            <input type="checkbox" id="typeA" />
            <label htmlFor="typeA" className="checkbox-label-small">
              A
            </label>
            <input type="checkbox" id="typeT" />
            <label htmlFor="typeT" className="checkbox-label-small">
              T
            </label>
          </div>
        </div>

        <div className="client-info-item">
          <label>Session #</label>
          <input
            style={{
              width: "50px",
              border: "none",
              borderBottom: "1px solid black",
              backgroundColor: "#f1f4ff",
            }}
          />
        </div>

        <div className="client-info-item">
          <label>Date:</label>
          <input
            type="text"
            style={{
              width: "100px",
              border: "none",
              borderBottom: "1px solid black",
              backgroundColor: "#f1f4ff",
            }}
          />
        </div>
      </div>

      <div className="form-row">
        <label>Practitioner Name:</label>
        <input
          style={{
            flex: 1,
            border: "none",
            borderBottom: "1px solid black",
            backgroundColor: "#f1f4ff",
          }}
        />
        <label>Email:</label>
        <input
          style={{
            flex: 1,
            border: "none",
            borderBottom: "1px solid black",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>

      <div className="form-row">
        <label>Possible origin of habit</label>
        <input
          style={{
            flex: 1,
            border: "none",
            borderBottom: "1px solid black",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>

      <div className="section-title">Dysfunctional breathing behaviors:</div>
      <div className="checkbox-grid">
        <div className="checkbox-item">
          <input type="checkbox" id="aborted" />
          <label htmlFor="aborted" className="checkbox-label">
            Aborted exhale
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="gasping" />
          <label htmlFor="gasping" className="checkbox-label">
            Gasping, sighing
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="assisting" />
          <label htmlFor="assisting" className="checkbox-label">
            Assisting the inhale
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="intentional" />
          <label htmlFor="intentional" className="checkbox-label">
            Intentional manipulations
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="avoidance" />
          <label htmlFor="avoidance" className="checkbox-label">
            Avoidance of transition time
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="mouth" />
          <label htmlFor="mouth" className="checkbox-label">
            Mouth breathing
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="breath" />
          <label htmlFor="breath" className="checkbox-label">
            Breath holding
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="overbreathing" />
          <label htmlFor="overbreathing" className="checkbox-label">
            Overbreathing
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="chest" />
          <label htmlFor="chest" className="checkbox-label">
            Chest breathing
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="rapid" />
          <label htmlFor="rapid" className="checkbox-label">
            Rapid breathing
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="doing" />
          <label htmlFor="doing" className="checkbox-label">
            Doing breathing (not allowing)
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="reverse" />
          <label htmlFor="reverse" className="checkbox-label">
            Reverse breathing
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="dysponesis" />
          <label htmlFor="dysponesis" className="checkbox-label">
            Dysponesis
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="taking" />
          <label htmlFor="taking" className="checkbox-label">
            Taking deep breaths
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="effortful" />
          <label htmlFor="effortful" className="checkbox-label">
            Effortful breathing
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="underbreathing" />
          <label htmlFor="underbreathing" className="checkbox-label">
            Underbreathing
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="forced" />
          <label htmlFor="forced" className="checkbox-label">
            Forced exhalation
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="other1" />
          <label htmlFor="other1" className="checkbox-label">
            Other
          </label>
          <input className="checkbox-underline" />
        </div>
      </div>

      <div className="section-title">
        Breathing habit triggers observed DURING INTERVIEW:
      </div>
      <div className="checkbox-grid">
        <div className="checkbox-item">
          <input type="checkbox" id="behaviors" />
          <label htmlFor="behaviors" className="checkbox-label">
            Behaviors
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="memories" />
          <label htmlFor="memories" className="checkbox-label">
            Memories
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="mechanics" />
          <label htmlFor="mechanics" className="checkbox-label">
            Breathing mechanics
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="discomfort" />
          <label htmlFor="discomfort" className="checkbox-label">
            Physical discomfort
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="self-interventions" />
          <label htmlFor="self-interventions" className="checkbox-label">
            Breathing self-interventions
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="postural" />
          <label htmlFor="postural" className="checkbox-label">
            Postural changes
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="content" />
          <label htmlFor="content" className="checkbox-label">
            Conversational content
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="tasks" />
          <label htmlFor="tasks" className="checkbox-label">
            Tasks
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="emotions" />
          <label htmlFor="emotions" className="checkbox-label">
            Emotions
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="thoughts" />
          <label htmlFor="thoughts" className="checkbox-label">
            Thoughts
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="cues" />
          <label htmlFor="cues" className="checkbox-label">
            Kinesthetic cues
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="other2" />
          <label htmlFor="other2" className="checkbox-label">
            Other
          </label>
          <input className="checkbox-underline" />
        </div>
      </div>

      <div className="section-title">
        Motivations & reinforcements of dysfunctional breathing habits:
      </div>
      <div className="checkbox-grid">
        <div className="checkbox-item">
          <input type="checkbox" id="alternative" />
          <label htmlFor="alternative" className="checkbox-label">
            Access to an alternative sense of self
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="control" />
          <label htmlFor="control" className="checkbox-label">
            Feeling in control
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="behaviors2" />
          <label htmlFor="behaviors2" className="checkbox-label">
            Access to behaviors
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="oxygen" />
          <label htmlFor="oxygen" className="checkbox-label">
            Feeling of "getting more oxygen"
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="emotions2" />
          <label htmlFor="emotions2" className="checkbox-label">
            Access to emotions
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="self-esteem" />
          <label htmlFor="self-esteem" className="checkbox-label">
            Feeling of better self-esteem
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="symptoms" />
          <label htmlFor="symptoms" className="checkbox-label">
            Access to symptoms
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="freer" />
          <label htmlFor="freer" className="checkbox-label">
            Feeling of being "freer", safer
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="physical" />
          <label htmlFor="physical" className="checkbox-label">
            Access to physical change
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="deepness" />
          <label htmlFor="deepness" className="checkbox-label">
            Feeling of "deepness in the breath," relaxation
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="avoid-memories" />
          <label htmlFor="avoid-memories" className="checkbox-label">
            Avoidance of memories
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="vulnerability" />
          <label htmlFor="vulnerability" className="checkbox-label">
            Reduced sense of vulnerability
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="avoid-emotions" />
          <label htmlFor="avoid-emotions" className="checkbox-label">
            Avoidance of emotions
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="air-hunger" />
          <label htmlFor="air-hunger" className="checkbox-label">
            Reduction of air hunger
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="avoid-pain" />
          <label htmlFor="avoid-pain" className="checkbox-label">
            Avoidance of discomfort (pain)
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="fear" />
          <label htmlFor="fear" className="checkbox-label">
            Reduction of fear/anxiety
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="avoid-thoughts" />
          <label htmlFor="avoid-thoughts" className="checkbox-label">
            Avoidance of thoughts
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="secondary" />
          <label htmlFor="secondary" className="checkbox-label">
            Secondary gain
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="confirmation" />
          <label htmlFor="confirmation" className="checkbox-label">
            Confirmation of personal interpretations
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="state" />
          <label htmlFor="state" className="checkbox-label">
            State change
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="stability" />
          <label htmlFor="stability" className="checkbox-label">
            Stability
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="familiarity" />
          <label htmlFor="familiarity" className="checkbox-label">
            Familiarity, using favorite muscles
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="dissociation" />
          <label htmlFor="dissociation" className="checkbox-label">
            Dissociation, disconnectedness
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="other3" />
          <label htmlFor="other3" className="checkbox-label">
            Other
          </label>
          <input className="checkbox-underline" />
        </div>
      </div>

      <div className="section-title">
        Breathing behaviors and symptoms that trigger learned emotions (e.g.,
        anxiety):
      </div>
      <div className="checkbox-grid">
        <div className="checkbox-item">
          <input type="checkbox" id="allowing" />
          <label htmlFor="allowing" className="checkbox-label">
            Allowing the breathing
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="slowness" />
          <label htmlFor="slowness" className="checkbox-label">
            Slowness of the exhale
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="approaching" />
          <label htmlFor="approaching" className="checkbox-label">
            Approaching the end of the exhale
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="small" />
          <label htmlFor="small" className="checkbox-label">
            Small breaths
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="chest2" />
          <label htmlFor="chest2" className="checkbox-label">
            Breathing in the chest
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="state2" />
          <label htmlFor="state2" className="checkbox-label">
            State changes
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="diaphragm" />
          <label htmlFor="diaphragm" className="checkbox-label">
            Breathing in the diaphragm
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="transition" />
          <label htmlFor="transition" className="checkbox-label">
            Transition time (exhale to inhale)
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="deep" />
          <label htmlFor="deep" className="checkbox-label">
            Can't take a deep breath
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="body" />
          <label htmlFor="body" className="checkbox-label">
            Changes in body sensations
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="fast" />
          <label htmlFor="fast" className="checkbox-label">
            Fast breathing
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="hypocapnic" />
          <label htmlFor="hypocapnic" className="checkbox-label">
            Hypocapnic symptoms
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="nose" />
          <label htmlFor="nose" className="checkbox-label">
            Nose breathing
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="slow" />
          <label htmlFor="slow" className="checkbox-label">
            Slow breathing
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="others" />
          <label htmlFor="others" className="checkbox-label">
            Others
          </label>
          <input className="checkbox-underline" />
        </div>
      </div>

      <div className="section-title">Cognition associated with breathing:</div>
      <div className="checkbox-grid">
        <div className="checkbox-item">
          <input type="checkbox" id="done" />
          <label htmlFor="done" className="checkbox-label">
            Breathing is "done" (not allowed)
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="memories2" />
          <label htmlFor="memories2" className="checkbox-label">
            Memories
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="disruptive" />
          <label htmlFor="disruptive" className="checkbox-label">
            Disruptive thoughts
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="self-talk" />
          <label htmlFor="self-talk" className="checkbox-label">
            Self-talk
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="faulty" />
          <label htmlFor="faulty" className="checkbox-label">
            Faulty beliefs
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="misinterpretation" />
          <label htmlFor="misinterpretation" className="checkbox-label">
            Symptom misinterpretation
          </label>
          <input className="checkbox-underline" />
        </div>
      </div>

      <div className="section-title">
        Breathing challenges used and outcomes{" "}
        <span className="smaller-note">(See Guided Overbreathing Record):</span>
      </div>
      <div>
        <div className="checkbox-item">
          <input type="checkbox" id="vivo" />
          <label htmlFor="vivo" className="checkbox-label">
            En vivo test
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="guided" />
          <label htmlFor="guided" className="checkbox-label">
            Guided breathing
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="imagination" />
          <label htmlFor="imagination" className="checkbox-label">
            Imagination, simulation
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="mechanics2" />
          <label htmlFor="mechanics2" className="checkbox-label">
            Mechanics challenge
          </label>
          <input className="checkbox-underline" />
        </div>
        <div className="checkbox-item">
          <input type="checkbox" id="task" />
          <label htmlFor="task" className="checkbox-label">
            Task challenge
          </label>
          <input className="checkbox-underline" />
        </div>
      </div>
    </div>
  );
}

export default Behaviour;
