import React from "react";

const OutcomeReport = () => {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "20px",
        border: "1px solid #ccc",
        marginTop: "20px",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          color: "#7d3c98",
          fontSize: "24px",
          marginBottom: "20px",
        }}
      >
        OUTCOME REPORT
      </h1>

      <div style={{ display: "flex", marginBottom: "10px" }}>
        <div>
          <span>Practitioner Name</span>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "300px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div>
          <span>Referrer Name</span>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "300px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
      </div>

      <div style={{ display: "flex", marginBottom: "10px" }}>
        <div style={{ width: "40%" }}>
          <span>Client Name</span>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "230px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ width: "40%" }}>
          <span>Email</span>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "250px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ width: "20%" }}>
          <span>Tel</span>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "150px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <span style={{ fontWeight: "bold" }}>OVERALL LEARNING SUCCESS </span>
        <input
          style={{
            display: "inline-block",
            width: "40px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        ></input>
        <span> (1 - 7, where 1 = no progress and 7 = complete success)</span>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <p style={{ fontWeight: "bold", margin: "10px 0" }}>
          Please rate the following about your client and assign a number
          between 1 and 7.
        </p>

        <div style={{ marginBottom: "5px" }}>
          <span>Demonstrated ability to detect and regulate CO</span>
          <span style={{ fontSize: "11px", verticalAlign: "sub" }}>2</span>
          <span> levels </span>
          <input
            style={{
              display: "inline-block",
              width: "40px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
          <span> (1 – 7, where 1 = minimal, and 7 = excellent)</span>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>Abatement of symptoms/deficits </span>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "40px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
          <span>
            {" "}
            (1 - 7, where 1 = no abatement, and 7 = symptoms/deficits gone)
          </span>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>Demonstrated ability to self-explore breathing </span>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "40px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
          <span> (1 – 7, where 1 = very little, and 7 = excellent)</span>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>Motivation for learning new breathing habits, </span>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "40px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
          <span> (1 – 7, where 1 = very low, and 7 = highly motivated)</span>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>Homework assignment completion </span>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "40px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
          <span>
            {" "}
            (1 - 7, where 1 = no completions, and 7 = all assignments)
          </span>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>Acceptance of personal responsibility to learn </span>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "40px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
          <span>
            {" "}
            (1 – 7, where 1 = very poor, and 7 = enthusiastic acceptance)
          </span>
        </div>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <p style={{ fontStyle: "italic", margin: "10px 0" }}>
          Why did the client decide to sign-up for breathing learnings services?
        </p>
        <textarea
          style={{
            height: "80px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <p style={{ fontStyle: "italic", margin: "10px 0" }}>
          What were the specific client- breathing learning objectives? How
          successful was your client?
        </p>
        <textarea
          style={{
            height: "80px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <p style={{ fontStyle: "italic", margin: "10px 0" }}>
          What were the specific symptoms and/or deficits addressed? How
          successful was your client?
        </p>
        <textarea
          style={{
            height: "80px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <p style={{ fontStyle: "italic", margin: "10px 0" }}>
          Were there learning hurdles? What were they? Were they overcome?
        </p>
        <textarea
          style={{
            height: "40px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <p style={{ fontWeight: "bold", margin: "10px 0" }}>
          Please answer these questions about learning outcomes. Be specific.
          Write N/A if not relevant.
        </p>

        <div style={{ marginBottom: "5px" }}>
          <span>Cognitive changes & their effects on breathing?</span>
          <input
            style={{
              display: "inline-block",
              width: "470px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>Reduced anxiety about breathing & its effects?</span>
          <input
            style={{
              display: "inline-block",
              width: "470px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>New knowledge & beliefs about breathing?</span>
          <input
            style={{
              display: "inline-block",
              width: "495px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>Disengaged habit triggers, e.g., exercise?</span>
          <input
            style={{
              display: "inline-block",
              width: "507px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>
            Disengaged habit motivations-reinforcements, e.g., feeling in
            control?
          </span>
          <input
            style={{
              display: "inline-block",
              width: "390px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>Self-awareness of breathing mechanics and its effects?</span>
          <input
            style={{
              display: "inline-block",
              width: "430px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>Self-awareness of breathing triggered symptoms/deficits?</span>
          <input
            style={{
              display: "inline-block",
              width: "430px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>Self-awareness of deregulated chemistry (hypocapnia)?</span>
          <input
            style={{
              display: "inline-block",
              width: "430px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>
            Emotional desensitization to breathing mediated symptoms, e.g.,
            dizziness?
          </span>
          <input
            style={{
              display: "inline-block",
              width: "380px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>
            Emotional desensitization to breathing experience, e.g., fast
            breathing?
          </span>
          <input
            style={{
              display: "inline-block",
              width: "380px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>
            Extinction of dysfunctional breathing behaviors, e.g., taking big
            breaths?
          </span>
          <input
            style={{
              display: "inline-block",
              width: "370px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>
            New breathing behaviors learned, e.g., allowing the exhale?
          </span>
          <input
            style={{
              display: "inline-block",
              width: "410px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>CO</span>
          <span style={{ fontSize: "11px", verticalAlign: "sub" }}>2</span>
          <span>
            {" "}
            regulation in the presence of old triggers (in the field)?
          </span>
          <input
            style={{
              display: "inline-block",
              width: "445px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>New motivations/reinforcements discovered/implemented?</span>
          <input
            style={{
              display: "inline-block",
              width: "430px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>
            Reduced frequency/severity of breathing triggered symptoms/deficits?
          </span>
          <input
            style={{
              display: "inline-block",
              width: "380px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>
            Reduced frequency/severity of symptoms triggered by organic
            conditions, e.g., pain?
          </span>
          <input
            style={{
              display: "inline-block",
              width: "325px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>
            Reduced frequency/severity of symptoms trigger by mental conditions.
            e.g., panic?
          </span>
          <input
            style={{
              display: "inline-block",
              width: "325px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>Improved performance, e.g., testing?</span>
          <input
            style={{
              display: "inline-block",
              width: "530px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <span>
            Extinction of related troublesome behaviors, e.g., self-talk?
          </span>
          <input
            style={{
              display: "inline-block",
              width: "430px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
      </div>
    </div>
  );
};

export default OutcomeReport;
