import React from "react";

const AssessmentRecord = () => {
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);

  const handleScreenSizeChange = () => {
    if (window.innerWidth < 700) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  // useEffect to handle initial state and screen size changes
  React.useEffect(() => {
    handleScreenSizeChange(); // Set initial state based on screen size
    window.addEventListener("resize", handleScreenSizeChange); // Listen for screen size changes
    return () => {
      window.removeEventListener("resize", handleScreenSizeChange); // Clean up the event listener
    };
  }, []);

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
      }}
    >
      <h4
        style={{
          color: "#0077b6",
          textAlign: "center",
          marginBottom: "10px",
          marginTop: "30px",
        }}
      >
        ASSESSMENT RECORD
      </h4>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
          <label htmlFor="client-name">Client Name:</label>
          <input
            type="text"
            id="client-name"
            style={{
              height: "30px",
              width: "75%",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>

        <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
          <label htmlFor="sex">Sex:</label>
          <input
            type="text"
            id="sex"
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          />
          <label htmlFor="age">Age:</label>
          <input
            type="text"
            id="age"
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          />
          <label htmlFor="session-date">Session Date:</label>
          <input
            type="text"
            id="session-date"
            style={{
              width: "150px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          />
        </div>

        <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
          <label htmlFor="practitioner-name">Practitioner Name:</label>
          <input
            type="text"
            id="practitioner-name"
            style={{
              height: "30px",
              width: "65%",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          />
        </div>

        <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            style={{
              width: "85%",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
      >
        <label
          style={{
            marginRight: "10px",
          }}
        >
          Type of breathing habits?
        </label>
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "10px" }}
        >
          <input
            type="checkbox"
            id="type1"
            style={{
              marginRight: "5px",
            }}
          />
          <span
            className="type-label"
            style={{ fontSize: "14px", marginRight: "5px", color: "#0077b6" }}
          >
            Type 1 habits
          </span>
          <span>(breathing is regulated)</span>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "10px" }}
        >
          <input
            type="checkbox"
            id="type2"
            style={{
              marginRight: "5px",
            }}
          />
          <span
            className="type-label"
            style={{ fontSize: "14px", marginRight: "5px", color: "#0077b6" }}
          >
            Type 2 habits
          </span>
          <span>(breathing regulates)</span>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "10px" }}
        >
          <input
            type="checkbox"
            id="type3"
            style={{
              marginRight: "5px",
            }}
          />
          <span
            className="type-label"
            style={{ fontSize: "14px", marginRight: "5px", color: "#0077b6" }}
          >
            Type 3 habits
          </span>
          <span>(symptoms regulate)</span>
        </div>
      </div>

      <textarea
        style={{
          width: "100%",
          height: "70px",
          border: "2px solid #444",
          backgroundColor: "#f1f4ff",
        }}
      ></textarea>

      <div style={{ fontSize: "14px", marginBottom: "5px" }}>
        What are the principal complaints, symptoms, and/or deficits associated
        with the breathing habit(s)?
      </div>
      <textarea
        style={{
          width: "100%",
          height: "70px",
          border: "2px solid #444",
          backgroundColor: "#f1f4ff",
        }}
      ></textarea>

      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ fontSize: "14px", marginRight: 10 }}>
          Physiological measurements:
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          <label>
            <input type="checkbox" /> PetCO2
          </label>
          <label>
            <input type="checkbox" /> EMG
          </label>
          <label>
            <input type="checkbox" /> HRV
          </label>
          <label>
            <input type="checkbox" /> HR
          </label>
          <label>
            <input type="checkbox" /> SpO2
          </label>
          <label>
            <input type="checkbox" /> Br/min
          </label>
          <label>
            <input type="checkbox" /> BP
          </label>
          <label>
            <input type="checkbox" /> Other
          </label>
          <span style={{ color: "#0077b6", marginLeft: "20px" }}>
            What did you find?
          </span>
        </div>
      </div>
      <textarea
        style={{
          width: "100%",
          height: "70px",
          border: "2px solid #444",
          backgroundColor: "#f1f4ff",
        }}
      ></textarea>

      <div style={{ fontSize: "14px", marginBottom: "5px" }}>
        What emotions and/or thoughts are associated with the breathing
        habit(s), and/or the physiological changes they mediate?
      </div>
      <textarea
        style={{
          width: "100%",
          height: "70px",
          border: "2px solid #444",
          backgroundColor: "#f1f4ff",
        }}
      ></textarea>

      <div style={{ fontSize: "14px", marginBottom: "5px" }}>
        What are the specific triggers (e.g., places, times, people, tasks,
        situations, thoughts, emotions) of the breathing habit(s)?
      </div>
      <textarea
        style={{
          width: "100%",
          height: "70px",
          border: "2px solid #444",
          backgroundColor: "#f1f4ff",
        }}
      ></textarea>

      <div style={{ fontSize: "14px", marginBottom: "5px" }}>
        What are the motivations (e.g., fear) and reinforcements (e.g., control)
        perpetuating the breathing habit(s)?
      </div>
      <textarea
        style={{
          width: "100%",
          height: "70px",
          border: "2px solid #444",
          backgroundColor: "#f1f4ff",
        }}
      ></textarea>

      <div style={{ fontSize: "14px", marginBottom: "5px" }}>
        What kind of breathing challenges (e.g., making a phone call, guided
        overbreathing) did you implement and what were the outcomes?
      </div>
      <textarea
        style={{
          width: "100%",
          height: "70px",
          border: "2px solid #444",
          backgroundColor: "#f1f4ff",
        }}
      ></textarea>

      <div style={{ fontSize: "14px", marginBottom: "5px" }}>
        What are the history and origins (e.g., asthma as a child, pain issue,
        pregnancy) of the breathing habit(s)? Provide details.
      </div>
      <textarea
        style={{
          width: "100%",
          height: "70px",
          border: "2px solid #444",
          backgroundColor: "#f1f4ff",
        }}
      ></textarea>

      <div style={{ fontSize: "14px", marginBottom: "5px" }}>
        What are possible interacting organic (e.g., hypertension, neurological
        disorder) and psychological (e.g., panic) conditions? Provide details.
      </div>
      <textarea
        style={{
          width: "100%",
          height: "70px",
          border: "2px solid #444",
          backgroundColor: "#f1f4ff",
        }}
      ></textarea>

      <div style={{ fontSize: "14px", marginBottom: "5px" }}>
        Are there problematic client beliefs (bigger breaths mean more O₂) and
        symptom interpretations (e.g., I'm not getting enough O₂)?
      </div>
      <textarea
        style={{
          width: "100%",
          height: "70px",
          border: "2px solid #444",
          backgroundColor: "#f1f4ff",
        }}
      ></textarea>

      <div style={{ fontSize: "14px", marginBottom: "5px" }}>
        In formulating a breathing learning plan for your client, what would be
        the specific learning objectives (e.g., improving attention)?
      </div>
      <textarea
        style={{
          width: "100%",
          height: "70px",
          border: "2px solid #444",
          backgroundColor: "#f1f4ff",
        }}
      ></textarea>

      <div style={{ fontSize: "14px", marginBottom: "5px" }}>
        ADDITIONAL COMMENTS (e.g., measurement issues, such as metabolic
        acidosis and PetCO₂ interpretations)
      </div>
      <textarea
        style={{
          width: "100%",
          height: "70px",
          border: "2px solid #444",
          backgroundColor: "#f1f4ff",
        }}
      ></textarea>
    </div>
  );
};

export default AssessmentRecord;
