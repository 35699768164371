import React, { useState } from "react";

const ServiceEvaluationForm = () => {
  const [formData, setFormData] = useState({
    analystKnowledgeable: "",
    analystResponsive: "",
    analystSkilled: "",
    analystSupportive: "",
    analystListener: "",
    analystGuide: "",
    networkOrganized: "",
    networkResponsive: "",
    networkSupportive: "",
    objectivesAchieved: "",
    symptomsReduced: "",
    learnedManagement: "",
    didHomeActivities: "",
    learnedAboutSelf: "",
    learningValuable: "",
    bigEffect: "",
    likelyRecommend: "",
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Handle form submission logic here
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "20px",
        marginTop: "20px",
        border: "1px solid #ccc",
      }}
    >
      <h3
        style={{
          color: "#6a1b9a",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        SERVICE EVALUATION
      </h3>

      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "20px" }}>
          <p
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Please place a number, 1 - 7, following each statement, where
          </p>
          <div
            style={{
              marginBottom: "15px",
            }}
          >
            <p style={{ margin: "3px 0" }}>1 = poor</p>
            <p style={{ margin: "3px 0" }}>2 = needs improvement</p>
            <p style={{ margin: "3px 0" }}>3 = marginally acceptable,</p>
            <p style={{ margin: "3px 0" }}>4 = average</p>
            <p style={{ margin: "3px 0" }}>5 = good</p>
            <p style={{ margin: "3px 0" }}>6 = very good</p>
            <p style={{ margin: "3px 0" }}>7 = outstanding</p>
          </div>
        </div>

        <div style={{ marginBottom: "30px" }}>
          <p
            style={{
              fontWeight: "bold",
              fontStyle: "italic",
              marginBottom: "10px",
            }}
          >
            My Breathing Analyst was:
          </p>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                margin: "5px 0",
              }}
            >
              <label>knowledgeable:</label>
              <input
                type="text"
                name="analystKnowledgeable"
                value={formData.analystKnowledgeable}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                margin: "5px 0",
              }}
            >
              <label>responsive:</label>
              <input
                type="text"
                name="analystResponsive"
                value={formData.analystResponsive}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                margin: "5px 0",
              }}
            >
              <label>skilled:</label>
              <input
                type="text"
                name="analystSkilled"
                value={formData.analystSkilled}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                margin: "5px 0",
              }}
            >
              <label>supportive:</label>
              <input
                type="text"
                name="analystSupportive"
                value={formData.analystSupportive}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                margin: "5px 0",
              }}
            >
              <label>a good listener:</label>
              <input
                type="text"
                name="analystListener"
                value={formData.analystListener}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                margin: "5px 0",
              }}
            >
              <label>a helpful guide:</label>
              <input
                type="text"
                name="analystGuide"
                value={formData.analystGuide}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ marginBottom: "30px" }}>
          <p
            style={{
              fontWeight: "bold",
              fontStyle: "italic",
              marginBottom: "10px",
            }}
          >
            The Breathing Science Network was:
          </p>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <label>organized:</label>
              <input
                type="text"
                name="networkOrganized"
                value={formData.networkOrganized}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <label>responsive:</label>
              <input
                type="text"
                name="networkResponsive"
                value={formData.networkResponsive}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <label>supportive:</label>
              <input
                type="text"
                name="networkSupportive"
                value={formData.networkSupportive}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <p
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Please rate the following statements, 1 - 7, where 1 = "no" and 7 =
            varying degrees of "yes."
          </p>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <label>My learning objectives were achieved.</label>
              <input
                type="text"
                name="objectivesAchieved"
                value={formData.objectivesAchieved}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <label>
                My breathing-related symptoms were significantly reduced.
              </label>
              <input
                type="text"
                name="symptomsReduced"
                value={formData.symptomsReduced}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <label>
                I learned how to manage my breathing related symptoms.
              </label>
              <input
                type="text"
                name="learnedManagement"
                value={formData.learnedManagement}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <label>
                I did the home learning activities as discussed (rather than
                assigned).
              </label>
              <input
                type="text"
                name="didHomeActivities"
                value={formData.didHomeActivities}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <label>
                I learned a lot about myself and how my habits affect me.
              </label>
              <input
                type="text"
                name="learnedAboutSelf"
                value={formData.learnedAboutSelf}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <label>
                Learning about my breathing and how it affected me was a
                valuable experience.
              </label>
              <input
                type="text"
                name="learningValuable"
                value={formData.learningValuable}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <label>
                My learning experience has had a big effect on my life.
              </label>
              <input
                type="text"
                name="bigEffect"
                value={formData.bigEffect}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0",
              }}
            >
              <label>
                I am likely to recommend breathing services to a friend, family,
                or a colleague.
              </label>
              <input
                type="text"
                name="likelyRecommend"
                value={formData.likelyRecommend}
                onChange={handleChange}
                style={{
                  width: "40px",
                  marginLeft: "5px",
                  borderBottom: "1px solid #000",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  outline: "none",
                  height: "30px",
                  backgroundColor: "#f1f4ff",
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ marginBottom: "30px" }}>
          <p
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            We would appreciate any comments that you may wish to make. Thank
            you!
          </p>
          <textarea
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            style={{
              width: "100%",
              height: "120px",
              border: "1px solid #000",
              padding: "8px",
              boxSizing: "border-box",
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default ServiceEvaluationForm;
