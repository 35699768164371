import React, { Component, useEffect, useRef, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link, useParams, Router, NavLink } from 'react-router-dom';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import '../css/style.css'
// import '../css/style2.scss'
import '../css/responsive.css'
import $ from "jquery";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PageviewIcon from '@mui/icons-material/Pageview';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import StreamIcon from '@mui/icons-material/Stream';
import NoteIcon from '@material-ui/icons/Note';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ReportIcon from '@mui/icons-material/Report';
import VideocamIcon from '@mui/icons-material/Videocam';

import ImageIcon from '@material-ui/icons/Image';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import NotesIcon from '@material-ui/icons/Notes';

import LogoutIcon from '@mui/icons-material/Logout';
import logo from '../images/logo.png';
import burger from '../images/burger.png';
import connect from '../images/connect.png';
import gcircle from '../images/gcircle.png';
import crosss from '../images/crosss.png';
import Multilanguage from '../component/Multilanguage'
import { API_URL } from "../../config";
import axios from "axios";
import { Box, ClickAwayListener, Grow, MenuList, Paper, Popper, Typography,Button } from "@mui/material";



const Header = () => {
  const [isVerified,setIsVerified]=useState(true)
  const [isLoading,setIsLoading]=useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const toggleSuccessModal = () => setShowSuccessModal(!showSuccessModal);
  const checkEmailVerification = async () => {
    const accessToken = localStorage.getItem('accessToken');
    
    try {
      const res = await axios.get(`${API_URL}/check-verification`,{
        headers: {
          "x-access-token": accessToken,
        },
      });
  
      if (res.status === 200) {       
      setIsVerified(res.data.status) 
      }
  
    } catch (err) {
       
    }
  }

  useEffect(()=>{
    // checkEmailVerification()
  },[])

  
  const userType = localStorage.getItem('userType');

  const { t } = useTranslation();

  useEffect(() => {

    if (userType == 5 || userType == 6) {

    } else {
      changePickupStoreMenu();

      function changePickupStoreMenu() {

        var body = $('body'),
          mask = $('<div class="mask"></div>'),
          toggleSlideRight = document.querySelector(".toggle-slide-right"),
          slideMenuRight = document.querySelector(".slide-menu-right"),
          activeNav = '';
        ;
        $('body').append(mask);

        /* slide menu right */
        toggleSlideRight.addEventListener("click", function () {
          $('body').addClass("smr-open");
          $('.mask').fadeIn();
          activeNav = "smr-open";
        });

        /* hide active menu if close menu button is clicked */
        $(document).on('click', ".close-menu", function (el, i) {
          $('body').removeClass(activeNav);
          activeNav = "";
          $('.mask').fadeOut();
        });

      }
    }



  }, [])

  const tabArray = [
    {
      links: "/dashboard", tabDisplay: t('Create-Data-Report'), "tabimg": <BorderColorIcon />
    },
    {
      links: "/view/data/report", tabDisplay: t('View-&-Edit-Data-Report'), "tabimg": <PageviewIcon />
    },
    {
      links: "/view/pdf/report", tabDisplay: t('View-PDF-Report'), "tabimg": <PictureAsPdfIcon />
    },
    {
      links: "/view/live", tabDisplay: t('View-Live-Session-Info'), "tabimg": <StreamIcon />
    },
    {
      links: "/view/manageform", tabDisplay: t('View/Manage-Forms'), "tabimg": <ManageAccountsIcon />
    },
    {
      links: "/viewcreate", tabDisplay: (userType == 3) ? "Edit Profile" : t('View-Create-Edit-Profile'), "tabimg": <PersonAddIcon />
    },
    {
      links: "/case/report/assembly", tabDisplay: t('Session-Report-Assembly'), "tabimg": <ReportIcon />
    },
    {
      links: "/recording", tabDisplay: t('Recording-by-Distributors'), "tabimg": <VideocamIcon />
    },
    {
      links: "/subscription/management", tabDisplay: t('Subscription-Management'), "tabimg": <TouchAppIcon />
    },


  ];

  const usertypeseventabArray = [
    {
      links: "/dashboard", tabDisplay: t('Create-Data-Report'), "tabimg": <BorderColorIcon />
    },
    {
      links: "/view/data/report", tabDisplay: t('View-&-Edit-Data-Report'), "tabimg": <PageviewIcon />
    },
    {
      links: "/view/pdf/report", tabDisplay: t('View-PDF-Report'), "tabimg": <PictureAsPdfIcon />
    },
    {
      links: "/view/live", tabDisplay: t('View-Live-Session-Info'), "tabimg": <StreamIcon />
    },
    {
      links: "/view/manageform", tabDisplay: t('View/Manage-Forms'), "tabimg": <ManageAccountsIcon />
    },

    {
      links: "/viewcreate", tabDisplay: (userType == 3) ? "Edit Profile" : t('View-Create-Edit-Profile'), "tabimg": <PersonAddIcon />
    },

    {
      links: "/case/report/assembly", tabDisplay: t('Session-Report-Assembly'), "tabimg": <ReportIcon />
    },
    {
      links: "/subscription/management", tabDisplay: t('Subscription-Management'), "tabimg": <TouchAppIcon />
    },
    // {
    //   links: "/subscribe/user", tabDisplay: t('Subscribed Users'), "tabimg": sidebarmenu8
    // },

    // {
    //     links: "/", tabDisplay: t('Group-Subscription-Management'), "tabimg": <PictureAsPdfIcon />
    //   },

  ];

  const usertype3tabArray = [
    // {
    //   links: "/dashboard", tabDisplay: t('Create-Data-Report'), "tabimg": <BorderColorIcon />
    // },
    // {
    //   links: "/view/data/report", tabDisplay: t('View-&-Edit-Data-Report'), "tabimg": <PageviewIcon />
    // },
    {
      links: "/view/pdf/report", tabDisplay: t('View-PDF-Report'), "tabimg": <PictureAsPdfIcon />
    },
    {
      links: "/view/live", tabDisplay: t('View-Live-Session-Info'), "tabimg": <StreamIcon />
    },
    {
      links: "/view/manageform", tabDisplay: t('View/Manage-Forms'), "tabimg": <ManageAccountsIcon />
    },

    {
      links: "/viewcreate", tabDisplay: (userType == 3) ? "Edit Profile" : t('View-Create-Edit-Profile'), "tabimg": <PersonAddIcon />
    },

    // {
    //   links: "/subscribe/user", tabDisplay: t('Subscribed Users'), "tabimg": sidebarmenu8
    // },

    // {
    //     links: "/", tabDisplay: t('Group-Subscription-Management'), "tabimg": <PictureAsPdfIcon />
    //   },

  ];


  const usertype2tabArray = [
    {
      links: "/dashboard", tabDisplay: t('Create-Data-Report'), "tabimg": <BorderColorIcon />
    },
    {
      links: "/view/data/report", tabDisplay: t('View-&-Edit-Data-Report'), "tabimg": <PageviewIcon />
    },
    {
      links: "/view/pdf/report", tabDisplay: t('View-PDF-Report'), "tabimg": <PictureAsPdfIcon />
    },
    {
      links: "/view/live", tabDisplay: t('View-Live-Session-Info'), "tabimg": <StreamIcon />
    },
    {
      links: "/view/manageform", tabDisplay: t('View/Manage-Forms'), "tabimg": <ManageAccountsIcon />
    },
    {
      links: "/viewcreate", tabDisplay: (userType == 3) ? "Edit Profile" : t('View-Create-Edit-Profile'), "tabimg": <PersonAddIcon />
    },
    {
      links: "/case/report/assembly", tabDisplay: t('Session-Report-Assembly'), "tabimg": <ReportIcon />
    },
    {
      links: "/recording", tabDisplay: t('Recording-by-Distributors'), "tabimg": <VideocamIcon />
    },
    // {
    //   links: "/subscription/management", tabDisplay: t('Subscription-Management'), "tabimg": <TouchAppIcon />
    // },


  ];



  const auth = localStorage.getItem('user_id');

  const logout = () => {
    localStorage.clear();
    // alert("You Logout successful")
  }
  const anchorRef = React.useRef(null);
  const [open_notification, setOpen_notification] = useState(false);
  const handleToggleNotifcation = () => {
    setOpen_notification((prevOpen) => !prevOpen);
  };
  const handleCloseNotification = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen_notification(false);
  };
  function handleListKeyDown_1(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen_notification(false);
    } else if (event.key === "Escape") {
      setOpen_notification(false);
    }
  }
  const sendVerificationEmail = async () => {
    setIsLoading(true)
    const accessToken = localStorage.getItem("accessToken");
  
    try {
      const res = await axios.post(
        `${API_URL}/send-verification-email`,
        {},
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      );
  
      if (res) {
        setIsLoading(false)
        setShowSuccessModal(true);
        handleCloseNotification();
      }
    } catch (err) {
      setIsLoading(false)
      console.error("Error sending verification email:", err);
    }
  };
  
  return (
    <>
    {!isVerified && <Box
          width={"100%"}
          sx={{
            background:"yellow",
            "& button":{
              background:"#800080",
             boxShadow:"none",
              border:"none",
              color:"#fff",
              px:"1rem",
              textTransform:"capitalize",
              "&:hover":{
                background:"#800080",
                
              }
            }
          }}
        >
          <Typography
            textAlign={"center"}
            color={"#000"}
            p={"10px"}
            sx={{fontWeight:"600"}}
          >
           
            Your Email is not verified yet.
             {" "}
      
                <Button
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={
                    open_notification ? "composition-menu" : undefined
                  }
                  aria-expanded={open_notification ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleNotifcation}
               
                >
                  Verify Email
                </Button>
      
            <Popper
              open={open_notification}
              anchorEl={anchorRef.current}
              role={undefined}
              sx={{
                zIndex: "1200",
                width: {
                  sm: "360px !important",
                },
              }}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseNotification}>
                      <MenuList
                        autoFocusItem={open_notification}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown_1}
                      >
                        <Box
                            sx={{
                              p: "0.5rem 1rem",
                            }}
                          >
                            <Typography>
                              Are you sure want to request for Email verification ? 
                            </Typography>

                            <Box
                              sx={{
                                pt: "1rem",
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                "& button": {
                                  color: "#fff !important",
                                  lineHeight: "19.92px !important",
                                  fontSize: "14px !important",
                                  // height: "45px",
                                  width: "30%",
                                  padding: "0.5rem !important",
                                  fontWeight: "400 !important",
                                  fontFamily: "Exo 2 !important",
                                  transition: "0.1s all !important",
                                  borderRadius: "5px !important",
                                },
                              }}
                            >
                              <Button
                                sx={{
                                  background: "green !important",
                                  "&:hover": {
                                    background: "green !important",
                                  },
                                }}
                                onClick={sendVerificationEmail}
                               >
                                Yes   {
                                        isLoading &&
                                        <div id="loader" style={{top:"0"}}></div>
                                    }
                              </Button>
                              <Button
                                sx={{
                                  background: "#000 !important",
                                  "&:hover": {
                                    background: "#000 !important",
                                  },
                                }}
                                onClick={handleCloseNotification}
                              >
                                No
                              </Button>
                            </Box>
                          </Box>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
        
          </Typography>
        </Box>}
    <div className="border-b" style={{
      top:!isVerified&&"58px"
    }}>
 
      <div className="container-fluid">
        <div className="header-box">
          <div className="header-c1">
            <div className="header-child1">
              {/* {userType} */}
              <h3>{userType == 1 ? "Admin" : userType == 2 ? "Trainer" : userType == 3 ? "Client": userType == 4?"Group" : userType == 5 ? "Go Personal" : userType == 6 ? "Go Basic" : userType == 7 ? "Go Professional" : ""} {t("Database Dashboard")}</h3>
            </div>
          </div>

          <div className="header-c2">

            {
              (userType == 5 || userType == 6) && <div>
                {
                  auth ? <Link to="/login" className="logout" onClick={logout}><div className="sidebar-icon"><LogoutIcon /> <p>Logout</p></div></Link> : null
                }
              </div>
            }

            <div className="multi-langueage-wrp">

            </div>

            {
              (userType == 5 || userType == 6) ? "" : <div className="burger-area">

                <a href="#" className="burgers toggle-slide-right"> <img src={burger} /></a>

              </div>
            }


          </div>

        </div>

        <div className="menu slide-menu-right menu-list-wrp">
          <button class="close-menu"><img src={crosss} className="img-close" /></button>
          <ul className="sidebar-list">
            {

              userType == 7 ? usertypeseventabArray.map(function (v, i) {
                return (
                  <li><NavLink to={v.links} className="close-menu" ><div className="sidebar-icon-img">{v.tabimg}</div><p>{v.tabDisplay}</p></NavLink></li>
                )
              }

              ) : userType == 3 ? usertype3tabArray.map(function (v, i) {
                return (
                  <li><NavLink to={v.links} className="close-menu" ><div className="sidebar-icon-img">{v.tabimg}</div><p>{v.tabDisplay}</p></NavLink></li>
                )
              }

              ) : userType == 2 ? usertype2tabArray.map(function (v, i) {
                return (
                  <li><NavLink to={v.links} className="close-menu" ><div className="sidebar-icon-img">{v.tabimg}</div><p>{v.tabDisplay}</p></NavLink></li>
                )
              }

              ) :
                tabArray.map(function (v, i) {
                  return (
                    <li><NavLink to={v.links} className="close-menu" ><div className="sidebar-icon-img">{v.tabimg}</div><p>{v.tabDisplay}</p></NavLink></li>
                  )
                }

                )

            }
            <li>{
              auth ? <Link to="/login" onClick={logout} className="tabs close-menu"><div className="sidebar-icon-img"><LogoutIcon /></div> <p>Logout</p></Link> : null
            }</li>


          </ul>
        </div>

      </div>
    </div>
    <Modal isOpen={showSuccessModal} toggle={toggleSuccessModal} centered>
    <ModalHeader toggle={toggleSuccessModal}>
        <span className="ml-1 roititle">Email Verification</span>
    </ModalHeader>
    <ModalBody>
        <div className="modal-p">
            <p style={{ padding: "1rem" }}>
                A verification link has been sent to your email. Please check your inbox and follow the instructions to complete the verification process.
            </p>
        </div>
    </ModalBody>
</Modal>

    </>
  );
}

export default Header;

