import React from "react";

const LearningSessionRecord = () => {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "20px",
        border: "1px solid #ccc",
        marginTop: "20px",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          color: "#0066cc",
          fontSize: "24px",
          marginBottom: "20px",
        }}
      >
        LEARNING SESSION RECORD
      </h1>

      <div style={{ marginBottom: "15px", display: "flex", flexWrap: "wrap" }}>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <span>Client Name: </span>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "320px",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
              height: "30px",
            }}
          ></input>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginLeft: "20px",
          }}
        >
          <span>Session #</span>
          <input
            style={{
              width: "50px",
              borderBottom: "1px solid black",
              display: "inline-block",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
              height: "30px",
            }}
          ></input>
          <span
            style={{
              marginLeft: "10px",
            }}
          >
            Date:{" "}
          </span>
          <input
            style={{
              width: "200px",
              borderBottom: "1px solid black",
              display: "inline-block",
              backgroundColor: "#f1f4ff",
              borderBottom: "2px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
              height: "30px",
            }}
          ></input>
        </div>
      </div>

      <div
        style={{
          marginBottom: "15px",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <span>Practitioner Name: </span>
        <span
          style={{
            borderBottom: "1px solid black",
            display: "inline-block",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
            width: "325px",
            height: "30px",
          }}
        ></span>
        <span style={{ marginLeft: "10px" }}>Email:</span>
        <span
          style={{
            borderBottom: "1px solid black",
            display: "inline-block",
            width: "350px",
            backgroundColor: "#f1f4ff",
            borderBottom: "2px solid #444",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            outline: "none",
            marginLeft: "5px",
            height: "30px",
          }}
        ></span>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <div
          style={{ color: "#0066cc", fontWeight: "bold", marginBottom: "5px" }}
        >
          Session description and outcomes:
        </div>
        <textarea
          style={{
            border: "1px solid black",
            height: "90px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            outline: "none",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <div
          style={{ color: "#0066cc", fontWeight: "bold", marginBottom: "5px" }}
        >
          Session learning strategies:
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="autonomic"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="autonomic">Autonomic learning (HRV feedback) </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "650px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="awareness"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="awareness">Awareness training (e.g., exhale) </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "650px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="breaking" style={{ marginRight: "5px" }} />
          <label htmlFor="breaking">Breaking response chains </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="capnography"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="capnography">Capnography feedback training </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "650px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="counterconditioning"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="counterconditioning">Counterconditioning </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "750px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="creating" style={{ marginRight: "5px" }} />
          <label htmlFor="creating">Creating/finding new reinforcements </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "650px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="desensitization1"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="desensitization1">
            Desensitization to breathing mechanics{" "}
          </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "620px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="desensitization2"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="desensitization2">
            Desensitization to effects of hypocapnia{" "}
          </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "620px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="diary" style={{ marginRight: "5px" }} />
          <label htmlFor="diary">Diary record keeping </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "750px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="envivo" style={{ marginRight: "5px" }} />
          <label htmlFor="envivo">En vivo learning strategies </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="testing" style={{ marginRight: "5px" }} />
          <label htmlFor="testing">En vivo testing </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="exploring1"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="exploring1">Exploring altered states </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="exploring2"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="exploring2">Exploring new behaviors </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="extinction1"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="extinction1">
            Extinction of classically conditioned responses{" "}
          </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "540px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="extinction2"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="extinction2">
            Extinction of reinforced behaviors{" "}
          </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "680px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="finding" style={{ marginRight: "5px" }} />
          <label htmlFor="finding">Finding new reinforcements </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="generalization"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="generalization">
            Generalization training (real life){" "}
          </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "600px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="guided" style={{ marginRight: "5px" }} />
          <label htmlFor="guided">Guided overbreathing </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="homework" style={{ marginRight: "5px" }} />
          <label htmlFor="homework">Homework Review </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="identifying1"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="identifying1">Identifying existing behaviors </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="identifying2"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="identifying2">
            Identifying existing reinforcements{" "}
          </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "600px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="identifying3"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="identifying3">Identifying triggers </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="learning" style={{ marginRight: "5px" }} />
          <label htmlFor="learning">
            Learning new breathing behaviors (shaping){" "}
          </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "600px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="linking" style={{ marginRight: "5px" }} />
          <label htmlFor="linking">Linking breathing with symptoms </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "600px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="negative" style={{ marginRight: "5px" }} />
          <label htmlFor="negative">Negative practice </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="perceptual"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="perceptual">
            Perceptual learning (e.g., reflex identification){" "}
          </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "600px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="preference"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="preference">Preference development </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="recorded" style={{ marginRight: "5px" }} />
          <label htmlFor="recorded">Recorded data review </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="reinforcing"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="reinforcing">
            Reinforcing alternative behaviors{" "}
          </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "600px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="secondary"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="secondary">
            Secondary gain analysis (e.g., headache){" "}
          </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "600px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="selfeducation"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="selfeducation">Self-education and beliefs </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="selfobservation"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="selfobservation">Self-observation lessons </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input
            type="checkbox"
            id="selfregulating"
            style={{ marginRight: "5px" }}
          />
          <label htmlFor="selfregulating">Self-regulating interventions </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="somatic" style={{ marginRight: "5px" }} />
          <label htmlFor="somatic">Somatic learning (EMG feedback) </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "650px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="symptoms" style={{ marginRight: "5px" }} />
          <label htmlFor="symptoms">
            Symptoms as triggers for good habits{" "}
          </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "650px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="symptom" style={{ marginRight: "5px" }} />
          <label htmlFor="symptom">Symptom interpretations </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="thinking" style={{ marginRight: "5px" }} />
          <label htmlFor="thinking">Thinking and imagining </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
        <div style={{ marginBottom: "3px" }}>
          <input type="checkbox" id="trigger" style={{ marginRight: "5px" }} />
          <label htmlFor="trigger">Trigger challenge training </label>
          <input
            style={{
              borderBottom: "1px solid black",
              display: "inline-block",
              width: "700px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          ></input>
        </div>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <div
          style={{ color: "#0066cc", fontWeight: "bold", marginBottom: "5px" }}
        >
          Learning objectives for next session:
        </div>
        <textarea
          style={{
            border: "1px solid black",
            height: "80px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            outline: "none",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <div
          style={{ color: "#0066cc", fontWeight: "bold", marginBottom: "5px" }}
        >
          Homework assignment(s) for between now and the next session:
        </div>
        <textarea
          style={{
            border: "1px solid black",
            height: "80px",
            width: "100%",
            backgroundColor: "#f1f4ff",
            outline: "none",
          }}
        ></textarea>
      </div>
    </div>
  );
};

export default LearningSessionRecord;
