import React, { useState } from "react";

const ReferralReportForm = () => {
  const [formData, setFormData] = useState({
    clientName: "",
    clientEmail: "",
    clientTel: "",
    practitionerName: "",
    practitionerEmail: "",
    practitionerTel: "",
    referralReason: "",
    breathingChallengesOutcome: "",
    breathingIssues: {
      abortedExhale: { checked: false, comment: "" },
      airHunger: { checked: false, comment: "" },
      anxietyAboutBreathing: { checked: false, comment: "" },
      assistingTheInhale: { checked: false, comment: "" },
      breathHolding: { checked: false, comment: "" },
      chestBreathing: { checked: false, comment: "" },
      effortfulBreathing: { checked: false, comment: "" },
      faultyBeliefs: { checked: false, comment: "" },
      forcedExhalation: { checked: false, comment: "" },
      gasping: { checked: false, comment: "" },
      intentionalManipulations: { checked: false, comment: "" },
      muscleActivity: { checked: false, comment: "" },
      mouthBreathing: { checked: false, comment: "" },
      noHesitation: { checked: false, comment: "" },
      overbreathingPetCo2: { checked: false, comment: "" },
      rapidBreathing: { checked: false, comment: "" },
      reachingForAir: { checked: false, comment: "" },
      reverseBreathing: { checked: false, comment: "" },
      shallowBreathing: { checked: false, comment: "" },
      shortnessOfBreath: { checked: false, comment: "" },
      symptomsAssociated: { checked: false, comment: "" },
      takesDeepBreaths: { checked: false, comment: "" },
      worriedAboutBreathing: { checked: false, comment: "" },
      other: { checked: false, comment: "" },
    },
    petCo2Changes: {
      breathingMechanics: { checked: false, comment: "" },
      intentionalManipulation: { checked: false, comment: "" },
      conversationalContent: { checked: false, comment: "" },
      emotions: { checked: false, comment: "" },
      physicalDiscomfort: { checked: false, comment: "" },
      posturalChanges: { checked: false, comment: "" },
      pain: { checked: false, comment: "" },
      other: { checked: false, comment: "" },
    },
    petCo2Measurements: {
      overbreathingPresent: "",
      initialBaselineValues: "",
      symptomsReported: "",
      riseToNormal: "",
      assistToRaise: "",
      successful: "",
      trappedInOverbreathing: "",
    },
    co2ScreenShots: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (section, item) => (e) => {
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [item]: {
          ...formData[section][item],
          checked: e.target.checked,
        },
      },
    });
  };

  const handleCommentChange = (section, item) => (e) => {
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [item]: {
          ...formData[section][item],
          comment: e.target.value,
        },
      },
    });
  };

  const handleRadioChange = (name, value) => {
    setFormData({
      ...formData,
      petCo2Measurements: {
        ...formData.petCo2Measurements,
        [name]: value,
      },
    });
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "20px",
        marginTop: "20px",
        border: "1px solid #ccc",
      }}
    >
      <h1
        style={{
          color: "#0078d4",
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        REFERRAL REPORT
      </h1>

      <p
        style={{ textAlign: "center", fontSize: "14px", marginBottom: "10px" }}
      >
        Please save this file in your client's name, e.g., smithreferral.pdf.
        Then upload it to your client's folder on the BSN portal.
      </p>

      <p
        style={{ textAlign: "center", fontSize: "14px", marginBottom: "20px" }}
      >
        Go to{" "}
        <a
          href="https://breathingsciencenetwork.com/"
          style={{ color: "#0078d4" }}
        >
          https://breathingsciencenetwork.com/
        </a>{" "}
        and click on DASHBOARD LOGIN.
      </p>

      <div style={{ marginBottom: "20px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "10px",
          }}
        >
          <label style={{ fontWeight: "normal" }}>Client Name:</label>
          <input
            type="text"
            name="clientName"
            value={formData.clientName}
            onChange={handleInputChange}
            style={{
              flex: "1",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid #000",
              outline: "none",
              padding: "5px 0",
              backgroundColor: "#f1f4ff",
            }}
          />
          <label style={{ marginLeft: "10px" }}>Email:</label>
          <input
            type="text"
            name="clientEmail"
            value={formData.clientEmail}
            onChange={handleInputChange}
            style={{
              flex: "1",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid #000",
              outline: "none",
              padding: "5px 0",
              backgroundColor: "#f1f4ff",
            }}
          />
          <label style={{ marginLeft: "10px" }}>Tel:</label>
          <input
            type="text"
            name="clientTel"
            value={formData.clientTel}
            onChange={handleInputChange}
            style={{
              flex: "1",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid #000",
              outline: "none",
              padding: "5px 0",
              backgroundColor: "#f1f4ff",
            }}
          />
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ fontWeight: "normal" }}>Practitioner Name:</label>
          <input
            type="text"
            name="practitionerName"
            value={formData.practitionerName}
            onChange={handleInputChange}
            style={{
              flex: "1",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid #000",
              outline: "none",
              padding: "5px 0",
              backgroundColor: "#f1f4ff",
            }}
          />
          <label style={{ fontWeight: "normal" }}>Email:</label>
          <input
            type="text"
            name="email"
            value={formData.practitionerName}
            onChange={handleInputChange}
            style={{
              flex: "1",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid #000",
              outline: "none",
              padding: "5px 0",
              backgroundColor: "#f1f4ff",
            }}
          />
          <label style={{ fontWeight: "normal" }}>Tel:</label>
          <input
            type="text"
            name="tel"
            value={formData.practitionerName}
            onChange={handleInputChange}
            style={{
              flex: "1",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid #000",
              outline: "none",
              padding: "5px 0",
              backgroundColor: "#f1f4ff",
            }}
          />
        </div>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <label
          style={{
            color: "#0078d4",
            fontWeight: "bold",
            display: "block",
            marginBottom: "5px",
          }}
        >
          Why did you decide to make a referral?{" "}
          <span style={{ fontWeight: "normal", color: "black" }}>
            Example: "Because of what I saw on the "Your Breathing Habits" form.
          </span>
        </label>
        <textarea
          name="referralReason"
          value={formData.referralReason}
          onChange={handleInputChange}
          style={{
            width: "100%",
            height: "100px",
            border: "1px solid #000",
            padding: "5px",
            boxSizing: "border-box",
            backgroundColor: "#f1f4ff",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <label
          style={{
            color: "#0078d4",
            fontWeight: "bold",
            display: "block",
            marginBottom: "5px",
          }}
        >
          Did you notice any of the following during your interview?{" "}
          <span style={{ fontWeight: "normal", color: "black" }}>
            If you have comments, please write them in the space provided for
            each item.
          </span>
        </label>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div
            style={{
              width: "50%",
              paddingRight: "10px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="abortedExhale"
                checked={formData.breathingIssues.abortedExhale.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="abortedExhale" style={{ fontSize: "14px" }}>
                Aborted exhale (not exhaling completely)
              </label>
              <input
                type="text"
                value={formData.breathingIssues.abortedExhale.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{
                  width: "300px",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="abortedExhale"
                checked={formData.breathingIssues.abortedExhale.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="abortedExhale" style={{ fontSize: "14px" }}>
                Air hunger
              </label>
              <input
                type="text"
                value={formData.breathingIssues.abortedExhale.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{
                  width: "300px",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="abortedExhale"
                checked={formData.breathingIssues.abortedExhale.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="abortedExhale" style={{ fontSize: "14px" }}>
                Anxity about breathing
              </label>
              <input
                type="text"
                value={formData.breathingIssues.abortedExhale.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{
                  width: "300px",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="abortedExhale"
                checked={formData.breathingIssues.abortedExhale.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="abortedExhale" style={{ fontSize: "14px" }}>
                Breath holding
              </label>
              <input
                type="text"
                value={formData.breathingIssues.abortedExhale.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{
                  width: "300px",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="abortedExhale"
                checked={formData.breathingIssues.abortedExhale.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="abortedExhale" style={{ fontSize: "14px" }}>
                Chest breathing
              </label>
              <input
                type="text"
                value={formData.breathingIssues.abortedExhale.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{
                  width: "300px",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="abortedExhale"
                checked={formData.breathingIssues.abortedExhale.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="abortedExhale" style={{ fontSize: "14px" }}>
                Effortful breathing
              </label>
              <input
                type="text"
                value={formData.breathingIssues.abortedExhale.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{
                  width: "300px",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="abortedExhale"
                checked={formData.breathingIssues.abortedExhale.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="abortedExhale" style={{ fontSize: "14px" }}>
                Faulty beliefs about breathing
              </label>
              <input
                type="text"
                value={formData.breathingIssues.abortedExhale.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{
                  width: "300px",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="abortedExhale"
                checked={formData.breathingIssues.abortedExhale.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="abortedExhale" style={{ fontSize: "14px" }}>
                Forced exhalation
              </label>
              <input
                type="text"
                value={formData.breathingIssues.abortedExhale.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{
                  width: "300px",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="abortedExhale"
                checked={formData.breathingIssues.abortedExhale.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="abortedExhale" style={{ fontSize: "14px" }}>
                Gasping, sighing
              </label>
              <input
                type="text"
                value={formData.breathingIssues.abortedExhale.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{
                  width: "300px",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="abortedExhale"
                checked={formData.breathingIssues.abortedExhale.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="abortedExhale" style={{ fontSize: "14px" }}>
                Intentional breathing manipulation
              </label>
              <input
                type="text"
                value={formData.breathingIssues.abortedExhale.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{
                  width: "300px",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="abortedExhale"
                checked={formData.breathingIssues.abortedExhale.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="abortedExhale" style={{ fontSize: "14px" }}>
                Muscle activity tied to breathing
              </label>
              <input
                type="text"
                value={formData.breathingIssues.abortedExhale.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "abortedExhale"
                )}
                style={{
                  width: "300px",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>

            {/* More breathing issues checkboxes for left column */}
            {/* ... */}
          </div>

          <div
            style={{
              width: "50%",
              paddingLeft: "10px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="mouthBreathing"
                checked={formData.breathingIssues.mouthBreathing.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="mouthBreathing" style={{ width: "200px" }}>
                Mouth breathing
              </label>
              <input
                type="text"
                value={formData.breathingIssues.mouthBreathing.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="mouthBreathing"
                checked={formData.breathingIssues.mouthBreathing.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="mouthBreathing" style={{ width: "200px" }}>
                No hesitation between exhale and inhale
              </label>
              <input
                type="text"
                value={formData.breathingIssues.mouthBreathing.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="mouthBreathing"
                checked={formData.breathingIssues.mouthBreathing.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="mouthBreathing" style={{ width: "200px" }}>
                overbreathing (PetCO<sub>2</sub> , range?)
              </label>
              <input
                type="text"
                value={formData.breathingIssues.mouthBreathing.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="mouthBreathing"
                checked={formData.breathingIssues.mouthBreathing.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="mouthBreathing" style={{ width: "200px" }}>
                Rapid breathing
              </label>
              <input
                type="text"
                value={formData.breathingIssues.mouthBreathing.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="mouthBreathing"
                checked={formData.breathingIssues.mouthBreathing.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="mouthBreathing" style={{ width: "200px" }}>
                Reaching of air
              </label>
              <input
                type="text"
                value={formData.breathingIssues.mouthBreathing.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="mouthBreathing"
                checked={formData.breathingIssues.mouthBreathing.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="mouthBreathing" style={{ width: "200px" }}>
                Reverse breathing
              </label>
              <input
                type="text"
                value={formData.breathingIssues.mouthBreathing.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="mouthBreathing"
                checked={formData.breathingIssues.mouthBreathing.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="mouthBreathing" style={{ width: "200px" }}>
                Shallow breathing
              </label>
              <input
                type="text"
                value={formData.breathingIssues.mouthBreathing.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "mouthBreathing"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="shortnessOfBreath"
                checked={formData.breathingIssues.shortnessOfBreath.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "shortnessOfBreath"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="shortnessOfBreath" style={{ width: "200px" }}>
                Shortness of breath
              </label>
              <input
                type="text"
                value={formData.breathingIssues.shortnessOfBreath.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "shortnessOfBreath"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="symptomsAssociated"
                checked={formData.breathingIssues.symptomsAssociated.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "symptomsAssociated"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="symptomsAssociated" style={{ width: "200px" }}>
                Symptoms associated with breathing
              </label>
              <input
                type="text"
                value={formData.breathingIssues.symptomsAssociated.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "symptomsAssociated"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="takesDeepBreaths"
                checked={formData.breathingIssues.takesDeepBreaths.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "takesDeepBreaths"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="takesDeepBreaths" style={{ width: "200px" }}>
                Takes deep breaths
              </label>
              <input
                type="text"
                value={formData.breathingIssues.takesDeepBreaths.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "takesDeepBreaths"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="worriedAboutBreathing"
                checked={formData.breathingIssues.worriedAboutBreathing.checked}
                onChange={handleCheckboxChange(
                  "breathingIssues",
                  "worriedAboutBreathing"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="worriedAboutBreathing" style={{ width: "200px" }}>
                Worried about breathing
              </label>
              <input
                type="text"
                value={formData.breathingIssues.worriedAboutBreathing.comment}
                onChange={handleCommentChange(
                  "breathingIssues",
                  "worriedAboutBreathing"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="otherBreathingIssue"
                checked={formData.breathingIssues.other.checked}
                onChange={handleCheckboxChange("breathingIssues", "other")}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="otherBreathingIssue" style={{ width: "200px" }}>
                OTHER
              </label>
              <input
                type="text"
                value={formData.breathingIssues.other.comment}
                onChange={handleCommentChange("breathingIssues", "other")}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <label
          style={{
            color: "#0078d4",
            fontWeight: "bold",
            display: "block",
            marginBottom: "5px",
          }}
        >
          Did you notice any changes in PetCO<sub>2</sub> levels during the
          interview?{" "}
          <span style={{ fontWeight: "normal", color: "black" }}>
            If so, did any of the following seem to trigger the change?
          </span>
        </label>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div
            style={{
              width: "50%",
              paddingRight: "10px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="breathingMechanics"
                checked={formData.petCo2Changes.breathingMechanics.checked}
                onChange={handleCheckboxChange(
                  "petCo2Changes",
                  "breathingMechanics"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="breathingMechanics" style={{ width: "200px" }}>
                Change in breathing mechanics
              </label>
              <input
                type="text"
                value={formData.petCo2Changes.breathingMechanics.comment}
                onChange={handleCommentChange(
                  "petCo2Changes",
                  "breathingMechanics"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="intentionalManipulation"
                checked={formData.petCo2Changes.intentionalManipulation.checked}
                onChange={handleCheckboxChange(
                  "petCo2Changes",
                  "intentionalManipulation"
                )}
                style={{ marginRight: "5px" }}
              />
              <label
                htmlFor="intentionalManipulation"
                style={{ width: "200px" }}
              >
                Intentional manipulation of breathing
              </label>
              <input
                type="text"
                value={formData.petCo2Changes.intentionalManipulation.comment}
                onChange={handleCommentChange(
                  "petCo2Changes",
                  "intentionalManipulation"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="conversationalContent"
                checked={formData.petCo2Changes.conversationalContent.checked}
                onChange={handleCheckboxChange(
                  "petCo2Changes",
                  "conversationalContent"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="conversationalContent" style={{ width: "200px" }}>
                Conversational content
              </label>
              <input
                type="text"
                value={formData.petCo2Changes.conversationalContent.comment}
                onChange={handleCommentChange(
                  "petCo2Changes",
                  "conversationalContent"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="emotions"
                checked={formData.petCo2Changes.emotions.checked}
                onChange={handleCheckboxChange("petCo2Changes", "emotions")}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="emotions" style={{ width: "200px" }}>
                Emotions
              </label>
              <input
                type="text"
                value={formData.petCo2Changes.emotions.comment}
                onChange={handleCommentChange("petCo2Changes", "emotions")}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
          </div>

          <div
            style={{
              width: "50%",
              paddingLeft: "10px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="physicalDiscomfort"
                checked={formData.petCo2Changes.physicalDiscomfort.checked}
                onChange={handleCheckboxChange(
                  "petCo2Changes",
                  "physicalDiscomfort"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="physicalDiscomfort" style={{ width: "200px" }}>
                Physical discomfort
              </label>
              <input
                type="text"
                value={formData.petCo2Changes.physicalDiscomfort.comment}
                onChange={handleCommentChange(
                  "petCo2Changes",
                  "physicalDiscomfort"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="posturalChanges"
                checked={formData.petCo2Changes.posturalChanges.checked}
                onChange={handleCheckboxChange(
                  "petCo2Changes",
                  "posturalChanges"
                )}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="posturalChanges" style={{ width: "200px" }}>
                Postural (kinesthetic) changes
              </label>
              <input
                type="text"
                value={formData.petCo2Changes.posturalChanges.comment}
                onChange={handleCommentChange(
                  "petCo2Changes",
                  "posturalChanges"
                )}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="pain"
                checked={formData.petCo2Changes.pain.checked}
                onChange={handleCheckboxChange("petCo2Changes", "pain")}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="pain" style={{ width: "200px" }}>
                Pain
              </label>
              <input
                type="text"
                value={formData.petCo2Changes.pain.comment}
                onChange={handleCommentChange("petCo2Changes", "pain")}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="otherPetCo2Change"
                checked={formData.petCo2Changes.other.checked}
                onChange={handleCheckboxChange("petCo2Changes", "other")}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor="otherPetCo2Change" style={{ width: "200px" }}>
                OTHER
              </label>
              <input
                type="text"
                value={formData.petCo2Changes.other.comment}
                onChange={handleCommentChange("petCo2Changes", "other")}
                style={{
                  flex: "1",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "1px solid #000",
                  outline: "none",
                  backgroundColor: "#f1f4ff",
                  height: "30px",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <label
          style={{
            color: "#0078d4",
            fontWeight: "bold",
            display: "block",
            marginBottom: "5px",
          }}
        >
          Did you introduce any breathing challenges during the interview, e.g.,
          overbreathing?{" "}
          <span style={{ fontWeight: "normal", color: "black" }}>
            If so, what were they and what were the outcomes?
          </span>
        </label>
        <textarea
          name="breathingChallengesOutcome"
          value={formData.breathingChallengesOutcome}
          onChange={handleInputChange}
          style={{
            width: "100%",
            height: "70px",
            border: "1px solid #000",
            padding: "5px",
            boxSizing: "border-box",
            backgroundColor: "#f1f4ff",
          }}
        ></textarea>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <label
          style={{
            color: "#0078d4",
            fontWeight: "bold",
            display: "block",
            marginBottom: "5px",
          }}
        >
          Did you make PetCO<sub>2</sub> measurements?{" "}
          <span style={{ fontWeight: "normal", color: "black" }}>
            If so, please answer the questions below. Make comments in the space
            provided.
          </span>
        </label>

        <div style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <label>
              Was overbreathing (below 35 mmHg) present at the start of the
              session?
            </label>
            <div
              style={{ display: "flex", alignItems: "center", width: "100px" }}
            >
              <input
                type="radio"
                id="overbreathingYes"
                name="overbreathingPresent"
                checked={
                  formData.petCo2Measurements.overbreathingPresent === "Yes"
                }
                onChange={() =>
                  handleRadioChange("overbreathingPresent", "Yes")
                }
                style={{ marginRight: "2px" }}
              />
              <label htmlFor="overbreathingYes" style={{ marginRight: "10px" }}>
                Yes
              </label>
              <input
                type="radio"
                id="overbreathingNo"
                name="overbreathingPresent"
                checked={
                  formData.petCo2Measurements.overbreathingPresent === "No"
                }
                onChange={() => handleRadioChange("overbreathingPresent", "No")}
                style={{ marginRight: "2px" }}
              />
              <label htmlFor="overbreathingNo">No</label>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <label>
              What were the initial baseline PetCO<sub>2</sub> values (first two
              minutes)?
            </label>
            <input
              type="text"
              name="initialBaselineValues"
              value={formData.petCo2Measurements.initialBaselineValues}
              onChange={(e) =>
                handleRadioChange("initialBaselineValues", e.target.value)
              }
              style={{
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderBottom: "1px solid #000",
                outline: "none",
                flex: "1",
                backgroundColor: "#f1f4ff",
                height: "30px",
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <label>What symptoms/deficits were reported, if any?</label>
            <input
              type="text"
              name="symptomsReported"
              value={formData.petCo2Measurements.symptomsReported}
              onChange={(e) =>
                handleRadioChange("symptomsReported", e.target.value)
              }
              style={{
                flex: "1",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderBottom: "1px solid #000",
                outline: "none",
                backgroundColor: "#f1f4ff",
                height: "30px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <label>
              If Values were below 35, did they rise to normal within the next
              few minutes?
            </label>
            <input
              type="radio"
              id="overbreathingYes"
              name="overbreathingPresent"
              checked={
                formData.petCo2Measurements.overbreathingPresent === "Yes"
              }
              onChange={() => handleRadioChange("overbreathingPresent", "Yes")}
              style={{ marginRight: "2px" }}
            />
            <label htmlFor="overbreathingYes" style={{ marginRight: "10px" }}>
              Yes
            </label>
            <input
              type="radio"
              id="overbreathingNo"
              name="overbreathingPresent"
              checked={
                formData.petCo2Measurements.overbreathingPresent === "No"
              }
              onChange={() => handleRadioChange("overbreathingPresent", "No")}
              style={{ marginRight: "2px" }}
            />
            <label htmlFor="overbreathingNo">No</label>
            <input
              type="text"
              name="symptomsReported"
              value={formData.petCo2Measurements.symptomsReported}
              onChange={(e) =>
                handleRadioChange("symptomsReported", e.target.value)
              }
              style={{
                flex: "1",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderBottom: "1px solid #000",
                outline: "none",
                backgroundColor: "#f1f4ff",
                height: "30px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <label>
              If not did you try to assist your client to raise the PetCO
              <sub>2</sub> levels to normal?
            </label>
            <input
              type="radio"
              id="overbreathingYes"
              name="overbreathingPresent"
              checked={
                formData.petCo2Measurements.overbreathingPresent === "Yes"
              }
              onChange={() => handleRadioChange("overbreathingPresent", "Yes")}
              style={{ marginRight: "2px" }}
            />
            <label htmlFor="overbreathingYes" style={{ marginRight: "10px" }}>
              Yes
            </label>
            <input
              type="radio"
              id="overbreathingNo"
              name="overbreathingPresent"
              checked={
                formData.petCo2Measurements.overbreathingPresent === "No"
              }
              onChange={() => handleRadioChange("overbreathingPresent", "No")}
              style={{ marginRight: "2px" }}
            />
            <label htmlFor="overbreathingNo">No</label>
            <label>If so, were you successful?</label>
            <input
              type="radio"
              id="overbreathingYes"
              name="overbreathingPresent"
              checked={
                formData.petCo2Measurements.overbreathingPresent === "Yes"
              }
              onChange={() => handleRadioChange("overbreathingPresent", "Yes")}
              style={{ marginRight: "2px" }}
            />
            <label htmlFor="overbreathingYes" style={{ marginRight: "10px" }}>
              Yes
            </label>
            <input
              type="radio"
              id="overbreathingNo"
              name="overbreathingPresent"
              checked={
                formData.petCo2Measurements.overbreathingPresent === "No"
              }
              onChange={() => handleRadioChange("overbreathingPresent", "No")}
              style={{ marginRight: "2px" }}
            />
            <label htmlFor="overbreathingNo">No</label>
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <label>Did your client seem to be trapped in overbreathing?</label>
            <input
              type="radio"
              id="overbreathingYes"
              name="overbreathingPresent"
              checked={
                formData.petCo2Measurements.overbreathingPresent === "Yes"
              }
              onChange={() => handleRadioChange("overbreathingPresent", "Yes")}
              style={{ marginRight: "2px" }}
            />
            <label htmlFor="overbreathingYes" style={{ marginRight: "10px" }}>
              Yes
            </label>
            <input
              type="radio"
              id="overbreathingNo"
              name="overbreathingPresent"
              checked={
                formData.petCo2Measurements.overbreathingPresent === "No"
              }
              onChange={() => handleRadioChange("overbreathingPresent", "No")}
              style={{ marginRight: "2px" }}
            />
            <label htmlFor="overbreathingNo">No</label>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <label
          style={{
            color: "#0078d4",
            fontWeight: "bold",
            display: "block",
            marginBottom: "5px",
          }}
        >
          Did you take any screen shots of CO<sub>2</sub> graphs?{" "}
          <span style={{ fontWeight: "normal", color: "black" }}>
            If so, please convert them to a PDF file and upload to the client
            folder ib the BSN portal
          </span>
        </label>
      </div>
    </div>
  );
};

export default ReferralReportForm;
