
import { ChartModifierBase2D, CustomChartModifier2D, EChart2DModifierType, MouseWheelZoomModifier, ZoomPanModifier } from "scichart";

export class CustomZoomDragModifier extends ZoomPanModifier {
  constructor(cb,options = {}) {
    super(options);
    this.callback  = cb
  }

  modifierMouseDown(args) {
    super.modifierMouseDown(args);

    if (this.callback) {
        this.callback(args);
    }
}

modifierMouseUp(args) {
  super.modifierMouseUp(args);

  if (this.callback) {
    this.callback(args);
}
}
}