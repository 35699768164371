import React, { Component, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  ModalHeader,
  ModalFooter,
  Modal,
  ModalBody,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { API_URL } from "../../../config";
import backIcon from "../../images/back.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import AssessmentRecord from "./shared/AccessMentRecord";
import Behaviour from "./shared/Behaviour";
import GuidedOverbreathingForm from "./shared/GuidedOverbreathingForm";
import LearningPlanForm from "./shared/LearningPlanForm";
import LearningSessionRecord from "./shared/LearningSessionRecord";
import OutcomeReport from "./shared/OutcomeReport";
import PhysiologyReport from "./shared/PhysiologyReport";
import ReferralReportForm from "./shared/ReferralReportForm";
import BreathingAssessmentForm from "./shared/BreathingAssessmentForm";

// const optionsArr = [
//   {
//     id: 1,
//     forms: "(T-1) Assessment Record Fill-in",
//     url: "https://capno-forms.s3.us-east-1.amazonaws.com/blank_forms/Assessment_Record_Fill-in.pdf",
//   },
//   {
//     id: 2,
//     forms: "(T-2) Behavior Report Fill-in",
//     url: "https://capno-forms.s3.us-east-1.amazonaws.com/blank_forms/Behavior_Report_Fill-in.pdf",
//   },
//   {
//     id: 3,
//     forms: "(T-3) Guided Breathing Record Fill-in",
//     url: "https://capno-forms.s3.us-east-1.amazonaws.com/blank_forms/Guided_Breathing_Record_Fill_in.pdf",
//   },
//   {
//     id: 7,
//     forms: "(T-4) Learning Plan Fill-in",
//     url: "https://capno-forms.s3.us-east-1.amazonaws.com/blank_forms/Learning_Plan_Fill_in.pdf",
//   },
//   {
//     id: 8,
//     forms: "(T-5) Learning Session Record Fill-in",
//     url: "https://capno-forms.s3.us-east-1.amazonaws.com/blank_forms/Learning_Session_Record_Fill-in.pdf",
//   },
//   {
//     id: 9,
//     forms: "(T-6) Outcome Report Fill-in",
//     url: "https://capno-forms.s3.us-east-1.amazonaws.com/blank_forms/Outcome_Report_Fill-in.pdf",
//   },
//   {
//     id: 10,
//     forms: "(T-7) Physiology Report Fill-in",
//     url: "https://capno-forms.s3.us-east-1.amazonaws.com/blank_forms/Physiology_Report_Fill_in.pdf",
//   },
//   {
//     id: 11,
//     forms: "(T-8) Referral Report Fill-in",
//     url: "https://capno-forms.s3.us-east-1.amazonaws.com/blank_forms/Referral_Report_Fill-in.pdf",
//   },
//   {
//     id: 12,
//     forms: "(T-9) Screening Report Fill-in",
//     url: "https://capno-forms.s3.us-east-1.amazonaws.com/blank_forms/Screening_Report_Fill-in.pdf",
//   },
// ];

const Uploadclientformonline = () => {
  const { t } = useTranslation();
  const [clients, setinclients] = useState([]);
  const [trainers, settrainers] = useState([]);
  const [sesstion, setsesstion] = useState([]);
  const [blankform, setblankform] = useState([]);
  const [showSessionbox, setShowSessionbox] = useState(false);
  const trainerActive = useRef();
  const formname = useRef();
  const formFile = useRef();
  const clientSelected = useRef();
  const trainerInactive = useRef();
  const clientActive = useRef();
  const clientInactive = useRef();
  const trainerSelected = useRef();
  const groupSelected = useRef();
  const cid = useRef();
  const sessionSelected = useRef();
  const userId = localStorage.getItem("user_id");
  const sessionId = localStorage.getItem("session_id");
  const [selectedTrainer, setSelectedTrainer] = React.useState(() => {
    const selectedTrainer = localStorage.getItem("selectedTrainer");
    return selectedTrainer && selectedTrainer !== "null"
      ? selectedTrainer
      : userId;
  });
  const [selectedClient, setSelectedClient] = React.useState(() => {
    const storedClient = localStorage.getItem("selectedClient");
    return storedClient && storedClient !== "null" ? storedClient : userId;
  });
  const selectedGroup = localStorage.getItem("selectedGroup");
  const selectedtrainerActive = localStorage.getItem("selectedtrainerActive");
  const selectedtrainerInactive = localStorage.getItem(
    "selectedtrainerInactive"
  );
  const selectedclientActive = localStorage.getItem("selectedclientActive");
  const selectedclientInactive = localStorage.getItem("selectedclientInactive");
  const selectedHomework = localStorage.getItem("selectedHomework");
  const userType = localStorage.getItem("userType");
  const [selectedSession, setSelectedSession] = React.useState(() => {
    const storedSession = localStorage.getItem("selectedSession");
    return storedSession && storedSession !== "null" ? storedSession : null;
  });
  const accessToken = localStorage.getItem("accessToken");
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [successModal, setsuccessModal] = useState(false);
  const successToggleModal = () => setsuccessModal(!successModal);

  const [loaderModal, setLoaderModal] = useState(false);
  const loaderToggleModal = () => setLoaderModal(!loaderModal);

  const [pdfFormLink, setPdfFormLink] = useState(null);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [confirmFormModal, setConfirmFormModal] = useState(false);
  const [loaderId, setLoaderId] = useState(false);

  const [fillallfieldmodal, setFillallfieldModal] = useState(false);
  const fillallfieldtoggleModal = () =>
    setFillallfieldModal(!fillallfieldmodal);

  const [selectedFormId, setSelectedFormId] = useState(null);
  const [showForm, setShowForm] = useState(false);

  React.useEffect(() => {
    if (selectedClient && selectedClient !== "Choose a client") {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [selectedClient]);

  const handleSelectChange = (e) => {
    const formId = parseInt(e.target.value);
    setSelectedFormId(formId);

    // Show or hide session box based on specific form IDs
    if ([9, 11, 12].includes(formId)) {
      setShowSessionbox(false);
    } else {
      setShowSessionbox(true);
    }
  };

  useEffect(() => {
    if (userType == 3) {
      setinclients([]);
      settrainers([]);
    } else if (userType == 2) {
      getClients();
      settrainers([]);
    } else {
      getTrainers();
      getClients();
    }

    getblankForm();
    getSession();
  }, []);

  const submitTrainerform = async () => {
    setLoaderId(true);
    const canvas = await html2canvas(document.getElementById(`pdf-container`));

    const dataimg = canvas.toDataURL("image/png");

    const doc = new jsPDF({
      orientation: "p",
    });

    // Create an Image object
    const img = new Image();
    img.src = dataimg;

    img.onload = function () {
      const imgWidth = img.width;
      const imgHeight = img.height;

      // Define max width/height for the PDF page
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      // Calculate dimensions while keeping aspect ratio
      let width = pageWidth;
      let height = (imgHeight * pageWidth) / imgWidth;

      if (height > pageHeight) {
        height = pageHeight;
        width = (imgWidth * pageHeight) / imgHeight;
      }

      const x = (pageWidth - width) / 2;
      const y = (pageHeight - height) / 2;

      // Add image to PDF
      doc.addImage(dataimg, "PNG", x, y, width, height);
    };
    await new Promise((resolve) => setTimeout(resolve, 2000));

    const pdfBlob = doc.output("blob");

    let formData = new FormData();
    let client_id =
      localStorage.getItem("selectedClient") == "null"
        ? userId
        : localStorage.getItem("selectedClient");

    const formId = blankform.find(
      (form) => form.id === parseInt(selectedFormId)
    )?.id;
    let session_id = localStorage.getItem("selectedSession");
    let cureentId = formname.current.value;

    formData.append("client_id", client_id);
    formData.append("form_id", formId);
    formData.append("form", pdfBlob, "generated.pdf");
    if (cureentId == 9 || cureentId == 11 || cureentId == 12) {
      formData.append("session_id", null);
    } else {
      formData.append("session_id", session_id);
    }

    fetch(API_URL + "/forms/trainer/upload", {
      method: "POST",
      headers: {
        "x-access-token": accessToken,
      },
      body: formData,
    })
      .then((result) => {
        console.log(result, "result");
        // console.warn("result",result);
        result.json().then((resp) => {
          console.log("response", resp);
          setConfirmFormModal(false);
          successToggleModal();
          setLoaderModal(false);
          setLoaderId(false);
        });
      })
      .catch((err) => {
        console.log(err, "error");
        setLoaderModal(false);
        setLoaderId(false);
      });
  };

  const getblankForm = () => {
    let type = 1;
    fetch(API_URL + "/forms/blank/" + type, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": accessToken,
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((resp) => {
          // console.log("result", resp);
          setblankform(resp.forms);
        });
      } else if (response.status == 401) {
        logout();
      } else {
        console.log("network error");
      }
    });
  };

  const getTrainers = () => {
    let url = API_URL + "/trainers?user_id=" + userId + "&status=2";
    // // console.log(trainerActive);
    let _trainerActive = trainerActive.current.checked;
    let _trainerInactive = trainerInactive.current.checked;

    if (trainerActive.current.checked) {
      localStorage.setItem("selectedtrainerActive", true);
    } else {
      localStorage.setItem("selectedtrainerActive", false);
    }
    if (trainerInactive.current.checked) {
      localStorage.setItem("selectedtrainerInactive", true);
    } else {
      localStorage.setItem("selectedtrainerInactive", false);
    }

    if (_trainerActive && !_trainerInactive) {
      url = API_URL + "/trainers?user_id=" + userId + "&status=1";
    } else if (!_trainerActive && _trainerInactive) {
      url = API_URL + "/trainers?user_id=" + userId + "&status=0";
    } else if (_trainerActive && _trainerInactive) {
      url = API_URL + "/trainers?user_id=" + userId;
    }
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": accessToken,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          response.json().then((result) => {
            // console.log(result.trainers)
            if (result.status) {
              settrainers(result.trainers);
              // getClients();
            } else {
              alert("no data error");
            }
          });
        } else if (response.status == 401) {
          logout();
        } else {
          console.log("network error");
        }
      })
      .catch((err) => {
        // // console.log(err)
      });
  };
  const getClients = () => {
    let _userId = localStorage.getItem("selectedTrainer");
    let _selectedGroup = localStorage.getItem("selectedGroup");
    let _userType = 3;
    let _groupSelected = _selectedGroup === "true" ? true : false;
    let _trainer = true;
    if (_userId == "all") {
      _trainer = false;
    }

    localStorage.setItem("selectedGroup", false);
    if (_groupSelected) {
      localStorage.setItem("selectedGroup", true);
      _userType = 4;
    }

    if (clientActive.current.checked) {
      localStorage.setItem("selectedclientActive", true);
    } else {
      localStorage.setItem("selectedclientActive", false);
    }
    if (clientInactive.current.checked) {
      localStorage.setItem("selectedclientInactive", true);
    } else {
      localStorage.setItem("selectedclientInactive", false);
    }
    let url =
      API_URL +
      "/clients?user_id=" +
      _userId +
      "&status=2&trainer=" +
      _trainer +
      "&user_type=" +
      _userType;
    let _clientActive = clientActive.current.checked;
    let _clientInactive = clientInactive.current.checked;

    if (_clientActive && !_clientInactive) {
      url =
        API_URL +
        "/clients?user_id=" +
        _userId +
        "&status=1&trainer=" +
        _trainer +
        "&user_type=" +
        _userType;
    } else if (!_clientActive && _clientInactive) {
      url =
        API_URL +
        "/clients?user_id=" +
        _userId +
        "&status=0&trainer=" +
        _trainer +
        "&user_type=" +
        _userType;
    } else if (_clientActive && _clientInactive) {
      url =
        API_URL +
        "/clients?user_id=" +
        _userId +
        "&trainer=" +
        _trainer +
        "&user_type=" +
        _userType;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": accessToken,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          response.json().then((result) => {
            // console.log(result.clients)
            if (result.status) {
              setinclients(result.clients);
            } else {
              alert("no data error");
            }
          });
        } else if (response.status == 401) {
          logout();
        } else {
          console.log("network error");
        }
      })
      .catch((err) => {
        // // console.log(err)
      });
  };
  const getSession = () => {
    let _cid = localStorage.getItem("selectedClient");

    let _hw = 0;

    let url = API_URL + "/sessions?cid=" + _cid + "&hw=" + _hw;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": accessToken,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          response.json().then((result) => {
            // // console.log(result.sesstion)
            if (result.status) {
              setsesstion(result.sessions);
              // // console.log(setsesstion)
            } else {
              alert("no data error");
            }
          });
        } else if (response.status == 401) {
          logout();
        } else {
          console.log("network error");
        }
      })
      .catch((err) => {
        // // console.log(err)
      });
  };

  // Client Selection Update
  const updateSelectClient = () => {
    const clientValue = clientSelected.current.value;
    localStorage.setItem("selectedClient", clientValue);
    setSelectedClient(clientValue); // Trigger real-time update
    getSession();
  };

  const updateSelectTrainer = () => {
    const trainerValue = trainerSelected.current.value;
    localStorage.setItem("selectedTrainer", trainerValue);
    localStorage.setItem("selectedClient", null);

    setSelectedClient(""); // Reset client when trainer changes
    getClients();
  };
  const updateselectedSecssion = () => {
    const sessionValue = sessionSelected.current.value;
    localStorage.setItem("selectedSession", sessionValue);
    setSelectedSession(sessionValue); // Update state to trigger re-render
    setShowForm(true); // Show form once session is selected
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const renderForms = React.useMemo(() => {
    let show = false;

    if ([9, 11, 12].includes(selectedFormId)) {
      console.log("here1");
      show = true;
    }

    if (selectedFormId && selectedSession) {
      console.log(selectedFormId, selectedSession, "selectedFormId");
      console.log("here2");
      show = true;
    }

    return show;
  }, [selectedFormId, selectedSession]);

  console.log("renderForms", renderForms);

  return (
    <div>
      <Header />
      <div className="wrp-dashbord">
        <div className="sidebar-section">
          <Sidebar />
        </div>
        <div className="right-section">
          <div className="container-fluid">
            <div className="configer-head">
              <div className="back-icon-wrp">
                <Link to="/view/manageform" className="backbtn-icon">
                  <img src={backIcon} alt="backicon" />
                  <span>{t("Back")}</span>
                </Link>
              </div>
              <div className="configer-child1">
                <h3>{t("Upload-Trainer-Form")}</h3>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              {userType == 2 ? (
                ""
              ) : (
                <div className="col-lg-3">
                  <div className="trainerbox">
                    <div className="trainer-c">
                      <p>{t("trainer")}:</p>
                    </div>
                    <div className="padding-box">
                      <div className="main-checkbox">
                        <div className="checkbox-wrp">
                          <div class="custom-radios">
                            <input
                              type="checkbox"
                              id="6"
                              onChange={getTrainers}
                              ref={trainerActive}
                              defaultChecked={
                                selectedtrainerActive === "true" ? true : false
                              }
                            />
                            <label for="6">
                              <span className="redious"></span>
                            </label>
                          </div>
                          <div className="caption-cheeckbox">
                            <p>{t("Active")}</p>
                          </div>
                        </div>
                        <div className="checkbox-wrp">
                          <div class="custom-radios">
                            <input
                              type="checkbox"
                              id="7"
                              onChange={getTrainers}
                              ref={trainerInactive}
                              defaultChecked={
                                selectedtrainerInactive === "true"
                                  ? true
                                  : false
                              }
                            />
                            <label for="7">
                              <span className="redious"></span>
                            </label>
                          </div>
                          <div className="caption-cheeckbox">
                            <p>{t("Inactive")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="select-client">
                        <select
                          ref={trainerSelected}
                          onChange={updateSelectTrainer}
                        >
                          <option className="selected-bold">
                            Choose a trainer
                          </option>
                          <option className="selected-bold" value={"all"}>
                            All trainers
                          </option>
                          {trainers.map((items) => (
                            <option
                              className="selected-bold"
                              selected={
                                items.id == selectedTrainer ? true : false
                              }
                              value={items.id}
                            >
                              {items.firstname} {items.lastname}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-lg-3">
                <div className="trainerbox">
                  <div className="trainer-c">
                    <p>{t("Client")}:</p>
                  </div>
                  <div className="padding-box">
                    <div className="main-checkbox">
                      <div className="checkbox-wrp">
                        <div class="custom-radios">
                          <input
                            type="checkbox"
                            id="color-8"
                            onChange={getClients}
                            ref={clientActive}
                            defaultChecked={
                              selectedclientActive === "true" ? true : false
                            }
                          />
                          <label for="color-8">
                            <span className="redious"></span>
                          </label>
                        </div>
                        <div className="caption-cheeckbox">
                          <p>{t("Active")}</p>
                        </div>
                      </div>

                      <div className="checkbox-wrp">
                        <div class="custom-radios">
                          <input
                            type="checkbox"
                            id="color-10"
                            onChange={getClients}
                            ref={clientInactive}
                            defaultChecked={
                              selectedclientInactive === "true" ? true : false
                            }
                          />
                          <label for="color-10">
                            <span className="redious"></span>
                          </label>
                        </div>
                        <div className="caption-cheeckbox">
                          <p>{t("Inactive")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="select-client">
                      <select
                        ref={clientSelected}
                        onChange={updateSelectClient}
                      >
                        <option className="selected-bold">
                          Choose a client
                        </option>

                        {clients.length > 0 &&
                          clients.map((client, i) => (
                            <option
                              className="selected-bold"
                              selected={
                                client.id == selectedClient ? true : false
                              }
                              value={client.id}
                            >
                              {client.firstname} {client.lastname}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {showForm && (
                <div className="col-lg-3">
                  <div className="trainerbox">
                    <div className="trainer-c">
                      <p>Form Name:</p>
                    </div>
                    <div className="padding-box">
                      <div className="select-client mrt-select">
                        <select ref={formname} onChange={handleSelectChange}>
                          <option className="selected-bold" value="">
                            Choose a form
                          </option>

                          {blankform.map((bankforms, i) => {
                            return (
                              <option
                                className="selected-bold"
                                value={bankforms.id}
                              >
                                {bankforms.forms}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-lg-3">
                {showSessionbox && (
                  <div className="trainerbox">
                    <div className="trainer-c">
                      <p>Session:</p>
                    </div>
                    <div className="padding-box">
                      <div className="select-client mrt-select">
                        <select
                          ref={sessionSelected}
                          onChange={updateselectedSecssion}
                        >
                          <option className="selected-bold">
                            Choose a session
                          </option>
                          {sesstion.map((sesstion, i) => (
                            <option
                              className="selected-bold"
                              selected={
                                sesstion.id == selectedSession ? true : false
                              }
                              value={sesstion.id}
                            >
                              {sesstion.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Modal
              isOpen={successModal}
              toggle={successToggleModal}
              className="connect-box"
              centered={true}
            >
              <ModalHeader toggle={successToggleModal}>
                <span className="ml-1 roititle font-weight-bold">
                  Successfull
                </span>
              </ModalHeader>
              <ModalBody>
                <div className="modal-p">
                  <p>Trainer form has been submitted successfully.</p>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={confirmFormModal}
              toggle={() => {
                setConfirmFormModal(false);
              }}
              className="connect-box"
              centered={true}
            >
              <ModalHeader
                toggle={() => {
                  setConfirmFormModal(false);
                }}
              >
                <span className="ml-1 roititle font-weight-bold">
                  Confirmation
                </span>
              </ModalHeader>
              <ModalBody>
                <div style={{ textAlign: "center" }}>
                  <p>
                    Are you sure you want to upload this form? <br /> <br />
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <button
                    onClick={() => {
                      submitTrainerform();
                    }}
                    className="btn btn-primary"
                  >
                    {loaderId ? (
                      <div
                        class="spinner-border"
                        role="status"
                        style={{
                          width: "1rem",
                          height: "1rem",
                        }}
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      t("Yes")
                    )}
                  </button>
                  <button
                    onClick={() => {
                      setConfirmFormModal(false);
                    }}
                    className="btn btn-secondary"
                  >
                    {t("No")}
                  </button>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={modal}
              toggle={toggleModal}
              className="connect-box"
              centered={true}
            >
              <ModalHeader toggle={toggleModal}>
                <span className="ml-1 roititle font-weight-bold">Error</span>
              </ModalHeader>
              <ModalBody>
                <div className="modal-error-p">
                  <p>
                    Please fill all field{" "}
                    {localStorage.getItem("selectedClient") ==
                      "Choose a client" ||
                    localStorage.getItem("selectedClient") == null ||
                    localStorage.getItem("selectedClient") == "null"
                      ? "or select clinet"
                      : ""}
                    {(selectedSession &&
                      localStorage.getItem("selectedSession") ==
                        "Choose a session") ||
                    localStorage.getItem("selectedClient") == "null" ||
                    localStorage.getItem("selectedClient") == null
                      ? "or select session"
                      : ""}
                    {!selectedFormId ? "or select form" : ""}
                  </p>
                </div>
              </ModalBody>
            </Modal>

            <div id="pdf-container">
              {renderForms && (
                <>
                  {selectedFormId === 1 && <AssessmentRecord />}
                  {selectedFormId === 2 && <Behaviour />}
                  {selectedFormId === 3 && <GuidedOverbreathingForm />}
                  {selectedFormId === 7 && <LearningPlanForm />}
                  {selectedFormId === 8 && <LearningSessionRecord />}
                  {selectedFormId === 9 && <OutcomeReport />}
                  {selectedFormId === 10 && <PhysiologyReport />}
                  {selectedFormId === 11 && <ReferralReportForm />}
                  {selectedFormId === 12 && <BreathingAssessmentForm />}
                </>
              )}
            </div>

            {!!selectedFormId && (
              <div className="client-submit-btn">
                <button
                  type="submit"
                  onClick={() => {
                    if (
                      localStorage.getItem("selectedClient") ==
                        "Choose a client" ||
                      localStorage.getItem("selectedClient") == null ||
                      localStorage.getItem("selectedClient") == "null" ||
                      (selectedSession &&
                        localStorage.getItem("selectedSession") ==
                          "Choose a session") ||
                      localStorage.getItem("selectedClient") == "null" ||
                      localStorage.getItem("selectedClient") == null ||
                      !selectedFormId
                    ) {
                      setModal(true);
                      return;
                    }

                    setConfirmFormModal(true);
                  }}
                >
                  {t("Submit")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={loaderModal}
        toggle={loaderToggleModal}
        className="connect-box"
        centered={true}
      >
        <ModalHeader toggle={loaderToggleModal}>
          <span className="ml-1 roititle modal-head">
            Request processing...
          </span>
        </ModalHeader>
        <ModalBody>
          <p className="text-center">Please wait...</p>
          <div className="wrp-chart-loader">
            <div class="loading">
              <div class="loading-1"></div>
              <div class="loading-2"></div>
              <div class="loading-3"></div>
              <div class="loading-4"></div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={fillallfieldmodal}
        toggle={fillallfieldtoggleModal}
        className="connect-box"
        centered={true}
      >
        <ModalHeader toggle={fillallfieldtoggleModal}>
          <span className="ml-1 roititle font-weight-bold">Error</span>
        </ModalHeader>
        <ModalBody>
          <div className="modal-error-p">
            <p>Please fill all field</p>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Uploadclientformonline;
