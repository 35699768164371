import { ChartModifierBase2D, CustomChartModifier2D, EChart2DModifierType } from "scichart";

export class ClickModifier extends CustomChartModifier2D {
  constructor({id,isEnabled,cb}) {
    super();
    this.type = EChart2DModifierType.Custom;
    this.id = id;
    this.isEnabled = isEnabled;
    this.callback = cb
  }

  modifierMouseDown(args) {
    super.modifierMouseDown(args);
   
    // const yValue = this.getCurrentCoordinateCalculator().getDataValue(args.mousePoint.y);
    // console.log(`Clicked at X:`,args);
    const { mousePoint } = args;
    const { x, y } = mousePoint;

    // Loop through all renderable series to check if the click was on a series
    for (let series of this.parentSurface.renderableSeries.asArray()) {
      const hitTestInfo = series.hitTestProvider.hitTest(x, y, 10);
      if (hitTestInfo.isHit) {
        // If it was a hit, get the data values from the hitTestInfo
        const { xValue, yValue } = hitTestInfo;

        if ( this.callback && this.isEnabled) { 
          this.callback( { x: xValue, y : yValue, series, hit : hitTestInfo } )
        }
        break;
      }
    }

  }
}