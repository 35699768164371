import React, { useState } from "react";

const HomeworkRecord = () => {
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);

  const handleScreenSizeChange = () => {
    if (window.innerWidth < 700) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  // useEffect to handle initial state and screen size changes
  React.useEffect(() => {
    handleScreenSizeChange(); // Set initial state based on screen size
    window.addEventListener("resize", handleScreenSizeChange); // Listen for screen size changes
    return () => {
      window.removeEventListener("resize", handleScreenSizeChange); // Clean up the event listener
    };
  }, []);
  const [formData, setFormData] = useState({
    clientName: "",
    sessionNumber: "",
    date: "",
    practitionerName: "",
    email: "",
    homeworkAssignment: "",
    location: "",
    success: "",
    subjectiveValue: "",
    recordedData: "",
    selfObservation: "",
    breathingBehaviors: "",
    symptomsLearning: "",
    challenges: "",
    emotions: "",
    negativePractice: "",
    successes: "",
    difficulties: "",
    positiveChanges: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "20px",
        border: "1px solid #ccc",
        marginTop: "20px",
      }}
    >
      <h1
        style={{
          color: "#0275d8",
          textAlign: "center",
          fontSize: "24px",
          marginBottom: "15px",
        }}
      >
        HOMEWORK RECORD
      </h1>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
          <label htmlFor="client-name">Client Name:</label>
          <input
            type="text"
            id="client-name"
            style={{
              height: "30px",
              width: "75%",
              backgroundColor: "#f1f4ff",
              borderBottom: "1px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              outline: "none",
            }}
          />
        </div>

        <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
          <label htmlFor="session">Session:</label>
          <input
            type="text"
            id="session"
            placeholder="#"
            style={{
              width: "150px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderBottom: "1px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          />
          <label htmlFor="date">Date:</label>
          <input
            type="text"
            id="date"
            style={{
              width: "150px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderBottom: "1px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
          <label htmlFor="practitioner"> Practitioner Name:</label>
          <input
            type="text"
            id="practitioner"
            style={{
              width: "350px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderBottom: "1px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          />
          <label htmlFor="email"> Email:</label>
          <input
            type="text"
            id="email"
            style={{
              width: "350px",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderBottom: "1px solid #444",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          />
        </div>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label
          style={{
            fontWeight: "bold",
            color: "#0275d8",
            display: "block",
            marginBottom: "5px",
          }}
        >
          Homework Assignment
        </label>
        <textarea
          name="homeworkAssignment"
          value={formData.homeworkAssignment}
          onChange={handleChange}
          style={{
            width: "100%",
            height: "70px",
            border: "1px solid black",
            padding: "5px",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label style={{ fontWeight: "normal" }}>
          Where did you complete this homework session (home, work, gym, etc.)?
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid black",
              width: "400px",
              marginLeft: "5px",
              height: "30px",
              backgroundColor: "#f1f4ff",
            }}
          />
        </label>
      </div>

      <div
        style={{ marginBottom: "10px", display: "flex", alignItems: "center" }}
      >
        <span style={{ marginRight: "10px" }}>Degree of success?</span>
        <label style={{ marginRight: "10px" }}>
          <input
            type="radio"
            name="success"
            value="None"
            checked={formData.success === "None"}
            onChange={handleRadioChange}
            style={{ marginRight: "5px" }}
          />
          None
        </label>
        <label style={{ marginRight: "10px" }}>
          <input
            type="radio"
            name="success"
            value="Somewhat"
            checked={formData.success === "Somewhat"}
            onChange={handleRadioChange}
            style={{ marginRight: "5px" }}
          />
          Somewhat
        </label>
        <label style={{ marginRight: "10px" }}>
          <input
            type="radio"
            name="success"
            value="Acceptable"
            checked={formData.success === "Acceptable"}
            onChange={handleRadioChange}
            style={{ marginRight: "5px" }}
          />
          Acceptable
        </label>
        <label style={{ marginRight: "10px" }}>
          <input
            type="radio"
            name="success"
            value="Very good"
            checked={formData.success === "Very good"}
            onChange={handleRadioChange}
            style={{ marginRight: "5px" }}
          />
          Very good
        </label>
        <label style={{ marginRight: "10px" }}>
          <input
            type="radio"
            name="success"
            value="Excellent"
            checked={formData.success === "Excellent"}
            onChange={handleRadioChange}
            style={{ marginRight: "5px" }}
          />
          Excellent
        </label>
      </div>

      <div
        style={{ marginBottom: "10px", display: "flex", alignItems: "center" }}
      >
        <span style={{ marginRight: "10px" }}>
          Subjective value of session?
        </span>
        <input
          type="text"
          name="subjectiveValue"
          value={formData.subjectiveValue}
          onChange={handleChange}
          style={{
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "1px solid black",
            width: "50px",
            marginRight: "5px",
            height: "30px",
            backgroundColor: "#f1f4ff",
          }}
        />
        <span>(1 to 7, poor to excellent)</span>
      </div>

      <div
        style={{ marginBottom: "10px", display: "flex", alignItems: "center" }}
      >
        <span style={{ marginRight: "10px" }}>Did you record data?</span>
        <label style={{ marginRight: "10px" }}>
          <input
            type="radio"
            name="recordedData"
            value="YES"
            checked={formData.recordedData === "YES"}
            onChange={handleRadioChange}
            style={{ marginRight: "5px" }}
          />
          YES
        </label>
        <label>
          <input
            type="radio"
            name="recordedData"
            value="NO"
            checked={formData.recordedData === "NO"}
            onChange={handleRadioChange}
            style={{ marginRight: "5px" }}
          />
          NO
        </label>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label
          style={{
            fontWeight: "normal",
            display: "block",
            marginBottom: "5px",
          }}
        >
          Did you do any self-observation (e.g., your exhale)? If so, what was
          it and what did you learn?
        </label>
        <textarea
          name="selfObservation"
          value={formData.selfObservation}
          onChange={handleChange}
          style={{
            width: "100%",
            height: "70px",
            border: "1px solid black",
            padding: "5px",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label
          style={{
            fontWeight: "normal",
            display: "block",
            marginBottom: "5px",
          }}
        >
          What new breathing behaviors did you work on? If so, what were they,
          and what did you do?
        </label>
        <textarea
          name="breathingBehaviors"
          value={formData.breathingBehaviors}
          onChange={handleChange}
          style={{
            width: "100%",
            height: "70px",
            border: "1px solid black",
            padding: "5px",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label
          style={{
            fontWeight: "normal",
            display: "block",
            marginBottom: "5px",
          }}
        >
          What did you learn about your symptoms, deficits, or complaints and
          how they might be related to your breathing?
        </label>
        <textarea
          name="symptomsLearning"
          value={formData.symptomsLearning}
          onChange={handleChange}
          style={{
            width: "100%",
            height: "70px",
            border: "1px solid black",
            padding: "5px",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label
          style={{
            fontWeight: "normal",
            display: "block",
            marginBottom: "5px",
          }}
        >
          Did you challenge yourself? If so, what were the specific challenges,
          and how did you do?
        </label>
        <textarea
          name="challenges"
          value={formData.challenges}
          onChange={handleChange}
          style={{
            width: "100%",
            height: "70px",
            border: "1px solid black",
            padding: "5px",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label
          style={{
            fontWeight: "normal",
            display: "block",
            marginBottom: "5px",
          }}
        >
          Did you work on any emotions associated with breathing (e.g., fear),
          if any? What did you learn?
        </label>
        <textarea
          name="emotions"
          value={formData.emotions}
          onChange={handleChange}
          style={{
            width: "100%",
            height: "70px",
            border: "1px solid black",
            padding: "5px",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label
          style={{
            fontWeight: "normal",
            display: "block",
            marginBottom: "5px",
          }}
        >
          Did you do any negative practice? If so, what did you do and what did
          you learn?
        </label>
        <textarea
          name="negativePractice"
          value={formData.negativePractice}
          onChange={handleChange}
          style={{
            width: "100%",
            height: "70px",
            border: "1px solid black",
            padding: "5px",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label
          style={{
            fontWeight: "normal",
            display: "block",
            marginBottom: "5px",
          }}
        >
          What were your successes during this session, if any? What did you
          learn?
        </label>
        <textarea
          name="successes"
          value={formData.successes}
          onChange={handleChange}
          style={{
            width: "100%",
            height: "70px",
            border: "1px solid black",
            padding: "5px",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <label
          style={{
            fontWeight: "normal",
            display: "block",
            marginBottom: "5px",
          }}
        >
          What were your difficulties during this session, if any? What did you
          learn?
        </label>
        <textarea
          name="difficulties"
          value={formData.difficulties}
          onChange={handleChange}
          style={{
            width: "100%",
            height: "70px",
            border: "1px solid black",
            padding: "5px",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>

      <div>
        <label
          style={{
            fontWeight: "normal",
            display: "block",
            marginBottom: "5px",
          }}
        >
          Have you observed positive changes in your breathing and its effects
          on you, if any?
        </label>
        <textarea
          name="positiveChanges"
          value={formData.positiveChanges}
          onChange={handleChange}
          style={{
            width: "100%",
            height: "70px",
            border: "1px solid black",
            padding: "5px",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>
    </div>
  );
};

export default HomeworkRecord;
