
import { ChartModifierBase2D, CustomChartModifier2D, EChart2DModifierType, MouseWheelZoomModifier } from "scichart";

export class CustomZoomWheelModifier extends MouseWheelZoomModifier {
  constructor(cb,options = {}) {
    super(options);
    this.callback  = cb
  }

  modifierMouseWheel(args) {
    super.modifierMouseWheel(args);

    if (this.callback) {
      this.callback(args);
    }

  }
}