import { DateLabelProvider } from "scichart";

export class CustomDateTimeLabelProvider extends DateLabelProvider {
    constructor() {
        super(); 
    }

    // Override the formatLabel method to customize the label formatting
    formatLabel(datavalue) {
        // const daylight = new Date(0).getTimezoneOffset() * 60 * 1000
        const date = new Date(datavalue);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        // let ampm = hours >= 12 ? 'PM' : 'AM';
        // hours = hours % 12;
        hours = hours ? hours : "00"; 
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        return `${hours}:${minutes}:${seconds}`;
    }
} 
