import React, { useState } from "react";

const GuidedOverbreathingForm = () => {
  const [formData, setFormData] = useState({
    clientName: "",
    typeC: false,
    typeA: false,
    typeT: false,
    sessionNumber: "",
    date: "",
    practitionerName: "",
    email: "",
    depth: "",
    rate: "",
    modeling: "",
    levels: "",
    focus: "",
    head: "",
    jaws: "",
    throat: "",
    upperBody: "",
    chest: "",
    heart: "",
    abdomen: "",
    feelLegs: "",
    fingersLips: "",
    vision: "",
    sound: "",
    muscles: "",
    pain: "",
    consciousness: "",
    attention: "",
    thoughts: "",
    memories: "",
    negativeEmotions: "",
    positiveEmotions: "",
    senseOfSelf: "",
    senseOfControl: "",
    other: "",
    clientRecovered: "",
    recoveryTime: "",
    clientExplanation: "",
    assistanceProvided: "",
    outcomeDescription: "",
    selfInterventions: [],
    otherIntervention: "",
    mostUsefulInterventions: "",
    notUsefulInterventions: "",
    recoveryInterview: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData({
        ...formData,
        selfInterventions: [...formData.selfInterventions, value],
      });
    } else {
      setFormData({
        ...formData,
        selfInterventions: formData.selfInterventions.filter(
          (item) => item !== value
        ),
      });
    }
  };

  const interventionOptions = [
    "Observe your breath; watch it, but don't manipulate it.",
    "Exhale completely, but not forcibly.",
    "Extend the transition times from exhale to inhale.",
    "Breathe with your diaphragm, if possible.",
    "Breathe slowly, but NOT deeply.",
    "Breathe through your nose.",
    "Breathe gently and as quietly as possible.",
    "Stop negative thoughts about your breathing.",
    "Think embracing thoughts about people, circumstances, and events.",
    "Translate anxiety into excitement",
    "Use earplugs, and listen to your breathing. Make it as quiet as possible.",
    "Extend the transition to longer and longer times: 2, 4, 8 seconds.",
    "Take smaller breaths, and don't worry about how fast or slow.",
    "Hold your breath, letting CO2 accumulate.",
    "Use a paper bag if necessary, and repeat the above.",
    "Other",
  ];

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "20px",
        lineHeight: "1.4",
        marginTop: "20px",
        height: "90vh",
        overflowY: "scroll",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          color: "#0066cc",
          marginBottom: "20px",
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        GUIDED OVERBREATHING
      </h1>

      <div className="client-info">
        <div className="client-info-item">
          <label>Client Name:</label>
          <input
            style={{
              width: "310px",
              border: "none",
              borderBottom: "1px solid black",
              backgroundColor: "#f1f4ff",
              height: "30px",
            }}
          />
        </div>

        <div className="client-info-item">
          <label
            style={{
              marginRight: "10px",
            }}
          >
            Type:
          </label>
          <div className="type-checkboxes">
            <input type="checkbox" id="typeA" />
            <label htmlFor="typeA" className="checkbox-label-small">
              A
            </label>
            <input type="checkbox" id="typeT" />
            <label htmlFor="typeT" className="checkbox-label-small">
              T
            </label>
          </div>
        </div>

        <div className="client-info-item">
          <label>Session #</label>
          <input
            style={{
              width: "50px",
              border: "none",
              borderBottom: "1px solid black",
              backgroundColor: "#f1f4ff",
              height: "30px",
            }}
          />
        </div>

        <div className="client-info-item">
          <label>Date:</label>
          <input
            type="text"
            style={{
              width: "100px",
              border: "none",
              borderBottom: "1px solid black",
              backgroundColor: "#f1f4ff",
              height: "30px",
            }}
          />
        </div>
      </div>
      <div
        className="form-row"
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          margin: "10px 0px",
        }}
      >
        <label>Practitioner Name:</label>
        <input
          style={{
            flex: 1,
            border: "none",
            borderBottom: "1px solid black",
            backgroundColor: "#f1f4ff",
            height: "30px",
          }}
        />
        <label>Email:</label>
        <input
          style={{
            flex: 1,
            height: "30px",
            border: "none",
            borderBottom: "1px solid black",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>

      <div style={{ marginBottom: "15px" }}>
        <p style={{ fontWeight: "bold" }}>
          Intentional overbreathing{" "}
          <span style={{ fontWeight: "normal" }}>
            is an important discovery and learning tool for people who already
            overbreathe. Learning what hypocapnia 'feels like' is a very
            important part of evaluation and training.
          </span>{" "}
          <span style={{ fontWeight: "bold" }}>
            Does your client automatically recover without your assistance?
          </span>{" "}
          <span style={{ fontWeight: "normal" }}>
            Failure to recover from hypocapnia is often a key consideration in
            vicious circle dysfunctional breathing.
          </span>
        </p>
        <p style={{ marginTop: "10px" }}>
          While guiding your clients into hypocapnia look for triggered physical
          symptoms, emotions, thoughts, memories, and shifts in consciousness.{" "}
          <span style={{ fontWeight: "bold" }}>
            Does changing PCO₂ remind your client of earlier times, places, or
            people?
          </span>
        </p>
        <p style={{ marginTop: "10px" }}>
          Ask your client about their experiences (phenomenology), to be
          answered privately to themselves, while overbreathing. Interview your
          clients about these experiences during the recovery period (not during
          the guided overbreathing itself), although do NOT assist the client in
          the recovery process, unless (s)he fails to show signs of recovery
          after five minutes or more.
        </p>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <p style={{ fontWeight: "bold" }}>
          Which of the following were important while coaching your client to
          overbreathe? How and why?
        </p>

        <div style={{ marginTop: "10px" }}>
          <p>
            <input
              type="checkbox"
              id="depth"
              name="depth"
              value="depth"
              onChange={handleInputChange}
            />
            <span style={{ fontWeight: "bold" }}>DEPTH:</span> Describe the
            details of what you did and its outcomes.
          </p>
          <input
            style={{
              borderBottom: "1px solid black",
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "15px",
              width: "95%",
              backgroundColor: "#f1f4ff",
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              height: "30px",
            }}
          ></input>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              marginLeft: "15px",
            }}
          >
            Note: Increase the size of the breath gradually and incrementally.
          </p>
        </div>

        <div style={{ marginTop: "15px" }}>
          <p>
            <input
              type="checkbox"
              id="rate"
              name="rate"
              value="rate"
              onChange={handleInputChange}
            />
            <span style={{ fontWeight: "bold" }}>RATE:</span> Describe the
            details of what you did and its outcomes.
          </p>
          <input
            style={{
              borderBottom: "1px solid black",
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "15px",
              width: "95%",
              backgroundColor: "#f1f4ff",
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              height: "30px",
            }}
          ></input>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              marginLeft: "15px",
            }}
          >
            Note: Increase breathing rate sparingly. Aborted exhales may result
            in inaccurate readings.
          </p>
        </div>

        <div style={{ marginTop: "15px" }}>
          <p>
            <input
              type="checkbox"
              id="modeling"
              name="modeling"
              value="modeling"
              onChange={handleInputChange}
            />
            <span style={{ fontWeight: "bold" }}>MODELING:</span> Describe the
            details of what you did and its outcomes.
          </p>
          <input
            style={{
              borderBottom: "1px solid black",
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "15px",
              width: "95%",
              backgroundColor: "#f1f4ff",
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              height: "30px",
            }}
          ></input>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              marginLeft: "15px",
            }}
          >
            Note: If coaching is ineffective, modeling overbreathing may become
            necessary.
          </p>
        </div>

        <div style={{ marginTop: "15px" }}>
          <p>
            <input
              type="checkbox"
              id="levels"
              name="levels"
              value="levels"
              onChange={handleInputChange}
            />
            <span style={{ fontWeight: "bold" }}>LEVELS:</span> To what levels
            of PetCO₂ did you coach your client, how long, and why?
          </p>
          <input
            style={{
              borderBottom: "1px solid black",
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "15px",
              width: "95%",
              backgroundColor: "#f1f4ff",
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              height: "30px",
            }}
          ></input>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              marginLeft: "15px",
            }}
          >
            Note: Customize levels of PetCO₂ based on client uniqueness, e.g.,
            in some cases mild levels of hypocapnia will suffice.
          </p>
        </div>

        <div style={{ marginTop: "15px" }}>
          <p>
            <input
              type="checkbox"
              id="focus"
              name="focus"
              value="focus"
              onChange={handleInputChange}
            />
            <span style={{ fontWeight: "bold" }}>FOCUS:</span> What was your
            coaching focus while bringing down PetCO₂ levels and why?
          </p>
          <input
            style={{
              borderBottom: "1px solid black",
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "15px",
              width: "95%",
              backgroundColor: "#f1f4ff",
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              height: "30px",
              backgroundColor: "#f1f4ff",
              borderBottom: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              height: "30px",
            }}
          ></input>
          <p
            style={{
              fontSize: "14px",
              fontStyle: "italic",
              marginLeft: "15px",
            }}
          >
            Note: Focus on physical and psychological changes based on earlier
            information gathering (e.g., checklist, questionnaire, interview
            content).
          </p>
        </div>

        <div
          style={{
            marginTop: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="head"
                name="head"
                value="head"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Head?</span> Do you feel
              dizzy, sleepy, headache, or ringing in your ears?
            </p>
            <input
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "50%",
              }}
            ></input>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="jaws"
                name="jaws"
                value="jaws"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Jaws?</span> Are they tight,
              painful, difficult to move, or relaxed?
            </p>
            <input
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "50%",
              }}
            ></input>
          </div>

          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <p>
              <input
                type="checkbox"
                id="throat"
                name="throat"
                value="throat"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Throat?</span> Can you
              swallow, breathe, and talk normally? Is your throat closing?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "40%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="upperBody"
                name="upperBody"
                value="upperBody"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Upper body?</span> Do you
              feel cold or hot, constrained, limited, restricted, uncomfortable?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "35%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="chest"
                name="chest"
                value="chest"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Chest?</span> Do you feel
              tightness, constriction, pressure, or pain?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "50%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="heart"
                name="heart"
                value="heart"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Heart?</span> Is your heart
              racing? Does it feel "funny"? Does it worry you?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "50%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="abdomen"
                name="abdomen"
                value="abdomen"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Abdomen?</span> Do you feel
              nauseous? Are you cramping? Do you feel pain?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "40%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="feelLegs"
                name="feelLegs"
                value="feelLegs"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Feet, legs?</span> Can you
              feel your feet? Do they feel connected to you?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "50%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="fingersLips"
                name="fingersLips"
                value="fingersLips"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Fingers, lips?</span> Are
              they numb, tingling, cold, warm, stiff, dry, or wet?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "50%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="vision"
                name="vision"
                value="vision"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Vision?</span> Look around.
              Can you focus? Can you see clearly?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "50%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="sound"
                name="sound"
                value="sound"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Sound?</span> Does my voice
              seem further away, closer, or the same?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "50%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="muscles"
                name="muscles"
                value="muscles"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Muscles?</span> Do you feel
              muscle cramping, stiffness, or pain?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "50%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="pain"
                name="pain"
                value="pain"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Pain?</span> Is pain
              appearing in places you've been previously challenged?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "40%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="consciousness"
                name="consciousness"
                value="consciousness"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Consciousness?</span> Do you
              feel disconnected, disoriented, unfocused, or confused?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "40%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="attention"
                name="attention"
                value="attention"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Attention?</span> Can you
              focus? Are you alert, clear headed, or scattered?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "40%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="thoughts"
                name="thoughts"
                value="thoughts"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Thoughts?</span> What are you
              saying to yourself? What images are coming to you?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "40%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="memories"
                name="memories"
                value="memories"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Memories?</span> Does this
              experience remind you of past or present events?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "45%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="negativeEmotions"
                name="negativeEmotions"
                value="negativeEmotions"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Negative emotions?</span> Do
              you feel anxious, angry, upset, threatened, isolated, or fearful?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "35%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="positiveEmotions"
                name="positiveEmotions"
                value="positiveEmotions"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Positive emotions?</span> Do
              you feel safer, relieved, drugged, freed, good, or better?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "40%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="self"
                name="self"
                value="self"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Sense of self?</span> Do you
              feel more or less confident, more or less vulnerable
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "40%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="control"
                name="control"
                value="control"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>Sense of control?</span> Do
              you feel more or less in control? If so, how does it matter?
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "40%",
              }}
            ></div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginTop: "10px",
            }}
          >
            <p>
              <input
                type="checkbox"
                id="otherSymptoms"
                name="otherSymptoms"
                value="otherSymptoms"
                onChange={handleInputChange}
              />
              <span style={{ fontWeight: "bold" }}>
                Other (e.g., body temperature)?
              </span>
            </p>
            <div
              style={{
                borderBottom: "1px solid black",
                marginTop: "5px",
                marginLeft: "15px",
                backgroundColor: "#f1f4ff",
                borderBottom: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                width: "70%",
              }}
            ></div>
          </div>
        </div>
      </div>

      <div>
        <h3
          style={{
            color: "#0078d7",
            margin: "10px 0",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          PRECAUTIONS
        </h3>
        <p style={{ margin: "10px 0", fontSize: "14px", lineHeight: "1.4" }}>
          By taking proper precautions, neither the practitioner nor the
          learner, need be afraid of intentional overbreathing. After all,
          overbreathing is the problem, and as a behavior, it must be addressed.
          In fact, fear of overbreathing and its effects may even contribute to
          the problem. These precautions include:
        </p>
        <div
          style={{
            margin: 0,
          }}
        >
          <li style={{ fontSize: "14px", marginBottom: "5px" }}>
            <span
              style={{
                height: "10px",
                width: "10px",
                backgroundColor: "#0070c0",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>{" "}
            having a paper bag available,
          </li>
          <li style={{ fontSize: "14px", marginBottom: "5px" }}>
            <span
              style={{
                height: "10px",
                width: "10px",
                backgroundColor: "#0070c0",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>{" "}
            being vigilant of indicative behavioral nuances,
          </li>
          <li style={{ fontSize: "14px", marginBottom: "5px" }}>
            <span
              style={{
                height: "10px",
                width: "10px",
                backgroundColor: "#0070c0",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>{" "}
            advising the client that s(he) may discontinue at any time,
          </li>
          <li style={{ fontSize: "14px", marginBottom: "5px" }}>
            <span
              style={{
                height: "10px",
                width: "10px",
                backgroundColor: "#0070c0",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>{" "}
            being prepared to stop coaching when you see a contraindication, and
          </li>
          <li style={{ fontSize: "14px", marginBottom: "5px" }}>
            <span
              style={{
                height: "10px",
                width: "10px",
                backgroundColor: "#0070c0",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>{" "}
            being prepared to implement short-term interventions.
          </li>
        </div>
      </div>

      {/* RECOVERY SECTION */}
      <div>
        <h3
          style={{
            color: "#0078d7",
            margin: "20px 0 10px",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          RECOVERY
        </h3>

        <div style={{ margin: "10px 0", fontSize: "14px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label>
              Did your client recover from hypocapnia, without your assistance?
            </label>
            <div style={{ marginLeft: "10px" }}>
              <input
                type="radio"
                name="clientRecovered"
                value="YES"
                checked={formData.clientRecovered === "YES"}
                onChange={handleInputChange}
                style={{ marginRight: "5px" }}
              />
              <label style={{ marginRight: "10px" }}>YES</label>

              <input
                type="radio"
                name="clientRecovered"
                value="NO"
                checked={formData.clientRecovered === "NO"}
                onChange={handleInputChange}
                style={{ marginRight: "5px" }}
              />
              <label>NO</label>
            </div>
          </div>

          <div style={{ margin: "5px 0" }}>
            <label>If so, how long did it take?</label>
            <input
              type="text"
              name="recoveryTime"
              value={formData.recoveryTime}
              onChange={handleInputChange}
              style={{
                marginLeft: "10px",
                width: "300px",
                borderBottom: "1px solid #000",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                backgroundColor: "#f1f4ff",
              }}
            />
          </div>

          <div style={{ margin: "5px 0" }}>
            <label>If not, what was your client's explanation?</label>
            <input
              type="text"
              name="clientExplanation"
              value={formData.clientExplanation}
              onChange={handleInputChange}
              style={{
                width: "100%",
                marginTop: "5px",
                borderBottom: "1px solid #000",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                backgroundColor: "#f1f4ff",
              }}
            />
          </div>
        </div>

        <div style={{ margin: "10px 0", fontSize: "14px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label>
              If you gave assistance, did your client return to normal?
            </label>
            <div style={{ marginLeft: "10px" }}>
              <input
                type="radio"
                name="assistanceProvided"
                value="YES"
                checked={formData.assistanceProvided === "YES"}
                onChange={handleInputChange}
                style={{ marginRight: "5px" }}
              />
              <label style={{ marginRight: "10px" }}>YES</label>

              <input
                type="radio"
                name="assistanceProvided"
                value="NO"
                checked={formData.assistanceProvided === "NO"}
                onChange={handleInputChange}
                style={{ marginRight: "5px" }}
              />
              <label>NO</label>
            </div>
          </div>

          <div style={{ margin: "5px 0" }}>
            <label>If not, describe the outcome:</label>
            <input
              type="text"
              name="outcomeDescription"
              value={formData.outcomeDescription}
              onChange={handleInputChange}
              style={{
                width: "100%",
                marginTop: "5px",
                borderBottom: "1px solid #000",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                backgroundColor: "#f1f4ff",
              }}
            />
          </div>
        </div>

        {/* Self Interventions Checkboxes */}
        <div style={{ margin: "15px 0", fontSize: "14px" }}>
          <p style={{ marginBottom: "10px" }}>
            Which of the following self-interventions did you implement?
          </p>

          {interventionOptions.map((option, index) => (
            <div
              key={index}
              style={{
                margin: "5px 0",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <input
                type="checkbox"
                id={`intervention-${index}`}
                value={option}
                checked={formData.selfInterventions.includes(option)}
                onChange={handleCheckboxChange}
                style={{ marginRight: "8px", marginTop: "3px" }}
              />
              <label htmlFor={`intervention-${index}`}>{option}</label>
              {option === "Other" && (
                <input
                  type="text"
                  name="otherIntervention"
                  value={formData.otherIntervention}
                  onChange={handleInputChange}
                  style={{
                    flex: 1,
                    marginLeft: "5px",
                    borderBottom: "1px solid #000",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    height: "30px",
                    backgroundColor: "#f1f4ff",
                  }}
                />
              )}
            </div>
          ))}
        </div>

        {/* Most Useful/Not Useful Interventions */}
        <div style={{ margin: "15px 0", fontSize: "14px" }}>
          <div style={{ margin: "10px 0" }}>
            <label>
              Which self-interventions, if used, were the most useful?
            </label>
            <input
              type="text"
              name="mostUsefulInterventions"
              value={formData.mostUsefulInterventions}
              onChange={handleInputChange}
              style={{
                width: "100%",
                marginTop: "5px",
                borderBottom: "1px solid #000",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                backgroundColor: "#f1f4ff",
              }}
            />
          </div>

          <div style={{ margin: "10px 0" }}>
            <label>
              Which self-interventions, if any, were not useful? What is your
              explanation?
            </label>
            <input
              type="text"
              name="notUsefulInterventions"
              value={formData.notUsefulInterventions}
              onChange={handleInputChange}
              style={{
                width: "100%",
                marginTop: "5px",
                borderBottom: "1px solid #000",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "30px",
                backgroundColor: "#f1f4ff",
              }}
            />
          </div>
        </div>
      </div>

      {/* RECOVERY INTERVIEW SECTION */}
      <div>
        <h3
          style={{
            color: "#0078d7",
            margin: "20px 0 10px",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          RECOVERY INTERVIEW{" "}
          <span style={{ fontWeight: "normal", color: "#000" }}>
            (time frame following guided overbreathing):
          </span>
        </h3>

        <p style={{ margin: "10px 0", fontSize: "14px" }}>
          What did your client say about their overbreathing experience? Did it
          remind your client of earlier times, places, or people? What did (s)he
          say about the experience of recovery? What did your client learn?
        </p>

        <textarea
          name="recoveryInterview"
          value={formData.recoveryInterview}
          onChange={handleInputChange}
          style={{
            width: "100%",
            height: "120px",
            border: "1px solid #000",
            marginTop: "5px",
            resize: "none",
            backgroundColor: "#f1f4ff",
          }}
        />
      </div>
    </div>
  );
};

export default GuidedOverbreathingForm;
